import React from 'react'
import LineAddFriendModal from '../components/modals/LineAddFriendModal'
import Footer from '../components/layout/Footer'
import LineTopImage from '../assets/images/rin_line_top.png'
import LineTalkOpenImage from '../assets/images/rin_line_talk_open.png'
import LineTalkThisButton from '../assets/images/rin_line_talk_this_button.png'
import LineLiffAppReadyImage from '../assets/images/rin_line_liff_app_ready.png'
import LineLiffAppFilledImage from '../assets/images/rin_line_liff_app_filled.png'
import LineLiffAppSuccessImage from '../assets/images/rin_line_liff_app_success.png'

function OrderHowToStart() {

    const showLineAddFriendModal = () => {
        document.getElementById('line_add_friend_modal').showModal()
    }

    return (
        <>
            <div className='w-full h-full'>
                <div className='h-full py-14'>
                    <div className='text-center py-14'>
                        <h2 className='text-base-content text-2xl md:text-3xl font-bold mb-4'>~ 鑑定のスタート方法 ~</h2>
                        <p className='text-xs leading-6 w-10/12 mx-auto'>
                            すでに
                            <span className='border-b border-base-content'>ご購入</span>
                            ・
                            <span className='border-b border-base-content'>お申し込み</span>
                            を完了されている場合は、<br />
                            <span className='font-bold text-sm border-b border-base-content'>最後にもう１ステップ</span>
                            だけ必要です📣
                        </p>
                    </div>
                    <div className='w-4/5 lg:w-3/5 mx-auto'>
                        <section className='mb-14'>
                            <h3 className='font-bold text-xl md:mb-10'>◆ご購入〜鑑定終了までの流れ</h3>
                            {/* <p className='text-xs'>※一般的なフロー</p> */}
                            <div className='flow-chart text-center'>
                                <ul className="steps steps-vertical md:steps-horizontal md:w-full">
                                    <li className="z-30 step step-primary">
                                        <span className='flex items-center md:flex-col text-left'>
                                            <span className='font-bold'>決済</span>
                                            <span className='text-xs font-normal pl-1'>(鑑定のご購入)</span>
                                        </span>
                                    </li>
                                    <li className="z-20 step step-primary">
                                        <span className='flex items-center md:flex-col text-left'>
                                            <span className='font-bold'>お申し込み</span>
                                            <span className='text-xs font-normal pl-1'>(お悩みのご記入＆送信)</span>
                                        </span>
                                    </li>
                                    <li className="z-10 step step-primary">
                                        <span className='flex items-center md:flex-col text-left leading-none'>
                                            <span className='font-bold flex md:flex-col text-center text-error'>
                                                <span className='md:hidden'>←</span>
                                                <span className='hidden md:inline'>↑</span>
                                                &nbsp;ココ！
                                            </span>
                                            <span className='text-xs font-normal pl-1'>(鑑定をスタートさせる)</span>
                                        </span>
                                    </li>
                                    <li className="z-0 step">
                                        <span className='flex items-center md:flex-col text-left'>
                                            <span className='font-bold'>お受け取り</span>
                                            <span className='text-xs font-normal pl-1'>(鑑定終了)</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section>
                            <h3 className='font-bold text-xl mb-4'>◆鑑定の"スタート"手順</h3>
                            <p className='text-sm mb-8'>
                                鑑定をスタートさせるには、<br />
                                凛の "<span className='font-bold'>LINE公式アカウント</span>" から<br />
                                操作をいただく必要がございます☺️💬<br />
                                <br />
                                以下の手順を確認いただきながら、<br />
                                鑑定を"スタート"させてください。<br />
                                お待ちしておりますね!!🍀^^
                            </p>
                            <dl className='flex flex-col gap-12'>
                                <div className=''>
                                    <dt className='font-bold'>1. 凛のLINE公式アカウントを開く</dt>
                                    <dd className='bg-neutral-content text-neutral text-sm italic rounded max-w-max px-2 py-1'>TOP画面</dd>
                                    <dd className='py-4'>
                                        ※まだLINEの「友だち追加」がお済みでない方は
                                        <span
                                            className='text-info font-bold cursor-pointer'
                                            onClick={showLineAddFriendModal}
                                        >
                                            こちら
                                        </span>
                                        👈
                                    </dd>
                                    <dd className='py-2'>
                                        <img className='w-3/5 md:w-1/4' src={LineTopImage} alt="" />
                                    </dd>
                                </div>
                                <div>
                                    <dt className='font-bold'>2. 凛との「トーク画面」を開く</dt>
                                    <dd className='bg-neutral-content text-neutral text-sm italic rounded max-w-max px-2 py-1'>TOP画面&nbsp;&nbsp;{'>'} LINEトーク</dd>
                                    <dd className='py-2'>
                                        <img className='w-3/5 md:w-1/4' src={LineTalkOpenImage} alt="" />
                                    </dd>
                                    <dd className='text-xs'>
                                        ※リッチメニューはトーク画面下部の<br />
                                        「<span className='font-bold'>メニュー▲</span>」をタップすることで表示可能です。
                                    </dd>
                                </div>
                                <div>
                                    <dt className='font-bold'>3. リッチメニューの「get started (鑑定スタート)」をタップ</dt>
                                    <dd className='bg-neutral-content text-neutral text-sm italic rounded max-w-max px-2 py-1'>TOP画面&nbsp;&nbsp;{'>'}&nbsp;LINEトーク&nbsp;&nbsp;{'>'}&nbsp;リッチメニュー</dd>
                                    <dd className='py-2'>
                                        <img className='w-3/5 md:w-1/4' src={LineTalkThisButton} alt="" />
                                    </dd>
                                    <dd className='text-xs'>「get started (鑑定スタート)」は<span className='font-bold'>リッチメニューの左下</span>にございます。</dd>
                                    <dd className='text-xs'>
                                        <br />
                                        ※リッチメニューはトーク画面下部の<br />
                                        「<span className='font-bold'>メニュー▲</span>」をタップすることで表示可能です。
                                    </dd>
                                </div>
                                <div>
                                    <dt className='font-bold'>4.《注文ID》と《あいことば》を入力</dt>
                                    <dd className='bg-neutral-content text-neutral text-sm italic rounded max-w-max px-2 py-1'>...&nbsp;&nbsp;{'>'}&nbsp;リッチメニュー&nbsp;&nbsp;{'>'}&nbsp;注文IDの送信</dd>
                                    <dd className='py-2'>
                                        <img className='w-3/5 md:w-1/4' src={LineLiffAppReadyImage} alt="" />
                                    </dd>
                                    <dd className='text-xs'>LINEトーク内に小さなウィンドウが開き、<br />入力フォームが表示されます。</dd>
                                    <dd className='pt-8 pb-2'>
                                        <img className='w-3/5 md:w-1/4' src={LineLiffAppFilledImage} alt="" />
                                    </dd>
                                    <dd className='text-xs'>入力が終わりましたら、送信ボタンを押してください。</dd>
                                    <dd className='text-xs'><br />※注文IDの記入＆送信の操作は、<span className='font-bold text-error'>必ずリッチメニューから</span>行なってください。</dd>
                                    <dd className='text-xs'>※"<span className='font-bold'>鑑定スタート</span>"が可能な状態ではないご注文に対して、この操作をいただいたり、注文IDとあいことばの組み合わせが正しくない場合は、<span className='font-bold text-error'>エラー画面</span>が表示されます。またその場合、鑑定はスタートしません。</dd>
                                </div>
                                <div>
                                    <dt className='font-bold'>5. 鑑定スタート完了！🎉✅</dt>
                                    <dd className='bg-neutral-content text-neutral text-sm italic rounded max-w-max px-2 py-1'>...&nbsp;&nbsp;{'>'}&nbsp;注文IDの送信&nbsp;&nbsp;{'>'}&nbsp;鑑定スタート</dd>
                                    <dd className='py-2'>
                                        <img className='w-3/5 md:w-1/4' src={LineLiffAppSuccessImage} alt="" />
                                    </dd>
                                    <dd className='text-sm font-bold'>凛からの『鑑定開始メッセージ』が確認できましたら、無事に鑑定スタートが完了しております♪<br />鑑定のお届けまで、今しばらくお待ちくださいませ☺️</dd>
                                    <dd className='text-xs'><br />※通常1〜5営業日以内に鑑定結果がLINEトークにて受け取れます。</dd>
                                </div>
                            </dl>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
            <LineAddFriendModal />
        </>
    )
}

export default OrderHowToStart