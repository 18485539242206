import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UseAuthAxios from '../../../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import UseValidation from '../../../utils/hooks/UseValidation'
import ApplicationConfirmModal from '../../modals/ApplicationConfirmModal'

function ColorDivinationApplicationForm({ themeName, accessToken }) {

    const textAreaMaxLength = 500
    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const location = useLocation()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reasonTextLength, setReasonTextLength] = useState(0)
    const [incorporationTextLength, setIncorporationTextLength] = useState(0)
    const [relevanceTextLength, setRelevanceTextLength] = useState(0)
    const [selectedColorCode, setSelectedColorCode] = useState('')
    const [formData, setFormData] = useState({
        info: {
            name: '',
            birthDate: '',
            gender: '',
            bloodType: '',
            selectedColor: '',
            selectionReason: '',
            incorporationIntoDailyLife: '',
            relevanceWithColor: ''
        },
        message: {
            name: '',
            birthDate: '',
            gender: '',
            bloodType: '',
            selectedColor: '',
            selectionReason: '',
            incorporationIntoDailyLife: '',
            relevanceWithColor: ''
        }
    })
    const { info, message } = formData

    const allInfoValuesFilled = obj => Object.values(obj).every(x => x !== '')
    const allMessageValuesEmpty = obj => Object.values(obj).every(x => x === '')

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        const buttonShouldBeEnabled = allInfoValuesFilled(info) && allMessageValuesEmpty(message);
        setButtonDisabled(!buttonShouldBeEnabled);
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const showApplicationConfirmModal = (e) => {
        e.preventDefault()
        document.getElementById('application_confirm_modal').showModal()
    }

    const handleSubmit = async () => {
        toggleLoading()  // ローディング開始
        const sanitizedInfo = {
            ...info,
            selectionReason: info.selectionReason.replace(/[\s\n\r　]+$/, ''),
            incorporationIntoDailyLife: info.incorporationIntoDailyLife.replace(/[\s\n\r　]+$/, ''),
            relevanceWithColor: info.relevanceWithColor.replace(/[\s\n\r　]+$/, '')
        }
        const data = {
            order_id: new URLSearchParams(location.search).get('order_id'),
            watchword: new URLSearchParams(location.search).get('watchword'),
            applicant_name: sanitizedInfo.name,
            birth_date: sanitizedInfo.birthDate,
            gender: sanitizedInfo.gender,
            blood_type: sanitizedInfo.bloodType,
            selected_color: sanitizedInfo.selectedColor,
            selection_reason: sanitizedInfo.selectionReason,
            incorporation_into_daily_life: sanitizedInfo.incorporationIntoDailyLife,
            relevance_with_color: sanitizedInfo.relevanceWithColor
        }
        try {
            // ここにエンドポイントを記述
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const applicationResponse = await authAxios.post('/order/apply', data, config, { withCredentials: true })
            if (applicationResponse.status === 201) {
                toast.success('申し込みが完了しました✨\n鑑定を『スタート』させましょう！', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                // 鑑定スタート手順ページにリダイレクト
                navigate('/order/how-to-start')
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error('不正なアクセスです。\nトップページに戻ります。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
                return
            }
            if (error?.response?.status === 404) {
                toast.error('リクエストが正しくありません。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
                return
            }
            if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
                return
            }
        }
    }

    const handleSelectColor = (colorName, colorCode) => {
        setFormData(() => ({
            info: { ...info, selectedColor: colorName + `(${colorCode})` },
            message: { ...message, selectedColor: UseValidation.formValidate('selectedColor', colorName) }
        }))
        setSelectedColorCode(colorCode)
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    const calculateTextLength = (char, currentPoints) => {
        // 全角文字は2ポイント、半角文字（改行含む）は1ポイント
        // eslint-disable-next-line
        return currentPoints + (char.match(/[\n]|\r/) || !char.match(/[^\x01-\x7E\xA1-\xDF]/) ? 1 : 2);
    }

    const handleTextAreaChange = (event) => {
        const key = event.target.name
        const value = event.target.value
        if (value === '') {
            if (key === 'selectionReason') setReasonTextLength(0)
            if (key === 'incorporationIntoDailyLife') setIncorporationTextLength(0)
            if (key === 'relevanceWithColor') setRelevanceTextLength(0)
            setFormData(() => ({
                info: { ...info, [key]: value },
                message: { ...message, [key]: UseValidation.formValidate(key, value)}
            }))
            return
        }
        let tempText = ''
        let currentPoints = 0

        for (let i = 0; i < value.length; i++) {
            const char = value[i];
            const newPoints = calculateTextLength(char, currentPoints);
            if (newPoints <= textAreaMaxLength) {
                tempText += char;
                currentPoints = newPoints;
                if (key === 'selectionReason') setReasonTextLength(currentPoints)
                if (key === 'incorporationIntoDailyLife') setIncorporationTextLength(currentPoints)
                if (key === 'relevanceWithColor') setRelevanceTextLength(currentPoints)
            } else {
                break; // 許容ポイントを超えるとループを抜ける
            }
        }
        setFormData(() => ({
            info: {...info, [key]: tempText},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <form onSubmit={showApplicationConfirmModal}>
                <div className='flex flex-col gap-2 md:gap-5'>
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            name='name'
                            type="text"
                            maxLength={20}
                            className="grow"
                            placeholder="あなたを表すお名前"
                            required
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </label>
                    {message.name && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.name}</p>}
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            name='birthDate'
                            type="text"
                            maxLength={8}
                            pattern="\d{8}"
                            className="grow"
                            placeholder="あなたの生年月日(半角数字8ケタ)"
                            required
                            value={info.birthDate || ''}
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </label>
                    {message.birthDate && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.birthDate}</p>}
                    <select
                        name="bloodType"
                        className="select select-bordered w-[70%] max-w-xs"
                        required
                        onChange={handleChange}
                        defaultValue=""
                        disabled={isLoading}
                    >
                        <option value="" disabled>あなたの血液型</option>
                        <option value="A型">A型</option>
                        <option value="B型">B型</option>
                        <option value="O型">O型</option>
                        <option value="AB型">AB型</option>
                        <option value="不明">不明</option>
                    </select>
                    <select
                        name="gender"
                        className="select select-bordered w-3/5 max-w-xs"
                        required
                        onChange={handleChange}
                        defaultValue=""
                        disabled={isLoading}
                    >
                        <option value="" disabled>あなたの性別</option>
                        <option value="女性">女性</option>
                        <option value="男性">男性</option>
                        <option value="その他">その他</option>
                    </select>
                    <div className="dropdown">
                        <div tabIndex={0} role="button" className="bg-base-100 btn btn-outline flex justify-start w-4/5">
                            {info.selectedColor &&
                                <span style={{ backgroundColor: selectedColorCode }} className='rounded-full w-6 h-6' />
                            }
                            {info.selectedColor ? info.selectedColor : '好きな色を選んでください。'}
                        </div>
                        <div tabIndex={0} className="dropdown-content z-[1] menu flex flex-nowrap gap-3 p-2 shadow bg-base-200 overflow-y-scroll rounded-box h-[50vh] w-4/5 md:w-1/2">
                            <div onClick={() => handleSelectColor('ブラック', '#000000')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#000000' }} className='rounded-full w-6 h-6' />ブラック</div>
                            <div onClick={() => handleSelectColor('グレー', '#808080')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#808080' }} className='rounded-full w-6 h-6' />グレー</div>
                            <div onClick={() => handleSelectColor('インディゴ', '#043c78')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#043c78' }} className='rounded-full w-6 h-6' />インディゴ</div>
                            <div onClick={() => handleSelectColor('ネイビー', '#000080')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#000080' }} className='rounded-full w-6 h-6' />ネイビー</div>
                            <div onClick={() => handleSelectColor('ブルー', '#0000ff')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#0000ff' }} className='rounded-full w-6 h-6' />ブルー</div>
                            <div onClick={() => handleSelectColor('ディープスカイブルー', '#00bfff')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#00bfff' }} className='rounded-full w-6 h-6' />ディープスカイブルー</div>
                            <div onClick={() => handleSelectColor('スカイブルー', '#87ceeb')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#87ceeb' }} className='rounded-full w-6 h-6' />スカイブルー</div>
                            <div onClick={() => handleSelectColor('シアン', '#00ffff')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#00ffff' }} className='rounded-full w-6 h-6' />シアン</div>
                            <div onClick={() => handleSelectColor('ターコイズ', '#40e0d0')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#40e0d0' }} className='rounded-full w-6 h-6' />ターコイズ</div>
                            <div onClick={() => handleSelectColor('グリーン', '#008000')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#008000' }} className='rounded-full w-6 h-6' />グリーン</div>
                            <div onClick={() => handleSelectColor('ペールグリーン', '#98fb98')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#98fb98' }} className='rounded-full w-6 h-6' />ペールグリーン</div>
                            <div onClick={() => handleSelectColor('ライム', '#00ff00')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#00ff00' }} className='rounded-full w-6 h-6' />ライム</div>
                            <div onClick={() => handleSelectColor('カーキ', '#f0e68c')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#f0e68c' }} className='rounded-full w-6 h-6' />カーキ</div>
                            <div onClick={() => handleSelectColor('イエロー', '#ffff00')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ffff00' }} className='rounded-full w-6 h-6' />イエロー</div>
                            <div onClick={() => handleSelectColor('オレンジ', '#ffa500')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ffa500' }} className='rounded-full w-6 h-6' />オレンジ</div>
                            <div onClick={() => handleSelectColor('ブラウン', '#a52a2a')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#a52a2a' }} className='rounded-full w-6 h-6' />ブラウン</div>
                            <div onClick={() => handleSelectColor('レッド', '#ff0000')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ff0000' }} className='rounded-full w-6 h-6' />レッド</div>
                            <div onClick={() => handleSelectColor('ディープピンク', '#ff1493')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ff1493' }} className='rounded-full w-6 h-6' />ディープピンク</div>
                            <div onClick={() => handleSelectColor('ホットピンク', '#ff69b4')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ff69b4' }} className='rounded-full w-6 h-6' />ホットピンク</div>
                            <div onClick={() => handleSelectColor('ピンク', '#ffc0cb')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ffc0cb' }} className='rounded-full w-6 h-6' />ピンク</div>
                            <div onClick={() => handleSelectColor('マゼンタ', '#ff00ff')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ff00ff' }} className='rounded-full w-6 h-6' />マゼンタ</div>
                            <div onClick={() => handleSelectColor('ヴァイオレット', '#ee82ee')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ee82ee' }} className='rounded-full w-6 h-6' />ヴァイオレット</div>
                            <div onClick={() => handleSelectColor('ダークヴァイオレット', '#9400d3')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#9400d3' }} className='rounded-full w-6 h-6' />ダークヴァイオレット</div>
                            <div onClick={() => handleSelectColor('パープル', '#800080')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#800080' }} className='rounded-full w-6 h-6' />パープル</div>
                            <div onClick={() => handleSelectColor('ホワイト', '#ffffff')} className='btn bg-base-100 flex justify-start'><span style={{ backgroundColor: '#ffffff' }} className='rounded-full w-6 h-6' />ホワイト</div>
                        </div>
                    </div>
                    <label className="form-control">
                        <div className="label">
                            <span className="label-text text-xs">1. "その色" を選んだ理由は何ですか？</span>
                        </div>
                        <textarea
                            name='selectionReason'
                            value={info.selectionReason || ''}
                            onChange={handleTextAreaChange}
                            required
                            className="textarea textarea-bordered text-base h-[20vh]"
                            placeholder={'たとえば、その色にどのような感情や記憶が関連していますか？'}
                            disabled={isLoading}
                        />
                        <div className="label flex justify-end">
                            <span className="label-text-alt">{reasonTextLength}&nbsp;/&nbsp;500文字</span>
                        </div>
                    </label>
                    {message.selectionReason && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.selectionReason}</p>}
                    <label className="form-control">
                        <div className="label">
                            <span className="label-text text-xs">2. 日常生活に "その色" を取り入れていますか？</span>
                        </div>
                        <textarea
                            name='incorporationIntoDailyLife'
                            value={info.incorporationIntoDailyLife || ''}
                            onChange={handleTextAreaChange}
                            required
                            className="textarea textarea-bordered text-base h-[20vh]"
                            placeholder={'たとえば、洋服やインテリア、アクセサリーなど。'}
                            disabled={isLoading}
                        />
                        <div className="label flex justify-end">
                            <span className="label-text-alt">{incorporationTextLength}&nbsp;/&nbsp;500文字</span>
                        </div>
                    </label>
                    {message.incorporationIntoDailyLife && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.incorporationIntoDailyLife}</p>}
                    <label className="form-control">
                        <div className="label">
                            <span className="label-text text-xs">3. あなた自身の現在に "その色" がどのように関連していると感じますか？</span>
                        </div>
                        <textarea
                            name='relevanceWithColor'
                            value={info.relevanceWithColor || ''}
                            onChange={handleTextAreaChange}
                            required
                            className="textarea textarea-bordered text-base h-[20vh]"
                            placeholder={'たとえば、現在抱えている問題や課題、または達成したい目標など。'}
                            disabled={isLoading}
                        />
                        <div className="label flex justify-end">
                            <span className="label-text-alt">{relevanceTextLength}&nbsp;/&nbsp;500文字</span>
                        </div>
                    </label>
                    {message.relevanceWithColor && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.relevanceWithColor}</p>}
                    {buttonDisabled ?
                        <div className='btn btn-active btn-ghost cursor-not-allowed mt-4'>
                            送信
                        </div> :
                        isLoading ?
                            <div className='btn btn-secondary text-base md:text-lg w-full mt-4'>
                                <span className="loading loading-spinner loading-base text-secondary-content" />
                            </div> :
                            <button
                                type="submit"
                                className='btn btn-secondary text-base md:text-lg w-full mt-4'
                            >
                                送信
                            </button>
                    }
                </div>
            </form>
            <ApplicationConfirmModal
                info={info}
                handleSubmit={handleSubmit}
            />
        </>
    )
}

export default ColorDivinationApplicationForm