import { createContext, useEffect, useState } from 'react'

export const themeNameContext = createContext({})

const ThemeNameProvider = ({ children }) => {
    const [themeName, setThemeName] = useState(() => {
        // ローカルストレージに保存されているテーマ名を取得するか、デフォルトのテーマ名を返す
        return localStorage.getItem('theme') || 'cupcake'
    })

    const [colorPrimary, setColorPrimary] = useState("")
    const [colorSecondary, setColorSecondary] = useState("")
    const [colorAccent, setColorAccent] = useState("")
    const [colorNeutral, setColorNeutral] = useState("")
    const [colorBackground, setColorBackground] = useState("")
    const [colorText, setColorText] = useState("")

    const setThemeColors = () => {
        setColorPrimary(getComputedStyle(document.documentElement).getPropertyValue('--p').trim())
        setColorSecondary(getComputedStyle(document.documentElement).getPropertyValue('--s').trim())
        setColorAccent(getComputedStyle(document.documentElement).getPropertyValue('--a').trim())
        setColorNeutral(getComputedStyle(document.documentElement).getPropertyValue('--n').trim())
        setColorBackground(getComputedStyle(document.documentElement).getPropertyValue('--b1').trim())
        setColorText(getComputedStyle(document.documentElement).getPropertyValue('--bc').trim())
    }

    useEffect(() => {
        // テーマ名をローカルストレージに保存し、ドキュメントのルートにテーマ名を設定する
        localStorage.setItem('theme', themeName)
        document.documentElement.setAttribute('data-theme', themeName)
        setThemeColors()
    },[themeName])

    return (
        <themeNameContext.Provider value={{ themeName, setThemeName, colorPrimary, colorSecondary, colorAccent, colorNeutral, colorBackground, colorText }}>
            {children}
        </themeNameContext.Provider>
    )
}

export default ThemeNameProvider