import React from 'react'

function ContactConfirmModal({ info, handleSubmit }) {

    const submitFromModal = () => {
        handleSubmit()
    }

    return (
        <>
            <dialog id="contact_confirm_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content max-h-[80vh]">
                    <h3 className="font-bold text-xl text-center">
                        以下の内容で<br />お問い合わせを確定しますか？
                    </h3>
                    <p className='text-error text-sm font-bold mt-5'>お問い合わせ後の内容変更はできません。</p>
                    <hr className='border-base-content border-opacity-30 my-6 w-11/12 mx-auto' />
                    <dl className='flex flex-col gap-2 overflow-y-scroll'>
                        {info.name &&
                            <div className='flex'>
                                <dt className='font-bold'>お名前：</dt>
                                <dd className='ml-2'>{info.name}</dd>
                            </div>
                        }
                        {info.email &&
                            <div className='flex'>
                                <dt className='font-bold'>メールアドレス：</dt>
                                <dd className='ml-2'>{info.email}</dd>
                            </div>
                        }
                        {info.typeOfConcern &&
                            <div className='flex'>
                                <dt className='font-bold'>お問い合わせの種類：</dt>
                                <dd className='ml-2'>{info.typeOfConcern}</dd>
                            </div>
                        }
                        {info.textBody &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>お問い合わせ内容：</dt>
                                <dd className='px-4'>{info.textBody}</dd>
                            </div>
                        }
                    </dl>
                    <div className='w-full mt-6'>
                        <form method="dialog">
                            <div className='flex justify-evenly'>
                                <button
                                    className="btn btn-primary text-base md:text-lg w-2/5"
                                    onClick={submitFromModal}
                                >
                                    はい
                                </button>
                                <button
                                    className="btn btn-neutral text-base md:text-lg w-2/5"
                                >
                                    戻る
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
    )
}

export default ContactConfirmModal