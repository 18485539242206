import { useRef, useContext, useEffect, useState } from 'react'
import { Link, useLocation, Routes, Route, Navigate } from 'react-router-dom'
import { accessTokenContext } from '../../utils/contexts/AccessToken'
import { privateRouteAccessContext } from '../../utils/contexts/PrivateRouteAccess'
import Header from './Header'
import TopPage from '../../pages/TopPage'
import OneTarot from '../../pages/products/OneTarot'
import NormalDivination from '../../pages/products/NormalDivination'
import SubscriptionDivination from '../../pages/products/SubscriptionDivination'
import ColorDivination from '../../pages/products/ColorDivination'
// import ThoughtTransmission from '../../pages/products/ThoughtTransmission'
import FAQ from '../../pages/FrequentlyAskedQuestion'
import Contact from '../../pages/Contact'
import TermsOfUse from '../../pages/TermsOfUse'
import PrivacyPolicy from '../../pages/PrivacyPolicy'
import CancelPolicy from '../../pages/CancelPolicy'
import SpecifiedCommercialTransactionsAct from '../../pages/SpecifiedCommercialTransactionsAct'
import NotFound from '../../pages/NotFound'
import Maintenance from '../../pages/Maintenance'
import MaintenanceRoute from '../../pages/MaintenanceRoute'
import OrderSuccess from '../../pages/OrderSuccess'
import OrderCollation from '../../pages/OrderCollation'
import OrderApplication from '../../pages/OrderApplication'
import OrderHowToStart from '../../pages/OrderHowToStart'
import OrderPayment from '../../pages/OrderPayment'
import UserPortal from '../../pages/UserPortal'
import SubscriptionResume from '../../pages/SubscriptionResume'
import SubscriptionCancel from '../../pages/SubscriptionCancel'
import PrivateRoute from '../../pages/PrivateRoute'

function useParentHeight() {
    const [parentHeight, setParentHeight] = useState(0)
    const parentRef = useRef(null)
    const location = useLocation()

    useEffect(() => {
        if (!parentRef.current) return
        const observer = new ResizeObserver(entries => {
            setParentHeight(entries[0].contentRect.height)
        })
        observer.observe(parentRef.current)
        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        // Scroll to top when location changes
        const params = new URLSearchParams(location.search)
        const section = params.get('section')
        if (!section) {
            // if (parentRef.current) {
            //     parentRef.current.scrollTop = 0
            // }
            window.scrollTo(0, 0);
        }
    }, [location])

    return [parentRef, parentHeight]
}

function TopMain() {

    const location = useLocation()
    const { accessToken } = useContext(accessTokenContext)
    const { privateRouteAccess } = useContext(privateRouteAccessContext)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedMenuNum, setSelectedMenuNum] = useState(1)

    const [parentRef, parentHeight] = useParentHeight()
    const [sumHeight, setSumHeight] = useState(0)
    const headerRef = useRef(null)

    useEffect(() => {
        if (headerRef.current) {
            const headerHeight = headerRef?.current.clientHeight
            setSumHeight(headerHeight)
        }
    }, [])

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    return (
        <>
            <div className='flex flex-col h-screen' ref={parentRef}>
                <div className="drawer">
                    <input id="my-custom-drawer" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content h-screen">
                        {/* Page content here */}
                        <Header
                            drawerOpen={drawerOpen}
                            toggleDrawer={toggleDrawer}
                            ref={headerRef}
                        />
                        <main
                            className={`flex flex-col w-full h-full ${drawerOpen ? 'pointer-events-none' : ''}`}
                            style={{
                                height: location.pathname === '/404' ? parentHeight - sumHeight : 'auto',
                                minHeight: parentHeight - sumHeight
                            }}
                        >
                            <MaintenanceRoute>
                                <Routes>
                                    <Route path="/maintenance" element={<Maintenance />} />
                                    <Route path="/" element={<Navigate to="/top" replace />} />
                                    <Route path="/top" element={<TopPage />} />
                                    <Route path="/menu">
                                        <Route index element={<Navigate to="404" replace />} />
                                        <Route path="1" element={<OneTarot setSelectedMenuNum={setSelectedMenuNum} />} />
                                        <Route path="2" element={<NormalDivination setSelectedMenuNum={setSelectedMenuNum} />} />
                                        <Route path="3" element={<SubscriptionDivination setSelectedMenuNum={setSelectedMenuNum} />} />
                                        <Route path="4" element={<ColorDivination setSelectedMenuNum={setSelectedMenuNum} />} />
                                        {/* <Route path="5" element={<ThoughtTransmission setSelectedMenuNum={setSelectedMenuNum} />} /> */}
                                    </Route>
                                    <Route path="/order">
                                        <Route index element={<Navigate to="404" replace />} />
                                        <Route path="success" element={<OrderSuccess />} />
                                        <Route path="collation" element={<OrderCollation />} />
                                        <Route path="apply" element={<OrderApplication />} />
                                        <Route path="how-to-start" element={<OrderHowToStart />} />
                                        <Route path="portal-view" element={<UserPortal />} />
                                        <Route
                                            path="payment"
                                            element={
                                                <PrivateRoute
                                                    condition={privateRouteAccess}
                                                    selectedMenuNum={selectedMenuNum}
                                                    accessToken={accessToken}
                                                    redirectTo="/404"
                                                >
                                                    <OrderPayment selectedMenuNum={selectedMenuNum} accessToken={accessToken} />
                                                </PrivateRoute>
                                            }
                                        />
                                    </Route>
                                    <Route path="/subscription">
                                        <Route index element={<Navigate to="404" replace />} />
                                        <Route
                                            path="resume"
                                            element={
                                                <PrivateRoute
                                                    condition={privateRouteAccess}
                                                    selectedMenuNum={selectedMenuNum}
                                                    accessToken={accessToken}
                                                    redirectTo="/404"
                                                >
                                                    <SubscriptionResume accessToken={accessToken} />
                                                </PrivateRoute>
                                            }
                                        />
                                        <Route path="cancel" element={<SubscriptionCancel />} />
                                    </Route>
                                    <Route path="/faq" element={<FAQ />} />
                                    <Route path="/contact" element={<Contact />} />
                                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                    <Route path="/cancel-policy" element={<CancelPolicy />} />
                                    <Route path="/specified-commercial-transactions-act" element={<SpecifiedCommercialTransactionsAct />} />
                                    <Route path="404" element={<NotFound />} />
                                    <Route path="*" element={<Navigate to="404" replace />} />
                                </Routes>
                            </MaintenanceRoute>
                        </main>
                    </div>
                    <div className="z-50 drawer-side">
                        <label htmlFor="my-custom-drawer" aria-label="close sidebar" className="z-50 drawer-overlay" onClick={() => toggleDrawer()}></label>
                        <ul className="z-50 bg-base-200 text-base-content min-h-full w-3/5 md:w-2/5 lg:w-1/4 p-4 pt-20">
                            {/* Sidebar content here */}
                            <li>
                                <p className='text-neutral-500 text-sm md:text-base font-bold'>SITE MAP</p>
                                <ul className='menu text-xs md:text-base'>
                                    <li><Link to='/top'>トップページ</Link></li>
                                    <li><Link to='/top?section=profile'>プロフィール</Link></li>
                                    <li><Link to='/top?section=menu'>鑑定メニュー</Link></li>
                                    <li><Link to='/top?section=review'>評価・レビュー</Link></li>
                                </ul>
                            </li>
                            <li>
                                <p className='text-neutral-500 text-sm md:text-base font-bold'>SUPPORT</p>
                                <ul className='menu text-xs md:text-base'>
                                    <li><Link to='/order/collation'>ご注文の詳細・状況確認</Link></li>
                                    <li><Link to='/order/how-to-start'>鑑定のスタート方法</Link></li>
                                    <li><Link to='/faq'>よくある質問(FAQ)</Link></li>
                                    <li><Link to='/contact'>お問い合わせ</Link></li>
                                </ul>
                            </li>
                            <li>
                                <p className='text-neutral-500 text-sm md:text-base font-bold'>LEGAL</p>
                                <ul className='menu text-xs md:text-base'>
                                    <li><Link to='/terms-of-use'>利用規約</Link></li>
                                    <li><Link to='/privacy-policy'>プライバシーポリシー</Link></li>
                                    <li><Link to='/cancel-policy'>キャンセルポリシー</Link></li>
                                    <li><Link to='/specified-commercial-transactions-act'>特定商取引法に基づく表記</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopMain