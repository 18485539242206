import React from 'react'
import Footer from '../../layout/Footer'
import RinIconImage from '../../../assets/images/rin_circle_icon.png'
import Product from '../../layout/Product'
import HeaderImage from '../../../assets/images/subscription_divination.jpg'
import EffortToPrivacy from '../EffortToPrivacy'
import OverallFlowOfDelivery from '../../layout/OverallFlowOfDelivery'
import ReviewItem from '../ReviewItem'
import ThumbnailImageForOneTarot from '../../../assets/images/one_tarot.jpg'
import ThumbnailImageForNormal from '../../../assets/images/normal_divination.jpg'
import ThumbnailImageForColor from '../../../assets/images/color_divination.jpg'
// import ThumbnailImageForThoughtTransmission from '../../../assets/images/candle_and_flower.jpg'

function SubscriptionDivinationContents({ imageHeight, buttonPosition, showPurchaseConfirmModal, showCancelConfirmModal }) {

    return (
        <>
            <div className='h-full md:w-4/5 lg:w-3/4 mt-14 md:mx-auto'>
                <div
                    className='header-image bg-neutral bg-cover'
                    style={{
                        backgroundImage: `url(${HeaderImage})`,
                        backgroundPosition: 'center top 50%',
                        height: imageHeight
                    }}
                />
                <div className='title-container'>
                    <h2 className='fortune-menu-title text-2xl font-bold md:text-xl p-4'>
                        【⭐️毎月1回お悩み鑑定⭐️】通常価格より"おトク"に！月に1回までの鑑定が可能です♡
                    </h2>
                </div>
                <hr className='border-base-content border-opacity-30 mx-8' />
                <div className='flex items-center gap-4 icon-section p-4 pt-8'>
                    <img src={RinIconImage} alt="" className='rounded-full shadow-base-normal w-24 h-24' />
                    <p className='font-bold'>占い師 ~ 凛 ~</p>
                </div>
                <div className='means-section p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 🔮 使用する占術 》
                    </h2>
                    <ul className='pt-3'>
                        <li>・タロット</li>
                        <li>・占星術</li>
                        <li>・数秘術</li>
                        <li>・スピリチュアル</li>
                        <li>・霊感</li>
                        <li>など</li>
                    </ul>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 ✏️ 鑑定メニュー詳細 》
                    </h2>
                    <h3 className='font-bold pt-3'>◆ 概要</h3>
                    <p className='px-2'>
                        <span className='underline'>
                            こちらは【通常お悩み鑑定】のサブスクリプション版です。<br />
                            おトクな価格で"毎月1回"まで、鑑定をご体験いただくことが可能です♪<br />
                        </span>
                        <br />
                        <br />
                        <span className='italic'>
                            片想い、復縁、不倫、複数人との恋愛、複雑な関係、出会い、婚期、夫婦関係、お仕事、転職、人間関係、家族、友人、開運、金運、仕事運、etc...<br />
                        </span>
                        <br />
                        <br />
                        日々のお悩みは多岐にわたりますよね。<br />
                        他人には言いにくいようなお悩みも、安心してお聞かせください♡<br />
                        ネガティブを断ち切り、皆様が安心して "前向きな日々" を過ごせるように、いただいたお悩みに最適な占術で鑑定を行います。🔮<br />
                        <br />
                        <br />
                        お悩みの内容は問いません。<br />
                        どんな内容でもOKです！<br />
                        お気軽にご相談くださいね🍀^^
                    </p>
                    <h3 className='font-bold pt-9'>◆ ご相談例</h3>
                    <ul className='px-2 text-sm md:text-base'>
                        <li>・「いま、彼に思いを伝えても大丈夫ですか？」</li>
                        <li>・「別れた彼のことが忘れらない…。どうするべき？」</li>
                        <li>・「今カレが何を考えているのか知りたいです」</li>
                        <li>・「出会いがありません。この先、好きな人は現れますか？」</li>
                        <li>・「ケンカが絶えない夫と、昔のように仲良くしたい」</li>
                    </ul>
                    <h3 className='font-bold pt-9'>◆ 占い方法</h3>
                    <p className='px-2'>
                        いただいたご相談内容に合わせて"<span className='font-bold'>最適な占術</span>"で鑑定を行います。
                    </p>
                    <h3 className='font-bold pt-9'>◆ ココがおすすめ！🔥</h3>
                    <p className='px-2'>
                        ・通常価格よりも"25%OFF"で鑑定可能！<br />
                        ・どんなお悩みもOK！<br />
                        ・あらゆる角度から深い考察で、じっくり鑑定！<br />
                        ・鑑定後に"質問"ができる！
                    </p>
                    <h3 className='font-bold pt-9'>◆ ご質問について</h3>
                    <p className='px-2'>
                        こちらの鑑定では、鑑定結果に対する「質問*」が可能でございます♪<br />
                        気になったことは何なりと、ご質問くださいませ💗<br />
                        <br />
                        ※質問は鑑定結果に関することのみです<br />
                        ※質問は基本的に1回までです
                    </p>
                    <h3 className='font-bold pt-9'>◆ 料金</h3>
                    <p className='px-2'>
                        ¥3,300 / 鑑定(税込)
                    </p>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 🍀 凛(Rin)から伝えたいこと 》
                    </h2>
                    <h3 className='font-bold pt-9'>◆ 鑑定における必要項目</h3>
                    <p className='px-2'>＜必須＞</p>
                    <ul className='px-2'>
                        <li>・ご相談内容（詳しい情報をお願いします）</li>
                    </ul>
                    <p className='px-2'>＜もしあれば＞</p>
                    <ul className='px-2'>
                        <li>・ご本人様の生年月日</li>
                        <li>・お相手の生年月日</li>
                    </ul>
                    <h3 className='font-bold pt-9'>◆ 注意事項</h3>
                    <p className='px-2'>1. 以下に該当するときは、鑑定をお断りさせていただく場合があります。</p>
                    <ul className='px-2'>
                        <li>・占い師を挑発、侮辱、または試しているとみなせる場合</li>
                        <li>・他者を傷つけるような相談内容の場合</li>
                        <li>・ギャンブルや投資の結果、試験や面接の合否、適職や就職先の選定、人の健康や病気、犯罪や人の生死に関わるような相談内容の場合</li>
                        <li>・その他、不適切と判断される場合</li>
                    </ul>
                    <p className='px-2'>2. 占術の指定はできません。予めご了承くださいませ。</p>
                    <EffortToPrivacy />
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 📲 鑑定お届けまでの流れ 》
                    </h2>
                    <OverallFlowOfDelivery />
                </div>
                <div className='review-section mt-10 p-4'>
                    <h2 className='review-title text-xl font-bold mb-2 md:text-xl'>
                        《 ⭐️ 評価・レビュー 》
                    </h2>
                    <div className='flex flex-col gap-4 pt-5 pb-10'>
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>何度か鑑定をしていただいておりますが、いつも的確なアドバイスをいただけて、本当に助かっています！今回も、彼との関係についての鑑定をお願いしましたが、彼の気持ちや今後の展開を教えていただき、安心しました。彼との関係を良好に保つためのアドバイスもいただき、実践してみようと思います。来月も相談させていただきますので、どうぞよろしくお願いします。</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='30代'
                            reviewText={<span>以前、何度か凛先生に鑑定をお願いして、とても優しく寄り添った内容の鑑定をいただいたので、またお願いしたいと思っていたところこちらのメニューの存在に気づきました。おトクに見ていただけるのですね！もっと早く気づけばよかったです！<br />引き続き先生のお世話になりますが、今後ともよろしくお願いいたします！</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>
                                何度も何度もありがとうございます。いつも、凛先生に鑑定していただくと、気持ちが楽になります。いつも丁寧な言葉で説明してくださり、私の気持ちを代弁してくださるような言葉に、心が温まります。私自身も強くなれるような気がして、毎回感謝しております。これからも凛先生を信じて、ポジティブな気持ちで過ごしていきたいと思います。またよろしくお願いいたします！
                            </span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='30代'
                            reviewText={<span>彼との関係において、もう何度も先生に鑑定をいただいていますが、いつもまるで私のことが見透かされているような鑑定で、いつも驚かされます。良いことも悪いこともズバッと言ってくださるので、その度にハッと気付かされたり、勇気づけられたりしています。文章から伝わる先生の人柄の良さや親切さが、私にとって支えとなっており、とても心強く感じています。いつも本当にありがとうございます。今後とも頼らせていただきたいと思いますので、よろしくお願い致します。</span>}
                        />
                    </div>
                </div>
                <div className='cta-section max-h-max w-full'>
                    <div className={`flex justify-center w-full py-6 ${buttonPosition === 'fixed' ? 'button-fixed-bottom' : ''}`}>
                        <button
                            className={`btn btn-accent text-xl shadow-base-normal w-[80vw] lg:w-[75vw] h-16`}
                            onClick={() => showPurchaseConfirmModal()}
                        >
                            購入する
                        </button>
                    </div>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 📚 他の鑑定メニュー 》
                    </h2>
                    <div className='grid grid-cols-2 gap-4 md:gap-8 lg:gap-12 pt-9'>
                        <Product
                            productNum={2}
                            img={ThumbnailImageForNormal}
                            title={'【🍀通常お悩み鑑定🍀】どんな悩みでもOKです♡恋愛・お仕事・お金・人間関係など'}
                            desc={'※お悩みの内容は問いません'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        />
                        <Product
                            productNum={4}
                            img={ThumbnailImageForColor}
                            title={'【🎨カラー鑑定🎨】\nあなたの特性や深層心理、将来へのインスピレーションを読み解きます♡'}
                            desc={'※購入された"ご本人様"が対象です'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        />
                        {/* <Product
                            productNum={5}
                            img={ThumbnailImageForThoughtTransmission}
                            title={'【💐思念伝達鑑定💐】\nあなたの"想い"をお相手様の潜在意識に届けます♡恋愛/復縁/片想い'}
                            desc={'※お悩みの内容は問いません'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        /> */}
                        <Product
                            productNum={1}
                            img={ThumbnailImageForOneTarot}
                            title={'【-Oneタロット鑑定-】\n1枚のカードからメッセージや意味、ヒントを読み取りお伝えします💗'}
                            desc={'※お悩みの内容は問いません'}
                            price={'¥550(税込)'}
                            type={'1回限り'}
                        />
                    </div>
                </div>
                <div className='cancel-section p-5'>
                    <p
                        className='text-error text-xs md:text-sm text-center cursor-pointer max-w-max mx-auto'
                        onClick={() => showCancelConfirmModal()}
                    >
                        プランのキャンセルはこちら
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SubscriptionDivinationContents