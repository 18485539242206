import React from 'react'

function CancelConfirmModal({ showCancelCodeSendModal, resetAllQuestions }) {
    return (
        <>
            <dialog id="cancel_confirm_modal" className="modal">
                <div className="modal-box border-2 border-base-content">
                    <h3 className="font-bold text-lg mb-4">プランのキャンセル手続きへ進みます。<br />よろしいですか？</h3>
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                <button
                                    className="btn btn-primary text-base md:text-lg w-2/5"
                                    onClick={() => showCancelCodeSendModal()}
                                >
                                    はい
                                </button>
                                <button
                                    className="btn btn-neutral text-base md:text-lg w-2/5"
                                    onClick={() => resetAllQuestions()}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => resetAllQuestions()}>close</button>
                </form>
            </dialog>
        </>
    )
}

export default CancelConfirmModal