const emailValidation = (email) => {
    if (!email) return 'メールアドレスを入力してください'
    if (email.length > 254) return 'メールアドレスは254文字以内で入力してください'

    const regexEmail = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$/
    if (!regexEmail.test(email)) return '正しい形式でメールアドレスを入力してください'

    return ''
}

const nameValidation = (name) => {
    if (!name) return 'お名前を入力してください'
    if (name.length > 20) return 'お名前は20文字以内で入力してください'

    return ''
}

const cardOwnerNameValidation = (cardOwnerName) => {
    if (!cardOwnerName) return 'カード名義を入力してください';
    // 正規表現でカード名義の形式をチェック
    if (!/^[A-Z\s.-]+$/.test(cardOwnerName)) {
        return '無効なカード名義です';
    }
    if (cardOwnerName.length > 45) return '無効なカード名義です';

    return '';
}

const confirmCodeValidation = (confirmCode) => {
    if (!confirmCode) return '確認コードを入力してください'
    const regexOnlyNum = /^[A-Z0-9a-z]+$/
    if (!regexOnlyNum.test(confirmCode)) return '正しい形式で入力してください'
    if (confirmCode.length !== 16) return '確認コードは16文字で入力してください'

    return ''

}

const orderIdValidation = (orderId) => {
    if (orderId.length === 0) return ''
    if (orderId.length !== 6) return '注文IDは6文字で入力してください'
    const regexPattern = /^[A-Z0-9]+$/
    if (!regexPattern.test(orderId)) return '正しい形式で入力してください'
    if (!['O', 'N', 'S', 'C', 'T'].includes(orderId[0])) return '正しい形式で入力してください'

    return '';
}

const subscriptionIdValidation = (orderId) => {
    if (orderId.length === 0) return ''
    if (orderId.length !== 6) return '注文IDは6文字で入力してください'
    const regexPattern = /^[A-Z0-9]+$/
    if (!regexPattern.test(orderId)) return '正しい形式で入力してください'
    if (!['S'].includes(orderId[0])) return '正しい形式で入力してください'

    return '';
}

const watchwordValidation = (watchword) => {
    if (watchword.length === 0) return ''
    if (watchword.length < 5) return 'あいことばは5文字以上で入力してください'
    if (watchword.length > 20) return 'あいことばは20文字以内で入力してください'
    if (!watchword) return 'あいことばを入力してください'
    const regexPattern = /^[a-zA-Z0-9ぁ-んァ-ンー一-龠々]+$/
    if (!regexPattern.test(watchword)) return '絵文字または記号を含めないでください'

    return ''
}

const birthDateValidation = (birthDate) => {
    if (!birthDate) return '生年月日を入力してください'
    const regexOnlyNum = /^[0-9]+$/
    if (!regexOnlyNum.test(birthDate)) return '生年月日は半角数字で入力してください'
    if (birthDate.length !== 8) return '生年月日は8文字で入力してください'

    const year = parseInt(birthDate.substring(0, 4), 10);
    const month = parseInt(birthDate.substring(4, 6), 10) - 1; // JS months are 0-indexed
    const day = parseInt(birthDate.substring(6, 8), 10);
    const currentDate = new Date();
    const inputDate = new Date(year, month, day);

    // 未来の日付をチェック
    if (inputDate > currentDate) {
        return '有効な日付を入力してください';
    }

    // Create a date object and verify the date's integrity
    const dateObj = new Date(year, month, day);
    if (dateObj.getFullYear() !== year || dateObj.getMonth() !== month || dateObj.getDate() !== day) {
        return '有効な日付を入力してください';
    }

    return ''
}

const passwordValidation = (password) => {
    if (!password) return 'パスワードを入力してください'
    if (password.length < 8 || password.length > 32) return 'パスワードは8〜32文字で入力してください'
    const regexOnlyNum = /^[0-9]+$/
    if (regexOnlyNum.test(password)) return 'パスワードは半角英字と半角数字を組み合わせてください'
    const regexPassword = /^[A-Za-z0-9]+$/
    if (!regexPassword.test(password)) return 'パスワードは半角英数字で入力してください'

    return ''
}

const textAreaValidation = (textBody) => {
    if (textBody.includes('<script>') || textBody.includes('</script>')) {
        return '使用禁止の文字が含まれています。';
    }
    // textBodyの前後の空白を削除
    const trimmedText = textBody.trim();
    if (trimmedText.length === 0) return '内容を記入してください'
    if (trimmedText.length < 20) return 'ご記入いただいた内容が短すぎます'

    return ''
}

const commonValidation = (type, value) => {
    return ''
}

class UseValidation {
    static formValidate = (type, value) => {
        switch (type) {
            case 'email':
                return emailValidation(value)
            case 'name':
                return nameValidation(value)
            case 'password':
                return passwordValidation(value)
            case 'orderId':
                return orderIdValidation(value)
            case 'subscriptionId':
                return subscriptionIdValidation(value)
            case 'watchword':
                return watchwordValidation(value)
            case 'birthDate':
                return birthDateValidation(value)
            case 'bloodType':
                return commonValidation(type, value)
            case 'gender':
                return commonValidation(type, value)
            case 'textBody':
                return textAreaValidation(value)
            case 'selectedColor':
                return commonValidation(type, value)
            case 'selectionReason':
                return textAreaValidation(value)
            case 'incorporationIntoDailyLife':
                return textAreaValidation(value)
            case 'relevanceWithColor':
                return textAreaValidation(value)
            case 'mainText':
                return textAreaValidation(value)
            case 'messageToTheOtherParty':
                return textAreaValidation(value)
            case 'typeOfConcern':
                return commonValidation(type, value)
            case 'cardOwnerName':
                return cardOwnerNameValidation(value)
            case 'confirmCode':
                return confirmCodeValidation(value)
            case 'cancelReason':
                return commonValidation(value)
            default:
        }
    }
}

export default UseValidation;