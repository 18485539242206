import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseAuthAxios from '../../utils/hooks/UseAuthAxios'
import { accessTokenContext } from '../../utils/contexts/AccessToken'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { toast } from 'react-toastify'

function WatchwordChangeConfirmModal({ orderId, defaultWatchword, newWatchword, setWatchwordChanged }) {

    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const { accessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async () => {
        setIsLoading(true)
        const data = {
            order_id: orderId,
            default_watchword: defaultWatchword,
            new_watchword: newWatchword
        }
        const config = { headers: { Authorization: `Bearer ${accessToken}` } }
        try {
            const changeWatchwordResponse = await authAxios.post('/order/default-watchword/change', data, config, { withCredentials: true })
            if (changeWatchwordResponse.status === 200) {
                const orderId = changeWatchwordResponse?.data.order_id
                const watchword = changeWatchwordResponse?.data.watchword
                // * モーダルを閉じる
                document.getElementById('watchword_change_confirm_modal').close()
                // * トースト通知
                toast.success('あいことばを変更しました！✨', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 1500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                // * 親コンポーネントのステートを更新
                setWatchwordChanged(true)
                // * ページリダイレクト
                navigate(`/order/success?order_id=${orderId}&watchword=${watchword}`)
            }
        } catch (error) {
            setIsLoading(false)
            document.getElementById('watchword_change_confirm_modal').close()
            if (error?.response?.status === 400 || error?.response?.status === 404 || error?.response?.status === 401) {
                toast.error('無効なリクエストです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 1500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error?.response?.status === 409 && error?.response?.data.detail === 'Already changed.') {
                toast.error('すでに「あいことば」は、\n変更されているようです。🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error?.response?.status === 409 && error?.response?.data.detail === 'Already used.') {
                toast.error('すでに使用している「あいことば」は\n使用することができません。😢', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            }
        }
    }

    return (
        <>
            <dialog id="watchword_change_confirm_modal" className="modal">
                <div className="modal-box border-2 border-base-content text-center">
                    <h3 className="font-bold text-lg">以下の内容でよろしいですか？</h3>
                    <p className="text-sm py-4">
                        ※一度変更した「あいことば」は、<br />
                        再度変更することはできません。
                    </p>
                    <div className='border border-base-content rounded-lg p-4 mb-4 mx-auto'>
                        <p>「{defaultWatchword}」</p>
                        <p>
                            ↓<br />
                            <span className='italic'>(変更後)</span><br />
                            ↓
                        </p>
                        <p className='text-lg font-bold'>『{newWatchword}』</p>
                    </div>
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex flex-col items-center justify-evenly'>
                                {isLoading ?
                                    <div className="btn btn-primary text-base md:text-lg w-10/12">
                                        <span className="loading loading-spinner loading-base text-secondary-content" />
                                    </div>:
                                    <div
                                        className="btn btn-primary shadow-base-normal text-base md:text-lg w-10/12"
                                        onClick={() => handleSubmit()}
                                    >
                                        はい
                                    </div>
                                }
                                <hr className='border-base-content border-opacity-30 my-3 w-10/12 mx-auto' />
                                {!isLoading ?
                                    <button
                                        className="btn btn-neutral text-base md:text-lg w-10/12"
                                    >
                                        キャンセル
                                    </button>:
                                    <div className='btn text-base md:text-lg w-10/12'>キャンセル</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {!isLoading &&
                    <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                    </form>
                }
            </dialog>
        </>
    )
}

export default WatchwordChangeConfirmModal