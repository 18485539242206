import { createContext, useState } from 'react'

export const privateRouteAccessContext = createContext({})

const PrivateRouteAccessProvider = ({ children }) => {
    const [privateRouteAccess, setPrivateRouteAccess] = useState(false)

    return (
        <privateRouteAccessContext.Provider value={{ privateRouteAccess, setPrivateRouteAccess }}>
            {children}
        </privateRouteAccessContext.Provider>
    )
}

export default PrivateRouteAccessProvider