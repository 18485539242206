import { Navigate, useLocation } from 'react-router-dom'

function MaintenanceRoute({ children }) {
    const isMaintenanceMode = false // ! 障害対応(メンテナンス)中は true にする
    const location = useLocation()

    if (isMaintenanceMode && location.pathname !== '/maintenance') {
        return <Navigate to="/maintenance" replace />
    } else if (!isMaintenanceMode && location.pathname === '/maintenance') {
        return <Navigate to="/" replace />
    }
    return children
}

export default MaintenanceRoute