import React from 'react'
import Footer from '../../layout/Footer'
import RinIconImage from '../../../assets/images/rin_circle_icon.png'
import Product from '../../layout/Product'
import HeaderImage from '../../../assets/images/normal_divination.jpg'
import EffortToPrivacy from '../EffortToPrivacy'
import OverallFlowOfDelivery from '../../layout/OverallFlowOfDelivery'
import ReviewItem from '../ReviewItem'
import ThumbnailImageForOneTarot from '../../../assets/images/one_tarot.jpg'
import ThumbnailImageForSubscription from '../../../assets/images/subscription_divination.jpg'
import ThumbnailImageForColor from '../../../assets/images/color_divination.jpg'
// import ThumbnailImageForThoughtTransmission from '../../../assets/images/candle_and_flower.jpg'

function NormaDivinationContents({ imageHeight, buttonPosition, showPurchaseConfirmModal }) {

    const showFirstModal = () => {
        showPurchaseConfirmModal()
    }

    return (
        <>
            <div className='h-full md:w-4/5 lg:w-3/4 my-14 md:mx-auto'>
                <div
                    className='header-image bg-neutral bg-cover'
                    style={{
                        backgroundImage: `url(${HeaderImage})`,
                        backgroundPosition: 'center top 50%',
                        height: imageHeight
                    }}
                />
                <div className='title-container'>
                    <h2 className='fortune-menu-title text-2xl font-bold md:text-xl p-4'>
                        【🍀通常お悩み鑑定🍀】どんな悩みでもOKです♡恋愛・お仕事・お金・人間関係など
                    </h2>
                </div>
                <hr className='border-base-content border-opacity-30 mx-8' />
                <div className='flex items-center gap-4 icon-section p-4 pt-8'>
                    <img src={RinIconImage} alt="" className='rounded-full shadow-base-normal w-24 h-24' />
                    <p className='font-bold'>占い師 ~ 凛 ~</p>
                </div>
                <div className='means-section p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 🔮 使用する占術 》
                    </h2>
                    <ul className='pt-3'>
                        <li>・タロット</li>
                        <li>・占星術</li>
                        <li>・数秘術</li>
                        <li>・スピリチュアル</li>
                        <li>・霊感</li>
                        <li>など</li>
                    </ul>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 ✏️ 鑑定メニュー詳細 》
                    </h2>
                    <h3 className='font-bold pt-3'>◆ 概要</h3>
                    <p className='px-2'>
                        <br />
                        <span className='italic'>
                            片想い、復縁、不倫、複数人との恋愛、複雑な関係、出会い、婚期、夫婦関係、お仕事、転職、人間関係、家族、友人、開運、金運、仕事運、etc...<br />
                        </span>
                        <br />
                        <br />
                        日々のお悩みは多岐にわたりますよね。<br />
                        他人には言いにくいようなお悩みも、安心してお聞かせください♡<br />
                        <br />
                        ネガティブを断ち切り、皆様が安心して "前向きな日々" を過ごせるように、いただいたお悩みに最適な占術で鑑定を行います。🔮<br />
                        <br />
                        <br />
                        お悩みの内容は問いません。<br />
                        どんな内容でもOKです！<br />
                        お気軽にご相談くださいね🍀^^
                    </p>
                    <h3 className='font-bold pt-9'>◆ ご相談例</h3>
                    <ul className='px-2 text-sm md:text-base'>
                        <li>・「いま、彼に思いを伝えても大丈夫ですか？」</li>
                        <li>・「別れた彼のことが忘れらない…。どうするべき？」</li>
                        <li>・「今カレが何を考えているのか知りたいです」</li>
                        <li>・「出会いがありません。この先、好きな人は現れますか？」</li>
                        <li>・「ケンカが絶えない夫と、昔のように仲良くしたい」</li>
                    </ul>
                    <h3 className='font-bold pt-9'>◆ 占い方法</h3>
                    <p className='px-2'>
                        いただいたご相談内容に合わせて"<span className='font-bold'>最適な占術</span>"で鑑定を行います。
                    </p>
                    <h3 className='font-bold pt-9'>◆ ココがおすすめ！🔥</h3>
                    <p className='px-2'>
                        ・どんなお悩みもOK！<br />
                        ・あらゆる角度から深い考察で、じっくり鑑定！<br />
                        ・鑑定後に"質問"ができる！
                    </p>
                    <h3 className='font-bold pt-9'>◆ ご質問について</h3>
                    <p className='px-2'>
                        こちらの鑑定では、鑑定結果に対する「質問*」が可能でございます♪<br />
                        気になったことは何なりと、ご質問くださいませ💗<br />
                        <br />
                        ※質問は鑑定結果に関することのみです<br />
                        ※質問は基本的に1回までです
                    </p>
                    <h3 className='font-bold pt-9'>◆ 料金</h3>
                    <p className='px-2'>
                        ¥4,400 / 鑑定(税込)
                    </p>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 🍀 凛(Rin)から伝えたいこと 》
                    </h2>
                    <h3 className='font-bold pt-9'>◆ 鑑定における必要項目</h3>
                    <p className='px-2'>＜必須＞</p>
                    <ul className='px-2'>
                        <li>・ご相談内容（詳しい情報をお願いします）</li>
                    </ul>
                    <p className='px-2'>＜もしあれば＞</p>
                    <ul className='px-2'>
                        <li>・ご本人様の生年月日</li>
                        <li>・お相手の生年月日</li>
                    </ul>
                    <h3 className='font-bold pt-9'>◆ 注意事項</h3>
                    <p className='px-2'>1. 以下に該当するときは、鑑定をお断りさせていただく場合があります。</p>
                    <ul className='px-2'>
                        <li>・占い師を挑発、侮辱、または試しているとみなせる場合</li>
                        <li>・他者を傷つけるような相談内容の場合</li>
                        <li>・ギャンブルや投資の結果、試験や面接の合否、適職や就職先の選定、人の健康や病気、犯罪や人の生死に関わるような相談内容の場合</li>
                        <li>・その他、不適切と判断される場合</li>
                    </ul>
                    <p className='px-2'>2. 占術の指定はできません。予めご了承くださいませ。</p>
                    <EffortToPrivacy />
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 📲 鑑定お届けまでの流れ 》
                    </h2>
                    <OverallFlowOfDelivery />
                </div>
                <div className='review-section mt-10 p-4'>
                    <h2 className='review-title text-xl font-bold mb-2 md:text-xl'>
                        《 ⭐️ 評価・レビュー 》
                    </h2>
                    <div className='flex flex-col gap-4 pt-5 pb-10'>
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>この度は鑑定をしていただき、ありがとうございました！彼との関係で悩んでいて、いつも焦りや不安を感じる日々でしたが、凛さんの鑑定で少し楽になりました。<br />ズバッと言っていただけたことで自分の気持ちを整理することができた気がします！今後の展開やアドバイスをいただけたことも嬉しかったです！もし迷われている方がいらっしゃったら、ぜひ凛さんに相談してみてください！ほんっとにオススメします！</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>彼との再会を望んでいたのですが、どうしても諦めきれない自分がいたので、凛さんに相談しました。彼とはすでに離れてしまったため、もう会うこともないのかなと半ば諦めかけていたのですが、鑑定でいただいた私の状況やアドバイスが的確すぎて…とても前向きな気持ちになってもう一度頑張ろうと思えました！<br />そして…先日彼に再会できました！！凛先生が導いてくださったのだと思います、本当に感謝しかありません。まだ彼と一緒になれたわけではないですが、その日までどうか、先生を頼らせてください！本当に凛先生にお会いできて良かったと思っています。</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>今カレとの関係が不安定で、どうしたらいいかわからなかったのですが、SNSで凛さんの投稿を見てなぜか惹かれてしまい…占いは初めてだったのですが思いきって相談してみました。親身になって丁寧な鑑定をしてくださり、2人の今後の展開やアドバイスをいただきました。<br />カレとの関係においては本当に今の状況をズバリ的確に言い当てられていて、ビックリ！しました。笑<br />凛さんに相談して、気持ちが楽になりました。また悩んだ際はぜひ相談させていただこうと思いますので、よろしくお願いします。ありがとうございました！</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='30代'
                            reviewText={<span>彼とズルズルした関係が続いていて、自分の気持ちにも踏ん切りがつけられずにいたので、凛さんに相談しました。<br />いただいた鑑定では、私の気持ちを見事にそのまま言葉にされていて、驚いたとともに、一気に涙が溢れてしまいました。<br />こんな私の気持ちに寄り添っていただいて、安心感と勇気をいただきました。おかげで彼との関係を断ち切ることができ、自分で新しい一歩を踏み出すことができました。いま前向きな気持ちで過ごせているのは凛さんのおかげです。本当にありがとうございました。また新しい恋の悩みができた時は、凛さんに相談したいと思います。</span>}
                        />
                    </div>
                </div>
                <div className='cta-section max-h-max w-full'>
                    <div className={`flex justify-center w-full py-6 ${buttonPosition === 'fixed' ? 'button-fixed-bottom' : ''}`}>
                        <button
                            className={`btn btn-accent text-xl shadow-base-normal w-[80vw] lg:w-[75vw] h-16`}
                            onClick={() => showFirstModal()}
                        >
                            購入する
                        </button>
                    </div>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 📚 他の鑑定メニュー 》
                    </h2>
                    <div className='grid grid-cols-2 gap-4 md:gap-8 lg:gap-12 pt-9'>
                        <Product
                            productNum={3}
                            img={ThumbnailImageForSubscription}
                            title={'【⭐️毎月1回お悩み鑑定⭐️】\n通常価格より"おトク"に！月に1回までの鑑定が可能です♡'}
                            desc={'※【🍀通常お悩み鑑定🍀】のサブスクリプション版です'}
                            price={'¥3,300(税込)'}
                            type={'月々払い'}
                        />
                        <Product
                            productNum={4}
                            img={ThumbnailImageForColor}
                            title={'【🎨カラー鑑定🎨】\nあなたの特性や深層心理、将来へのインスピレーションを読み解きます♡'}
                            desc={'※購入された"ご本人様"が対象です'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        />
                        {/* <Product
                            productNum={5}
                            img={ThumbnailImageForThoughtTransmission}
                            title={'【💐思念伝達鑑定💐】\nあなたの"想い"をお相手様の潜在意識に届けます♡恋愛/復縁/片想い'}
                            desc={'※お悩みの内容は問いません'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        /> */}
                        <Product
                            productNum={1}
                            img={ThumbnailImageForOneTarot}
                            title={'【-Oneタロット鑑定-】\n1枚のカードからメッセージや意味、ヒントを読み取りお伝えします💗'}
                            desc={'※お悩みの内容は問いません'}
                            price={'¥550(税込)'}
                            type={'1回限り'}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NormaDivinationContents