import axios from 'axios'

// *《dev》
// const BASE_URL = "http://127.0.0.1:8000"
// export const authAxios = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         "Content-Type": "application/json",
//         // ! 下記の設定(ngrok-skip-browser-warning)は開発時のみ必要になる！
//         // ! 1. ngrokの警告を非表示にするため
//         // ! 2. Safariでのみ発生するCORSエラーを回避するため
//         "ngrok-skip-browser-warning": "testValue"
//     },
//     withCredentials: true,
//     timeout: 20000,
// })

// *《prod》
const BASE_URL = "https://rin-enrich-life.onrender.com";
export const authAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true,
    timeout: 20000,
})

// ?《common》
export default axios.create({ baseURL: BASE_URL });