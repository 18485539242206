import { useRef, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import UseValidation from '../utils/hooks/UseValidation'
import { themeNameContext } from '../utils/contexts/ThemeName'
import { toast } from 'react-toastify'
import { PiCreditCardThin, PiCalendarCheckThin, PiDotsThreeDuotone, PiSignatureThin } from "react-icons/pi"
import rinCircleIcon from '../assets/images/rin_circle_icon.png'
import amexIcon from '../assets/images/amex.png'
import dinersIcon from '../assets/images/diners.png'
import discoverIcon from '../assets/images/discover.png'
import jcbIcon from '../assets/images/jcb.png'
import masterIcon from '../assets/images/master.png'
import visaIcon from '../assets/images/visa.png'
import OneTarotImage from '../assets/images/one_tarot.jpg'
import NormalDivinationImage from '../assets/images/normal_divination.jpg'
import SubscriptionDivinationImage from '../assets/images/subscription_divination.jpg'
import ColorDivinationImage from '../assets/images/color_divination.jpg'
import ThoughtTransmissionImage from '../assets/images/candle_and_flower.jpg'
import ApplePayButton from '../components/common/ApplePayButton'


function OrderPayment({ selectedMenuNum, accessToken}) {

    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const { themeName } = useContext(themeNameContext)

    const expiryElementRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    // const [isApplePayLoading, setIsApplePayLoading] = useState(false)
    const [hasDefaultCard, setHasDefaultCard] = useState(undefined)
    const [defaultCardBrand, setDefaultCardBrand] = useState('')
    const [defaultCardLast4, setDefaultCardLast4] = useState('')
    const [useDefaultCard, setUseDefaultCard] = useState(undefined)
    const [cardInfo, setCardInfo] = useState({
        brand: '',
        empty: false,
        complete: false,
        error: null
    })
    const [formComplete, setFormComplete] = useState({
        numberComplete: false,
        expiryComplete: false,
        cvcComplete: false
    })
    const [formData, setFormData] = useState({
        info: {
            cardOwnerName: '',
        },
        message: {
            cardOwnerName: ''
        }
    })
    const { info, message } = formData

    useEffect(() => {
        const checkDefaultCard = async () => {
            try {
                const config = { headers: { Authorization: `Bearer ${accessToken}` } }
                const defaultCardResponse = await authAxios.get('/order/payment/ready', config, { withCredentials: true })
                if (defaultCardResponse.status === 200) {
                    setHasDefaultCard(true)
                    setDefaultCardBrand(defaultCardResponse?.data?.brand)
                    setDefaultCardLast4(defaultCardResponse?.data?.last4)
                    setUseDefaultCard(true)
                }
                if (defaultCardResponse.status === 204) {
                    setHasDefaultCard(false)
                    setUseDefaultCard(false)
                }
            }catch (error) {
                // console.log(error)
                if (error?.response?.status === 429) {
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                    return
                }
            }
        }
        if (sessionStorage.getItem('privateAccessGranted') && accessToken && (selectedMenuNum && Number.isInteger(selectedMenuNum) && 0 < selectedMenuNum && selectedMenuNum < 6)) {
            checkDefaultCard()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // elementsを初期化
        if (!window.payjp) {
            // windowオブジェクトにpayjpがまだ設定されていなければ、新しく生成
            window.payjp = window.Payjp(process.env.REACT_APP_PAYJP_PUBLIC_KEY);
        }
        const elements = window.payjp.elements();
        const numberElement = elements.create('cardNumber', {hideIcon: true});
        expiryElementRef.current = elements.create('cardExpiry');
        // const expiryElement = elements.create('cardExpiry');
        const cvcElement = elements.create('cardCvc');
        if (hasDefaultCard !== undefined && useDefaultCard !== undefined && (!hasDefaultCard || !useDefaultCard)) {
            numberElement.mount('#number-form');
            expiryElementRef.current.mount('#expiry-form');
            cvcElement.mount('#cvc-form');
        }

        const handleCardChange = (element, type) => {
            element.on('change', e => {
                setCardInfo(prev => ({
                    ...prev,
                    brand: element === numberElement && e.brand ? e.brand : prev.brand,
                    empty: typeof e.empty === 'boolean' ? e.empty : prev.empty,
                    complete: typeof e.complete === 'boolean' ? e.complete : prev.complete,
                    error: e.error && JSON.stringify(e.error)
                }));
                setFormComplete(prev => ({
                    ...prev,
                    [type + 'Complete']: e.complete
                }));
            });
        };

        handleCardChange(numberElement, 'number');
        handleCardChange(expiryElementRef.current, 'expiry');
        handleCardChange(cvcElement, 'cvc');

        return () => {
            numberElement.unmount();
            expiryElementRef.current.unmount();
            cvcElement.unmount();
            window.onsubmit = null;
        }
    }, [hasDefaultCard, useDefaultCard]);

    const sendPaymentRequest = async (cardToken, useDefaultCard) => {
        setIsLoading(true)
        try {
            const data = { menuId: selectedMenuNum, cardToken: cardToken, useDefaultCard: useDefaultCard}
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const paymentResponse = await authAxios.post(
                '/order/payment/',
                data,
                config,
                { withCredentials: true }
            )
            if (paymentResponse.status === 200) {
                const orderId = paymentResponse?.data?.order_id
                const watchword = paymentResponse?.data?.watchword
                toast.success('支払いが完了しました！✨', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate(`/order/success?order_id=${orderId}&watchword=${watchword}`)
            }
        } catch (error) {
            console.log(error)
            const paymentErrorResponse = error?.response
            if ((paymentErrorResponse?.status === 400) && (paymentErrorResponse?.data?.detail === 'Payment failed.')) {
                toast.error('支払いに失敗しました。\n最初からやり直してください。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate(`/menu/${selectedMenuNum}`)
                return
            } else if ((paymentErrorResponse?.status === 400) && (paymentErrorResponse?.data?.detail === 'Invalid request')) {
                toast.error('無効なリクエストです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate(`/menu/${selectedMenuNum}`)
                return
            } else if (paymentErrorResponse?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/top')
                return
            } else {
                toast.warning(`正常なリクエストが行われませんでした。\nお手数をおかけして申し訳ございません。\n『ポータル画面』から状況をご確認ください🙇‍♀️(${paymentErrorResponse?.status})`, {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 3500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate(`/menu/${selectedMenuNum}`)
                return
            }
        }
    }

    const executeElementsFormSubmit = (event) => {
        toggleLoading()
        event.preventDefault();
        window.payjp.createToken(expiryElementRef.current, { card: { name: info.cardOwnerName }}).then(function (response) {
            if (response.id) {
                const cardToken = response.id
                sendPaymentRequest(cardToken, useDefaultCard)
            }
            if (response.error) {
                document.querySelector('#error').innerText = response.error && response.error.message
                // トークン作成時のエラーステータスコード：402
                // トークン作成は可能だが、支払い作成時のエラーステータスコード： 400
                setFormData(() => ({
                    info: '', message: ''
                }))
                setIsLoading(false)
            }
        });
    };

    // windowオブジェクトにonsubmitイベントハンドラーを設定
    window.onsubmit = executeElementsFormSubmit

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    const isFormValid = !cardInfo.error &&
                        formComplete.numberComplete &&
                        formComplete.expiryComplete &&
                        formComplete.cvcComplete &&
                        formData.message.cardOwnerName === '' &&
                        formData.info.cardOwnerName !== '';

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    useEffect(() => {
        //? コンポーネントがアンマウントされるときに sessionStorage から privateAccessGranted を削除するクリーンアップ関数
        return () => {
            sessionStorage.removeItem('privateAccessGranted')
        }
    }, [])

    return (
        <>
            <div className='w-full h-screen'>
                <div className='h-full'>
                    <div className='bg-secondary text-secondary-content bg-opacity-80 flex flex-col justify-evenly h-[45%] lg:h-1/2 px-5 md:px-20 py-4'>
                        <div className='lg:w-3/5 lg:mx-auto'>
                            <Link to={`/menu/${selectedMenuNum}`}>
                                <button className="btn btn-xs md:btn-sm lg:btn-lg btn-ghost ">
                                    ←
                                    <img src={rinCircleIcon} alt='rin_circle' className='w-6 h-6 md:w-7 md:h-7' />
                                </button>
                            </Link>
                        </div>
                        <div className='flex items-center rounded lg:rounded-lg shadow-lg w-3/5 md:w-1/2 lg:w-1/3 h-32 md:h-40 mx-auto overflow-hidden'>
                            {selectedMenuNum === 1 && <img src={OneTarotImage} alt='one_tarot' className='w-full' />}
                            {selectedMenuNum === 2 && <img src={NormalDivinationImage} alt='normal_divination' className='w-full' />}
                            {selectedMenuNum === 3 && <img src={SubscriptionDivinationImage} alt='subscription_divination' className='w-full' />}
                            {selectedMenuNum === 4 && <img src={ColorDivinationImage} alt='color_divination' className='w-full' />}
                            {selectedMenuNum === 5 && <img src={ThoughtTransmissionImage} alt='thought_transmission' className='w-full' />}
                        </div>
                        <h2 className='text-center'>
                            {selectedMenuNum === 1 && 'Oneタロット鑑定'}
                            {selectedMenuNum === 2 && '通常お悩み鑑定'}
                            {selectedMenuNum === 3 && '毎月1回お悩み鑑定'}
                            {selectedMenuNum === 4 && 'カラー鑑定'}
                            {selectedMenuNum === 5 && '思念伝達鑑定'}
                        </h2>
                        <h3 className='font-bold text-3xl text-center'>
                            {selectedMenuNum === 1 && '¥550'}
                            {selectedMenuNum === 2 && '¥4,400'}
                            {selectedMenuNum === 3 && '¥3,300'}
                            {selectedMenuNum === 4 && '¥4,400'}
                            {selectedMenuNum === 5 && '¥4,400'}
                        </h3>
                        <p className='text-xs text-center px-5'>
                            {selectedMenuNum === 1 && '1枚のカードからメッセージや意味、ヒントを読み取りお伝えします。'}
                            {selectedMenuNum === 2 && 'どんな悩みでもOK！ご相談内容に合わせてあらゆる角度から深い考察を交えて、じっくり鑑定を行います。'}
                            {selectedMenuNum === 3 && '【通常お悩み鑑定】のサブスクリプション版です。月に1回までの鑑定が可能です。通常価格に対して"25%OFF"と、おトクにご利用いただけます。'}
                            {selectedMenuNum === 4 && '"色"からあなたの特性や深層心理、将来へのインスピレーションを読み解きます。'}
                            {selectedMenuNum === 5 && 'あなたの"想い"をお相手様の潜在意識に届けます♡恋愛/復縁/片想い'}
                        </p>
                    </div>
                    <div className='flex flex-col gap-5 px-5 md:px-40 py-10 md:py-14 lg:w-3/5 lg:mx-auto'>
                        {(hasDefaultCard && useDefaultCard && (defaultCardBrand !== '' && defaultCardLast4 !== '')) &&
                            <>
                                <div
                                    className='btn flex justify-around shadow-base-normal'
                                    onClick={() => setUseDefaultCard(prev => !prev)}
                                >
                                    {defaultCardBrand === 'Visa' && <img src={visaIcon} alt='visa' className='h-8' />}
                                    {defaultCardBrand === 'MasterCard' && <img src={masterIcon} alt='master' className='h-8' />}
                                    {defaultCardBrand === 'JCB' && <img src={jcbIcon} alt='jcb' className='h-8' />}
                                    {defaultCardBrand === 'American Express' && <img src={amexIcon} alt='amex' className='h-8' />}
                                    {defaultCardBrand === 'Diners Club' && <img src={dinersIcon} alt='diners' className='h-8' />}
                                    {defaultCardBrand === 'Discover' && <img src={discoverIcon} alt='discover' className='h-8' />}
                                    <span>***** {defaultCardLast4}</span>
                                    <span className='font-normal text-info'>変更する</span>
                                </div>
                                {isLoading ?
                                    <div className='btn btn-primary'>
                                        <span className="loading loading-spinner loading-base text-primary-content"></span>
                                    </div> :
                                    <button
                                        onClick={() => sendPaymentRequest(null, useDefaultCard)}
                                        className='btn btn-primary'
                                    >支払う</button>
                                }
                                <hr className='border-base-content border-opacity-50 my-3' />
                                <ApplePayButton
                                    className="shadow-base-normal"
                                    selectedMenuNum={selectedMenuNum}
                                    accessToken={accessToken}
                                />
                            </>
                        }
                        {(useDefaultCard === false || hasDefaultCard === false) &&
                            <>
                                <div className='flex flex-col items-end'>
                                    {/* カードブランド画像を並べる */}
                                    <div className='bg-base-300 rounded flex items-center justify-center gap-3 p-2 w-full'>
                                        <img src={visaIcon} alt='visa' className='h-7' />
                                        <img src={masterIcon} alt='master' className='h-7' />
                                        {/* <p className='text-2xs'>Visa / Mastercardがご利用可能です。</p> */}
                                        <img src={jcbIcon} alt='jcb' className='h-7' />
                                        <img src={amexIcon} alt='amex' className='h-7' />
                                        <img src={dinersIcon} alt='diners' className='h-7' />
                                        <img src={discoverIcon} alt='discover' className='h-7' />
                                    </div>
                                    {(hasDefaultCard && hasDefaultCard !== undefined) &&
                                        <>
                                            { !isLoading ?
                                                <p
                                                    className='text-sm text-info text-right max-w-max mt-3'
                                                    onClick={() => setUseDefaultCard(true)}
                                                >
                                                    ***** {defaultCardLast4} の使用を続ける
                                                </p> :
                                                <p className='text-sm text-info text-right max-w-max mt-3'>
                                                    ***** {defaultCardLast4} の使用を続ける
                                                </p>}
                                        </>
                                    }
                                </div>
                                <label className="input input-bordered bg-white flex items-center gap-2">
                                    {(cardInfo.brand === '' || cardInfo.brand === 'unknown') && <PiCreditCardThin size={30} className='text-black' />}
                                    {cardInfo.brand === 'American Express' && <img src={amexIcon} alt="amex" className='w-[30px]' />}
                                    {cardInfo.brand === 'Diners Club' && <img src={dinersIcon} alt="diners" className='w-[30px]' />}
                                    {cardInfo.brand === 'Discover' && <img src={discoverIcon} alt="discover" className='w-[30px]' />}
                                    {cardInfo.brand === 'JCB' && <img src={jcbIcon} alt="jcb" className='w-[30px]' />}
                                    {cardInfo.brand === 'MasterCard' && <img src={masterIcon} alt="master" className='w-[30px]' />}
                                    {cardInfo.brand === 'Visa' && <img src={visaIcon} alt="visa" className='w-[30px]' />}
                                    <div id="number-form" className="payjs-outer bg-white grow" />
                                </label>
                                <div className='flex gap-2'>
                                    <label className="input input-bordered bg-white flex items-center gap-2 w-[55%]">
                                        <PiCalendarCheckThin size={30} className='text-black' />
                                        <div id="expiry-form" className="payjs-outer bg-white grow" />
                                    </label>
                                    <label className="input input-bordered bg-white flex items-center gap-2 w-[45%]">
                                        <PiDotsThreeDuotone size={30} className='text-black' />
                                        <div id="cvc-form" className="payjs-outer bg-white grow" />
                                    </label>
                                </div>
                                <label className="input input-bordered bg-white flex items-center gap-2">
                                    <PiSignatureThin size={30} className='text-black' />
                                    <input
                                        id='card-owner-name'
                                        name='cardOwnerName'
                                        type="text"
                                        value={info.cardOwnerName || ''}
                                        autoComplete='cc-name'
                                        maxLength={20}
                                        className="grow text-black"
                                        placeholder="カード名義"
                                        required
                                        onChange={handleChange}
                                    />
                                </label>
                                {message.cardOwnerName && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.cardOwnerName}</p>}
                                {(isFormValid && !isLoading) ?
                                    <button
                                        onClick={(e) => executeElementsFormSubmit(e)}
                                        className='btn btn-primary'
                                    >支払う</button> :
                                    (isLoading) ?
                                        <div className='btn btn-primary'>
                                            <span className="loading loading-spinner loading-base text-primary-content"></span>
                                        </div> :
                                        <div className='btn shadow-base-normal'>支払う</div>
                                }
                                {/* <p id="card-info-error" className='text-center text-error'>{cardInfo.error && JSON.parse(cardInfo.error)?.message}</p> */}
                                <p id="error" className='text-center text-error'>{cardInfo.error && JSON.parse(cardInfo.error)?.message}</p>
                                <hr className='border-base-content border-opacity-50 my-1' />
                                <ApplePayButton
                                    className="shadow-base-normal"
                                    selectedMenuNum={selectedMenuNum}
                                    accessToken={accessToken}
                                />
                            </>
                        }
                    </div>
                    {/* <p>
                        <span id="number-brand">brand: {cardInfo.brand}</span><br />
                        <span id="number-empty">empty: {cardInfo.empty != null ? cardInfo.empty.toString() : 'N/A'}</span><br />
                        <span id="number-complete">complete: {cardInfo.complete != null ? cardInfo.complete.toString() : 'N/A'}</span><br />
                        <span id="number-error">error: {cardInfo.error}</span>
                    </p> */}
                </div>
            </div>
        </>
    )
}

export default OrderPayment