import { useContext, useEffect, useState } from 'react'
import UseAuthAxios from '../../utils/hooks/UseAuthAxios'
import { accessTokenContext } from '../../utils/contexts/AccessToken'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { toast } from 'react-toastify'
import { TbAuth2Fa } from 'react-icons/tb'
import UseValidation from '../../utils/hooks/UseValidation'

function CancelLinkSendModal({ resetAllQuestions, menuNum }) {

    const authAxios = UseAuthAxios()
    const { themeName } = useContext(themeNameContext)
    const { accessToken } = useContext(accessTokenContext)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        info: {
            confirmCode: ''
        },
        message: {
            confirmCode: ''
        }
    })
    const { info, message } = formData

    const allInfoValuesFilled = obj => Object.values(obj).every(x => x !== '')
    const allMessageValuesEmpty = obj => Object.values(obj).every(x => x === '')

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        const buttonShouldBeEnabled = allInfoValuesFilled(info) && allMessageValuesEmpty(message);
        setButtonDisabled(!buttonShouldBeEnabled);
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const resetAll = () => {
        setFormData({
            info: {
                confirmCode: ''
            },
            message: {
                confirmCode: ''
            }
        })
        setIsLoading(false)
        document.getElementById('cancel_link_send_modal').close()
        resetAllQuestions()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        toggleLoading()
        try {
            // ここにエンドポイントを記述
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const data = { 'confirm_code': info.confirmCode, 'menu_id': menuNum }
            const sendLinkResponse = await authAxios.post('/subscription/cancel-link/send', data, config, { withCredentials: true })
            if (sendLinkResponse.status === 200) {
                resetAll()
                document.getElementById('cancel_link_send_modal').close()
                toast.success('ご登録いただいたメールアドレス宛に\n『キャンセル用URL』を送信しました。\nメール受信BOXをご確認ください🕊️✨', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 4000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
            const sendLinkErrorResponse = error?.response
            if (sendLinkErrorResponse?.status === 400) {
                resetAll()
                toast.error('無効なリクエストです。\n最初からやり直してください。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (sendLinkErrorResponse?.status === 404 && sendLinkErrorResponse?.data?.detail === 'Not found.') {
                resetAll()
                toast.warning('メールアドレスまたは\nお名前が正しくないようです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (sendLinkErrorResponse?.status === 404 && sendLinkErrorResponse?.data?.detail === 'Resource not found.') {
                resetAll()
                toast.warning('プランが見つかりませんでした🙇‍♀️\nご状況をご確認くださいませ。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (sendLinkErrorResponse?.status === 429) {
                resetAll()
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            }
        }
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <dialog id="cancel_link_send_modal" className="modal">
                <div className="modal-box border-2 border-base-content">
                    <div className='text-center mb-4'>
                        <ul className="steps steps-horizontal">
                            <li className="step step-primary"></li>
                            <li className="step step-primary"></li>
                            <li className="step"></li>
                        </ul>
                    </div>
                    <p className='text-center p-2'>
                        ご登録のメールアドレス宛に<br />『<span className='font-bold'>確認コード</span>』を送信しました。<br />
                    </p>
                    <p className="border-2 border-error rounded-lg text-sm p-2 mt-4 mb-5 md:my-4">
                        <span className='underline'>
                            このウィンドウは、<br />
                            ✋<span className='font-bold text-base text-error'>閉じずにそのまま</span>で<br />
                            メール受信BOXをご確認ください！📧<br />
                        </span>
                        <br />
                        ※閉じてしまうと、再度確認コードの取得が必要になります。
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col gap-2 w-full'>
                            <label className="input input-bordered flex items-center gap-2">
                                <TbAuth2Fa className='text-2xl' />
                                <input
                                    name='confirmCode'
                                    type="text"
                                    autoComplete='text'
                                    maxLength={16}
                                    className="grow"
                                    placeholder="メールに記載された確認コード"
                                    required
                                    value={info.confirmCode || ''}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                />
                            </label>
                            {message.confirmCode && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.confirmCode}</p>}
                            {(!isLoading && buttonDisabled) &&
                                <div
                                    className='btn shadow-base-normal cursor-not-allowed text-base md:text-lg w-full mt-2'
                                >送信</div>
                            }
                            {(!isLoading && !buttonDisabled) &&
                                <button
                                    type="submit"
                                    className='btn btn-primary shadow-base-normal cursor-pointer text-base md:text-lg w-full mt-2'
                                >送信</button>
                            }
                            {(isLoading && !buttonDisabled) &&
                                <div className='btn btn-primary shadow-base-normal cursor-not-allowed text-base md:text-lg w-full mt-2'>
                                    <span className="loading loading-spinner loading-base text-primary-content"></span>
                                </div>
                            }
                        </div>
                    </form>
                    <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                {!isLoading ?
                                    <button
                                        className="btn btn-neutral cursor-pointer text-base md:text-lg w-full"
                                        onClick={() => resetAll()}
                                    >
                                        キャンセル
                                    </button> :
                                    <div className='btn btn-neutral cursor-not-allowed text-base md:text-lg w-full'>キャンセル</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    {!isLoading &&
                        <button onClick={() => resetAll()}>close</button>
                    }
                </form>
            </dialog>
        </>
    )
}

export default CancelLinkSendModal