import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/layout/Footer'

function CancelPolicy() {
    return (
        <>
            <div className='w-full h-full'>
                <div className='h-full py-14'>
                    <div className='text-center py-14'>
                        <h2 className='text-base-content text-2xl font-bold'>キャンセルポリシー</h2>
                    </div>
                    <div className='text-sm w-4/5 lg:w-1/2 mx-auto'>
                        <p>
                            ご注文のキャンセル方法やキャンセルの条件、返金の流れについてはこちらのページをご確認くださいませ🌱
                        </p>
                        <hr className='w-full my-5' />
                        <h3 className='text-lg font-bold py-3'>
                            【🚫キャンセルについて】
                        </h3>
                        <p>
                            お申し込みいただいた鑑定サービスのご注文のキャンセル/返金は、原則としてお受けできません。<br />
                            ただし、以下の場合に限り、受け付けることができます。
                        </p>
                        <h4 className='text-lg font-bold border-b border-base-content max-w-max mt-10 lg:mt-14 mb-3'>
                            《1回限りの鑑定のキャンセル/返金》
                        </h4>
                        <p>
                            ◆対象の鑑定サービス<br />
                            ・通常お悩み鑑定<br />
                            ・Oneタロット鑑定<br />
                            ・カラー鑑定<br />
                            <br />
                            ◆キャンセル/返金の条件<br />
                            以下の2つの条件を満たす場合に限り、キャンセル/返金を受け付けることができます。<br />
                            <br />
                            <span className='text-error font-bold italic'>
                                ・ご購入から30日以内<br />
                                ・ご相談内容をまだ送信していない段階<br />
                            </span>
                            <br />
                        </p>
                        <p className='text-xs'>
                            ※すでにご相談内容を送信している場合は、キャンセルを受け付けることができませんので、ご了承ください。
                        </p>
                        <h4 className='text-lg font-bold border-b border-base-content max-w-max mt-10 lg:mt-14 mb-3'>
                            《月々払い鑑定のキャンセル/返金》
                        </h4>
                        <p>
                            ◆対象の鑑定サービス<br />
                            ・毎月1回お悩み鑑定<br />
                            <br />
                            ◆キャンセル/返金の条件<br />
                            以下の2つの条件を満たす場合に限り、キャンセル/返金を受け付けることができます。<br />
                            <br />
                            <span className='text-error font-bold italic'>
                                ・初回購入から30日以内<br />
                                ・ご相談内容をまだ送信していない段階<br />
                            </span>
                            <br />
                        </p>
                        <p className='text-xs'>
                            ※キャンセルに関してはいつでも可能です。<br />
                            ※初回購入後、キャンセルはいつでも可能でございますが、すでにご相談内容を送信している場合、返金については受け付けることができません。<br />
                            ※こちらのサービスは、いつでもキャンセルが可能ですが、2回目のお支払い以降は返金はいたしかねますので、ご了承ください。<br />
                            ※初回購入後すぐにキャンセル/返金の手続きを行った場合、決済処理が完了していない場合がございます。その場合はキャンセル処理のみが行われ、返金の処理はございません。
                        </p>
                        <h3 className='text-lg font-bold pt-10 pb-3'>
                            【👆キャンセル操作手順】
                        </h3>
                        <p>
                            以下の手順に沿ってキャンセル操作を行ってください。<br />
                        </p>
                        <h4 className='text-lg font-bold border-b border-base-content max-w-max mt-10 lg:mt-14 mb-3'>
                            《1回限りの鑑定》
                        </h4>
                        <p>
                            ◆対象の鑑定サービス<br />
                            ・通常お悩み鑑定<br />
                            ・Oneタロット鑑定<br />
                            ・カラー鑑定<br />
                            <br />
                            1. <Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>の<span className='inline md:hidden'><br /></span><span className='bg-secondary text-secondary-content text-xs rounded-full py-1 px-2 mx-1'>URLを発行する</span>ボタンより「ポータル画面URL」を発行してください。<br />
                            2. ご登録いただいたメールアドレスに「ポータル画面URL」が記載されたメールが届きます。<br />
                            3. メールに記載されたURLから、ポータル画面にアクセスしてください。<br />
                            4. ポータル画面上の各注文にて「キャンセル」ボタンを押してください。<br />
                            5. キャンセルしたい注文の情報を入力し、キャンセル手続きを完了してください。<br />
                        </p>
                        <h4 className='text-lg font-bold border-b border-base-content max-w-max mt-10 lg:mt-14 mb-3'>
                            《月々払い鑑定》
                        </h4>
                        <p>
                            ◆対象の鑑定サービス<br />
                            ・毎月1回お悩み鑑定<br />
                            <br />
                            1. <Link to='/menu/3' className='text-info font-bold'>鑑定メニューページ</Link>の下部にある「<span className='text-error'>プランのキャンセルはこちら</span>」リンクからのお手続きをお願いします。<br />
                            2. ご本人様確認情報をご記入・送信の上、そのままの状態でキャンセル用の「確認コード」が記載されたメールが届くのをお待ちください。<br />
                            3. メールに記載された「確認コード」を入力・送信すると、キャンセルページURLが記載されたメールが届きます。<br />
                            4. メールに記載されたURLからキャンセルページにアクセスしてください。<br />
                            5. キャンセルしたい注文(プラン)の情報を入力し、キャンセル手続きを完了してください。<br />
                        </p>
                        <h3 className='text-lg font-bold pt-10 pb-3'>
                            【👛返金について】
                        </h3>
                        <p>
                            当サイトでお客様からいただいた返金手続きの完了と同時に、返金処理が行われます。<br />
                            <br />
                            <br />
                            ◆請求・払い戻し期間について<br />
                            お客様への払い戻しタイミングについては、カード会社によって異なるため、断定できません。<br />
                            期間としては1週間、長くて1ヶ月程度かかることが多いです。<br />
                            <br />
                            通常はカード会社の締め日に合わせて、請求や引き落としが行われます。<br />
                            実際の請求期間について知りたい場合は、カード会社にお問い合わせいただく必要がございます。<br />
                            カード会社によっては、明細に反映されるまでには時間がかかる場合もございます。ウェブ明細にも記載がない場合もカード会社にお問い合わせください。<br />
                            <br />
                        </p>
                        <h3 className='text-lg font-bold pt-10 pb-3'>
                            【🌱その他】
                        </h3>
                        <p>
                            ・商品の交換については、お届けした商品に不良がある場合のみ対応いたします。お客様のご都合による交換はお受けできません。<br />
                            ・お客様のご利用方法や、お申し込み内容によっては、強制的にキャンセルを行う場合がございます。<br />
                            予めご了承ください。<br />
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default CancelPolicy