import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children, condition, selectedMenuNum, accessToken, redirectTo }) => {
    // conditionはprivateRouteAccessの値
    // sessionStorage で追加のチェックを行い、ページへのアクセスが許可されているかを判定
    const accessGranted = (
        sessionStorage.getItem('privateAccessGranted') === 'true' &&
        (selectedMenuNum && Number.isInteger(selectedMenuNum) && 0 < selectedMenuNum && selectedMenuNum < 6) &&
        (accessToken && typeof accessToken === 'string')
    ) && condition

    return accessGranted ? children : <Navigate to={redirectTo} replace />
}

export default PrivateRoute