import ReviewItem from '../common/ReviewItem'

function TopUserReviewSection() {
    return (
        <>
            <div className='review-section flex flex-col gap-5 h-auto px-4 py-16 md:px-24 lg:px-40'>
                <h2 className='text-xl font-bold'>《評価・レビュー》</h2>
                <ReviewItem
                    gender='女性'
                    age='20代'
                    reviewText={<span>今カレとの関係が不安定で、どうしたらいいかわからなかったのですが、SNSで凛さんの投稿を見てなぜか惹かれてしまい…占いは初めてだったのですが思いきって相談してみました。親身になって丁寧な鑑定をしてくださり、2人の今後の展開やアドバイスをいただきました。<br />カレとの関係においては本当に今の状況をズバリ的確に言い当てられていて、ビックリ！しました。笑<br />凛さんに相談して、気持ちが楽になりました。また悩んだ際はぜひ相談させていただこうと思いますので、よろしくお願いします。ありがとうございました！</span>}
                />
                <ReviewItem
                    gender='女性'
                    age='40代'
                    reviewText={<span>長いあいだ、夫婦関係に悩んでいたので、改善のためのアドバイスをいただきたいと凛さんに相談しました。<br />私の現在の生活や夫婦関係について、私の気持ちを汲み取ってくれて、優しく丁寧な言葉で鑑定をしてくださりました。<br />いただいたアドバイスを実践したところ、喧嘩することも減り、旦那さんの雰囲気も変わって家庭内の空気が明るくなりました。おかげで家族で過ごす時間が増え、今では楽しく幸せな時間を過ごせるようになりました。本当に感謝しています！<br />また悩みがあれば、相談させていただきたいと思います。ありがとうございました！</span>}
                />
                <ReviewItem
                    gender='女性'
                    age='20代'
                    reviewText={<span>元彼との復縁で悩んでいた際に、どのように彼にアプローチすれば分からなかったので、凛さんに鑑定をお願いしました。<br />正直「もう無理かな」と思っていたのですが、凛さんは鑑定で、彼との関係を取り戻すためのアドバイスや考え方を教えてくれました。<br />彼の気持ちも教えてくれていて、なるほどなと思わされることが多かったです。私の気持ちに関しても、まるで代弁してくれるような言葉に救われました。今では彼との関係も元通りになり、幸せな日々を過ごせています。あの時、凛さんに相談して良かったです。支えになっていただきありがとうございました！</span>}
                />
                <ReviewItem
                    gender='女性'
                    age='30代'
                    reviewText={<span>彼とズルズルした関係が続いていて、自分の気持ちにも踏ん切りがつけられずにいたので、凛さんに相談しました。<br />いただいた鑑定では、私の気持ちを見事にそのまま言葉にされていて、驚いたとともに、一気に涙が溢れてしまいました。<br />こんな私の気持ちに寄り添っていただいて、安心感と勇気をいただきました。おかげで彼との関係を断ち切ることができ、自分で新しい一歩を踏み出すことができました。いま前向きな気持ちで過ごせているのは凛さんのおかげです。本当にありがとうございました。また新しい恋の悩みができた時は、凛さんに相談したいと思います。</span>}
                />
                <ReviewItem
                    gender='男性'
                    age='30代'
                    reviewText={<span>仕事について、現職における悩みや、今後のキャリアなどについて鑑定を行っていただきました。私が抱えている悩みや不安・状況をズバリ言い当てられて、かなり驚きました。凛さんは明確な言葉で説明してくださり、未来へのアドバイスもいただきました。<br />いただいた言葉を胸に毎日を過ごしています。モヤモヤや不安も軽減して、今では仕事に対するモチベーションも上がりました。この度はありがとうございました。</span>}
                />
            </div>
        </>
    )
}

export default TopUserReviewSection