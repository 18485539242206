import { Link } from 'react-router-dom'
import { TiArrowBack } from 'react-icons/ti'
import Footer from '../components/layout/Footer'

function NotFound() {
    return (
        <>
            <div className='w-full h-full'>
                <div className="flex items-center text-center h-full mb-14">
                    <div className="max-w-2xl mx-auto">
                        <h1 className="text-6xl md:text-7xl lg:text-8xl font-bold mb-8">
                            404<br />
                            <span className='text-4xl md:text-5xl lg:text-6xl'>Page not found.</span>
                        </h1>
                        <p className="text-lg md:text-xl lg:text-2xl text-gray-500 mb-8">
                            申し訳ございません。<br />
                            お探しのページが見つかりませんでした。
                        </p>
                        <Link to='/top' className='btn btn-primary btn-md capitalize text-base'>
                            <TiArrowBack className='text-2xl mr-2' />
                            トップページに戻る
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default NotFound