import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/layout/Footer'
import CollapseItem from '../components/common/CollapseItem'

function FrequentlyAskedQuestion() {
    return (
        <>
            <div className='w-full h-full'>
                <div className='h-full my-14'>
                    <div className='text-center py-14'>
                        <div className='bg-base-300 text-base-content rounded-lg w-4/5 mx-auto py-5'>
                            <h2 className='text-2xl font-bold'>よくある質問(FAQ)</h2>
                            <p className='text-xs mt-5'>
                                皆様からよく寄せられる質問をまとめました。<br />お問い合わせの前にご覧ください☺️🌟
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-5 w-4/5 mx-auto'>
                        <div>
                            <h3 className='font-bold'>~ 鑑定について ~</h3>
                        </div>
                        <CollapseItem
                            title={'Q. 鑑定結果の受け取り方法は？'}
                            content={<span>鑑定のお申し込み(記入＆送信)が完了されている場合は『<Link to='/order/how-to-start' className='text-info font-bold'>鑑定スタート手順ページ</Link>』を参考に進めてください。<br />鑑定結果は、凛(Rin)の<span className='font-bold'>LINE公式アカウント</span>とのチャットにてお送りいたします。^^</span>}
                        />
                        <CollapseItem
                            title={'Q. 鑑定結果が届きません。'}
                            content={<span>1. <span className='font-bold'>鑑定のスタート</span>の手順を完了されていない可能性がございます。<br />『<Link to='/order/how-to-start' className='text-info font-bold'>鑑定スタート手順ページ</Link>』を参考に進めてください。<br /><br />2. <Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>からご注文のステータスが『<span className='text-warning font-bold'>鑑定中</span>』であるかどうかもご確認くださいね^^</span>}
                        />
                        <CollapseItem
                            title={'Q. 購入できる鑑定メニューは他にもありますか？'}
                            content={'現在は、当サイトに掲載されているメニューのみ購入可能でございます。\n\n近い将来、"新しい鑑定メニュー" を追加する可能性もございますので、楽しみにしていてくださいね♡'}
                        />
                        <CollapseItem
                            title={'Q. 対面鑑定は行っていますか？'}
                            content={'対面鑑定は行っておりません。><\n現在は《チャット鑑定》のみとなります。'}
                        />
                        <CollapseItem
                            title={'Q. 電話鑑定は行っていますか？'}
                            content={'電話鑑定は行っておりません。><\n現在は《チャット鑑定》のみとなります。'}
                        />
                        <div className='mt-7'>
                            <h3 className='font-bold'>~ 料金・決済について ~</h3>
                        </div>
                        <CollapseItem
                            title={'Q. 鑑定料金は税込ですか？'}
                            content={'はい。^^\n表示されている料金は全て税込価格です。'}
                        />
                        <CollapseItem
                            title={'Q. どんな支払い方法がありますか？'}
                            content={<span>『クレジットカード』<br />『Apple Pay』<br />でのお支払い可能です。^^</span>}
                        />
                        <CollapseItem
                            title={'Q. 使えるカードのブランドは？'}
                            content={<span>現在は、以下のクレジットカードがご利用いただけます。<br/><span className='font-bold'><br />・VISA<br />・MasterCard<br />・JCB<br />・American Express(AMEX)<br />・Diners Club<br />・Discover</span><br /><br />他のカードブランドも、順次ご利用いただけるようになる予定です。^^</span>}
                        />
                        <CollapseItem
                            title={'Q. デビットカードも使えますか？'}
                            content={<span>はい。^^<br /><br />デビットカードやプリペイドカード（au walletプリペイドカード、LINE Payカード、バンドルカードなど）は、採用している決済システムが対応しているカードブランドロゴがプリントされたカードであれば、通常は利用可能です。<br /><br /><span className='underline'>ただし、カード自体に問題がなくてもご利用いただけないケースがあります。</span><br />これはカード発行会社の設定に起因するもので、当サービスの運営側で原因を特定・把握することはできません。<br /><br />カード情報（限度額や残高、有効期限）に問題がなくてもご利用ができなかった場合は、恐れ入りますが当サービスではそのカードはご利用不可であるとご認識くださいますようお願いいたします。</span>}
                        />
                        <CollapseItem
                            title={'Q. Apple Payのボタンが表示されません。'}
                            content={<span>
                                Apple Payは<span className='font-bold underline'>macOs、iOSのSafari</span>でのみご利用いただけます🙇‍♀️<br />Google Chromeではご利用いただけない場合がございますので、ご注意ください。<br /><br />また、Apple Payのご利用には、<span className='font-bold'>Apple ID</span>と<span className='font-bold'>Touch ID</span>または<span className='font-bold'>Face ID</span>が必要です。<br /><br />Apple Payのご利用方法については、<a href='https://support.apple.com/ja-jp/HT201469' target="_blank" rel="noopener noreferrer" className='font-bold text-info'>こちら</a>をご参照ください。
                            </span>}
                        />
                        <CollapseItem
                            title={'Q. 支払い履歴はどこで確認できますか？'}
                            content={'支払い履歴は、ご利用のクレジットカード会社や銀行のサイトからご確認ください。\nまた、お支払い完了後に送られるメールにも明細が記載されています。'}
                        />
                        <div className='mt-7'>
                            <h3 className='font-bold'>~ ご注文について ~</h3>
                        </div>
                        <CollapseItem
                            title={'Q. なぜ本人確認情報が必要なのですか？'}
                            content={'ご注文ごとに本人確認情報をご入力いただくのは、セキュリティ対策のためです。\nお客様の大切な情報を守るため、ご協力をお願いしております。'}
                        />
                        <CollapseItem
                            title={'Q. 「注文ID」とは何ですか？'}
                            content={<span>"注文ID"は、鑑定料金のお支払い時に発行されるIDです。<br />「鑑定の申込」や「ご注文の詳細・状況確認」の際に必要となりますので、<span className='text-error font-bold'>必ず大切に保管</span>してください。</span>}
                        />
                        <CollapseItem
                            title={'Q. 「あいことば」とは何ですか？'}
                            content={<span>"あいことば"は、鑑定料金のお支払い時に"注文ID"と一緒に発行されるキーワードです。<br />「お支払い完了画面」や「お客様専用のポータル画面」から再設定することも可能です。<br />"注文ID"と同様に「鑑定の申込」や「ご注文の詳細・状況確認」の際に必要となりますので、<span className='text-error font-bold'>必ず大切に保管</span>してください。</span>}
                        />
                        <CollapseItem
                            title={'Q. 注文ID/あいことばを忘れました。'}
                            content={<span>ご安心ください。^^<br />注文IDを忘れてしまっても、お客様の「メールアドレス」と「お名前」で再取得が可能です。<br />お手数ですが、<Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>から「ポータル画面URL」を発行して、ご確認ください。</span>}
                        />
                        <CollapseItem
                            title={'Q. 注文はキャンセルできますか？'}
                            content={<span>はい。可能です^^<br /><span className='font-bold italic'>・ご購入後30日以内</span><br /><span className='font-bold italic'>・ご相談内容を送信する前</span><br />この2つを満たしている場合は、注文をキャンセルすることが可能です。<br /><br />注文のキャンセルは、お客様専用の<span className='font-bold'>ポータル画面</span>から可能です。<br /><Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>から「ポータル画面URL」を発行できます。</span>}
                        />
                        <div className='mt-7'>
                            <h3 className='font-bold'>~ その他 ~</h3>
                        </div>
                        <CollapseItem
                            title={'Q. 推奨環境などはありますか？'}
                            content={<span>当サイトを快適にご利用いただくために、以下の環境でのご利用を推奨いたします。<br />また、JavaScript、Cookieが使用できる状態でご利用くださいませ🍀<br /><br />《<span className='font-bold'>推奨ブラウザ</span>》<br />・Safari（最新版）<br />・Google Chrome （最新版）</span>}
                        />
                        {/* <CollapseItem
                            title={'Q. 推奨環境などはありますか？'}
                            content={<span>当サイトを快適にご利用いただくために、以下の環境でのご利用を推奨いたします。<br />また、JavaScript、Cookieが使用できる状態でご利用くださいませ🍀<br /><br />《<span className='font-bold'>推奨ブラウザ</span>》<br />・Safari*（最新版）<br />・Google Chrome （最新版）<br /><br />※<span className='underline font-bold'>ApplePayでのお支払いはmacOSまたはiOSの"Safari"でのみ可能です。</span>Google Chromeではご利用いただけませんのでご注意ください。</span>}
                        /> */}
                        <CollapseItem
                            title={'Q. 手元にメールが届きません。'}
                            content={<span>ご本人様情報として<span className='font-bold'>正しい綴りのメールアドレス</span>を登録できているかご確認ください。<br /><br />また《<span className='font-bold'>迷惑メールフォルダ</span>》に振り分けられている場合もございますので、そちらも併せてご確認ください☺️</span>}
                        />
                        <CollapseItem
                            title={'Q. LINE公式アカウントの友だち追加はどこでできますか？'}
                            content={<span>分かりづらくて申し訳ございません…。<br/>→<a href='https://lin.ee/eBK1Ka8' target="_blank" rel="noopener noreferrer" className='font-bold text-info'>こちらのリンク</a>から追加ができますので、よろしくお願いいたします☺️</span>}
                        />
                        <CollapseItem
                            title={'Q. 毎月1回鑑定プランの解除はどうすればいいですか？'}
                            content={<span>毎月1回プラン(月々払い)の解除は、<Link to='/menu/3' className='text-info font-bold'>鑑定メニューページ</Link>下部にある「<span className='text-error'>プランのキャンセルはこちら</span>」リンクより可能でございます。<br />画面に表示される案内に従いながらお手続きを進めてください。</span>}
                        />
                        <CollapseItem
                            title={'Q. 鑑定結果を受け取る前に毎月1回鑑定プランを解除しましたが、鑑定結果は受け取れますか？'}
                            content={<span>すでに"期間サイクルのお支払い"が確定している場合は、受け取ることが可能です。<br /><br />お支払いが確定していない場合は、鑑定を申し込まれていた(もしくはスタートされていた)としても、鑑定結果を受け取ることはできません。<br />不正利用を防止するため、そのようなシステムとなっております。何卒ご了承くださいませ。<br />プランを再開してお支払いを確定させるには、<Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>からの操作をお願いいたします。</span>}
                        />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default FrequentlyAskedQuestion