import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UseAuthAxios from '../../../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import UseValidation from '../../../utils/hooks/UseValidation'
import ApplicationConfirmModal from '../../modals/ApplicationConfirmModal'

function OneTarotApplicationForm({ themeName, accessToken }) {

    const textAreaMaxLength = 200 //! Oneタロットは200文字、通常＆サブスクは3000文字
    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const location = useLocation()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [textAreaLength, setTextAreaLength] = useState(0)
    const [formData, setFormData] = useState({
        info: {
            name: '',
            birthDate: '',
            gender: '',
            bloodType: '',
            textBody: ''
        },
        message: {
            name: '',
            birthDate: '',
            gender: '',
            bloodType: '',
            textBody: ''
        }
    })
    const { info, message } = formData

    const allInfoValuesFilled = obj => Object.values(obj).every(x => x !== '')
    const allMessageValuesEmpty = obj => Object.values(obj).every(x => x === '')

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        const buttonShouldBeEnabled = allInfoValuesFilled(info) && allMessageValuesEmpty(message);
        setButtonDisabled(!buttonShouldBeEnabled);
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const showApplicationConfirmModal = (e) => {
        e.preventDefault()
        document.getElementById('application_confirm_modal').showModal()
    }

    const handleSubmit = async () => {
        toggleLoading()  // ローディング開始
        const sanitizedInfo = { ...info, textBody: info.textBody.replace(/[\s\n\r　]+$/, '') }; // 送信前に末尾のスペースや改行を削除
        const data = {
            order_id: new URLSearchParams(location.search).get('order_id'),
            watchword: new URLSearchParams(location.search).get('watchword'),
            applicant_name: sanitizedInfo.name,
            birth_date: sanitizedInfo.birthDate,
            gender: sanitizedInfo.gender,
            blood_type: sanitizedInfo.bloodType,
            consult_content: sanitizedInfo.textBody
        }
        try {
            // ここにエンドポイントを記述
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const applicationResponse = await authAxios.post('/order/apply', data, config, { withCredentials: true })
            if (applicationResponse.status === 201) {
                toast.success('申し込みが完了しました✨\n鑑定を『スタート』させましょう！', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                // 鑑定スタート手順ページにリダイレクト
                navigate('/order/how-to-start')
            }
        } catch (error) {
            if ((error?.response?.status === 400) || (error?.response?.status === 401 && error?.response?.data?.detail === 'Invalid Credentials')) {
                toast.error('不正なアクセスです。\nトップページに戻ります。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            } else if (error?.response?.status === 401 && error?.response?.data?.detail === 'Credentials have expired') {
                toast.error('セッションが切れました。\nもう一度ページにアクセスしてください。', {
                    className: 'break-newline',
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/order/collation')
            } else if (error?.response?.status === 404) {
                toast.error('リクエストが正しくありません。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            } else if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            }
        }
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    const calculateTextLength = (char, currentPoints) => {
        // 全角文字は2ポイント、半角文字（改行含む）は1ポイント
        // eslint-disable-next-line
        return currentPoints + (char.match(/[\n]|\r/) || !char.match(/[^\x01-\x7E\xA1-\xDF]/) ? 1 : 2);
    }

    const handleTextAreaChange = (event) => {
        const key = event.target.name
        const value = event.target.value
        if (value === '') {
            setTextAreaLength(0)
            setFormData(() => ({
                info: { ...info, textBody: value },
                message: { ...message, [key]: UseValidation.formValidate(key, value)}
            }))
            return
        }
        let tempText = ''
        let currentPoints = 0

        for (let i = 0; i < value.length; i++) {
            const char = value[i];
            const newPoints = calculateTextLength(char, currentPoints);
            if (newPoints <= textAreaMaxLength) {
                tempText += char;
                currentPoints = newPoints;
                setTextAreaLength(currentPoints);
            } else {
                break; // 許容ポイントを超えるとループを抜ける
            }
        }
        setFormData(() => ({
            info: {...info, textBody: tempText},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <form onSubmit={showApplicationConfirmModal}>
                <div className='flex flex-col gap-2 md:gap-5'>
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            name='name'
                            type="text"
                            maxLength={20}
                            className="grow"
                            placeholder="あなたを表すお名前"
                            required
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </label>
                    {message.name && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.name}</p>}
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            name='birthDate'
                            type="text"
                            maxLength={8}
                            pattern="\d{8}"
                            className="grow"
                            placeholder="あなたの生年月日(半角数字8ケタ)"
                            required
                            value={info.birthDate || ''}
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </label>
                    {message.birthDate && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.birthDate}</p>}
                    <select
                        name="bloodType"
                        className="select select-bordered w-[70%] max-w-xs"
                        required
                        onChange={handleChange}
                        defaultValue=""
                        disabled={isLoading}
                    >
                        <option value="" disabled>あなたの血液型</option>
                        <option value="A型">A型</option>
                        <option value="B型">B型</option>
                        <option value="O型">O型</option>
                        <option value="AB型">AB型</option>
                        <option value="不明">不明</option>
                    </select>
                    <select
                        name="gender"
                        className="select select-bordered w-3/5 max-w-xs"
                        required
                        onChange={handleChange}
                        defaultValue=""
                        disabled={isLoading}
                    >
                        <option value="" disabled>あなたの性別</option>
                        <option value="女性">女性</option>
                        <option value="男性">男性</option>
                        <option value="その他">その他</option>
                    </select>
                    <label className="form-control">
                        <div className='label py-1 pl-3'>
                            <span className='label-text'>▼現在のお悩み✏︎</span>
                        </div>
                        <textarea
                            name='textBody'
                            value={info.textBody || ''}
                            onChange={handleTextAreaChange}
                            required
                            className="textarea textarea-bordered text-base h-[20vh]"
                            placeholder={"現在のお悩みをご記入ください。"}
                            disabled={isLoading}
                        />
                        <div className="label flex justify-end">
                            <span className="label-text-alt">{textAreaLength}&nbsp;/&nbsp;200文字</span>
                        </div>
                    </label>
                    {message.textBody && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.textBody}</p>}
                    {buttonDisabled ?
                        <div className='btn btn-active btn-ghost cursor-not-allowed mt-4'>
                            送信
                        </div> :
                        isLoading ?
                            <div className='btn btn-secondary text-base md:text-lg w-full mt-4'>
                                <span className="loading loading-spinner loading-base text-secondary-content" />
                            </div> :
                            <button
                                type="submit"
                                className='btn btn-secondary text-base md:text-lg w-full mt-4'
                            >
                                送信
                            </button>
                    }
                </div>
            </form>
            <ApplicationConfirmModal
                info={info}
                handleSubmit={handleSubmit}
            />
        </>
    )
}

export default OneTarotApplicationForm