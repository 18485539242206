import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { themeChange } from 'theme-change'
import TopMain from './components/layout/TopMain'
import ThemeNameProvider from './utils/contexts/ThemeName'
import PrivateRouteAccessProvider from './utils/contexts/PrivateRouteAccess'
import AccessTokenProvider from './utils/contexts/AccessToken'

function App() {

  useEffect(() => {
    themeChange(false)
  },[])

  return (
    <>
      <ThemeNameProvider>
        <PrivateRouteAccessProvider>
          <AccessTokenProvider>
            <Routes>
              <Route path='/*' element={<TopMain />} index />
            </Routes>
          </AccessTokenProvider>
        </PrivateRouteAccessProvider>
      </ThemeNameProvider>
    </>
  );
}

export default App;
