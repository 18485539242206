import React from 'react'

function RepeatConfirmModal({ setIsRepeater, showRegistrationConfirmModal, resetAllQuestions }) {
    return (
        <>
            <dialog id="repeat_modal" className="modal">
                <div className="modal-box border-2 border-base-content">
                    <h3 className="font-bold text-xl px-4">はじめてのご購入ですか？</h3>
                    <p className='text-xs px-4'>ご購入いただく際に必ず伺っております。</p>
                    <p className="text-xs px-4 my-5">
                        ※1. 以前にご購入いただいたことがある方は「<span className='font-bold'>いいえ</span>」をご選択ください☺️<br />
                        <br />
                        ※2. まだご購入はされていなくても、ご本人様情報をご記入いただいている場合は「<span className='font-bold'>いいえ</span>」をご選択ください🍀
                    </p>
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                <button
                                    className="btn btn-primary text-base md:text-lg w-2/5"
                                    onClick={() => {
                                        setIsRepeater(false)
                                        showRegistrationConfirmModal()
                                    }}
                                >
                                    はい
                                </button>
                                <button
                                    className="btn btn-secondary text-base md:text-lg w-2/5"
                                    onClick={() => {
                                        setIsRepeater(true)
                                        showRegistrationConfirmModal()
                                    }}
                                >
                                    いいえ
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => resetAllQuestions()}>close</button>
                </form>
            </dialog>
        </>
    )
}

export default RepeatConfirmModal