import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/layout/Footer'

function SpecifiedCommercialTransactionsAct() {
    return (
        <>
            <div className='w-full h-full'>
                <div className='h-full py-14'>
                    <div className='text-center py-14'>
                        <h2 className='text-base-content text-2xl font-bold'>特定商取引法に基づく表記</h2>
                    </div>
                    <div className='text-sm w-4/5 lg:w-3/4 md:w-3/4 mx-auto'>
                        <dl>
                            <dt className='text-lg font-bold my-2'>販売事業者名</dt>
                            <dd>請求を頂いた場合は、遅滞なく開示いたします。</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>所在地</dt>
                            <dd>同上</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>電話番号</dt>
                            <dd>同上</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>メールアドレス</dt>
                            <dd>rin.uranai06@gmail.com</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>受け付け可能な決済手段</dt>
                            <dd>クレジットカードがご利用いただけます。</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>引渡時期</dt>
                            <dd>注文はただちに処理され、商品は通常1〜5営業日以内で引き渡しとなります。</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>決済期間</dt>
                            <dd>クレジットカード決済の場合はただちに処理されます。</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>返品・交換・解約について</dt>
                            <dd>商品の性質上、返品は受け付けておりません。</dd>
                            <dd>交換については、商品に不良がある場合にのみ対応いたします。</dd>
                            <dd>定期課金の解約はいつでもポータルページから行っていただけます。(日割り分等での返金は承っておりませんのでご了承ください)</dd>
                            <dd></dd>
                            <dd>
                                ※交換やキャンセル/返金の概要や手続き手順については、<Link to='/cancel-policy' className='text-info'>キャンセルポリシー</Link>をご確認ください。
                            </dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>販売価格</dt>
                            <dd>商品ごとのページに表示しています</dd>
                            <dd>※表示価格は消費税込です</dd>

                            <dt className='text-lg font-bold mt-6 mb-2'>追加手数料等の追加料金</dt>
                            <dd>なし</dd>
                        </dl>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default SpecifiedCommercialTransactionsAct