import { useRef, useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import UseValidation from '../utils/hooks/UseValidation'
import { themeNameContext } from '../utils/contexts/ThemeName'
import { toast } from 'react-toastify'
import { PiCreditCardThin, PiCalendarCheckThin, PiDotsThreeDuotone, PiSignatureThin } from "react-icons/pi"

import rinCircleIcon from '../assets/images/rin_circle_icon.png'
import amexIcon from '../assets/images/amex.png'
import dinersIcon from '../assets/images/diners.png'
import discoverIcon from '../assets/images/discover.png'
import jcbIcon from '../assets/images/jcb.png'
import masterIcon from '../assets/images/master.png'
import visaIcon from '../assets/images/visa.png'


function SubscriptionResume({ accessToken }) {

    const authAxios = UseAuthAxios()
    const location = useLocation()
    const navigate = useNavigate()
    const { themeName } = useContext(themeNameContext)

    const expiryElementRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [cardInfo, setCardInfo] = useState({
        brand: '',
        empty: false,
        complete: false,
        error: null
    })
    const [formComplete, setFormComplete] = useState({
        numberComplete: false,
        expiryComplete: false,
        cvcComplete: false
    })
    const [formData, setFormData] = useState({
        info: {
            cardOwnerName: '',
        },
        message: {
            cardOwnerName: ''
        }
    })
    const { info, message } = formData

    useEffect(() => {
        const checkAddingNewCardsReady = async () => {
            try {
                const queryParams = new URLSearchParams(location.search)
                const paramOrderId = queryParams.get('order_id')
                const paramWatchword = queryParams.get('watchword')
                if (!paramOrderId || !paramWatchword) { navigate('/404') }
                const config = { headers: { Authorization: `Bearer ${accessToken}` } }
                await authAxios.get(`/subscription/resume/ready?order_id=${paramOrderId}&watchword=${paramWatchword}`, config, { withCredentials: true })
            } catch (error) {
                if (error?.response?.status === 400) {
                    navigate('/404')
                } else if (error?.response?.status === 429) {
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/order/collation')
                }
            }
        }
        if (sessionStorage.getItem('privateAccessGranted') && accessToken) {
            checkAddingNewCardsReady()
        } else {
            navigate('/404')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // elementsを初期化
        if (!window.payjp) {
            // windowオブジェクトにpayjpがまだ設定されていなければ、新しく生成
            window.payjp = window.Payjp(process.env.REACT_APP_PAYJP_PUBLIC_KEY);
        }
        const elements = window.payjp.elements();
        const numberElement = elements.create('cardNumber', {hideIcon: true});
        expiryElementRef.current = elements.create('cardExpiry');
        // const expiryElement = elements.create('cardExpiry');
        const cvcElement = elements.create('cardCvc');
        numberElement.mount('#number-form');
        expiryElementRef.current.mount('#expiry-form');
        cvcElement.mount('#cvc-form');

        const handleCardChange = (element, type) => {
            element.on('change', e => {
                setCardInfo(prev => ({
                    ...prev,
                    brand: element === numberElement && e.brand ? e.brand : prev.brand,
                    empty: typeof e.empty === 'boolean' ? e.empty : prev.empty,
                    complete: typeof e.complete === 'boolean' ? e.complete : prev.complete,
                    error: e.error && JSON.stringify(e.error)
                }));
                setFormComplete(prev => ({
                    ...prev,
                    [type + 'Complete']: e.complete
                }));
            });
        };

        handleCardChange(numberElement, 'number');
        handleCardChange(expiryElementRef.current, 'expiry');
        handleCardChange(cvcElement, 'cvc');

        return () => {
            numberElement.unmount();
            expiryElementRef.current.unmount();
            cvcElement.unmount();
            window.onsubmit = null;
        }
    }, []);

    const sendAddNewCardsRequest = async (cardToken) => {
        setIsLoading(true)
        try {
            const data = { cardToken: cardToken }
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const addNewCardsResponse = await authAxios.post('/subscription/resume/', data, config, { withCredentials: true })
            if (addNewCardsResponse.status === 200) {
                toast.success('新しいカードを追加しました！\nプランが再開されました✨\n鑑定の状況をご確認ください☺️', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 4000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate(`/order/collation`)
            }
        } catch (error) {
            console.log(error)
            const addNewCardErrorResponse = error?.response
            if ((addNewCardErrorResponse?.status === 400) && (addNewCardErrorResponse?.data?.detail === 'Invalid request')) {
                toast.error('無効なリクエストです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/order/collation')
            } else if (addNewCardErrorResponse?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/order/collation')
            } else {
                toast.error('カードの追加に失敗しました。\n最初からやり直してください。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/order/collation')
            }
        }
    }

    const executeElementsFormSubmit = (event) => {
        toggleLoading()
        event.preventDefault();
        window.payjp.createToken(expiryElementRef.current, { card: { name: info.cardOwnerName }}).then(function (response) {
            if (response.id) {
                const newCardToken = response.id
                sendAddNewCardsRequest(newCardToken)
            }
            if (response.error) {
                document.querySelector('#error').innerText = response.error && response.error.message
                // トークン作成時のエラーステータスコード：402
                // トークン作成は可能だが、支払い作成時のエラーステータスコード： 400
                setFormData(() => ({
                    info: '', message: ''
                }))
                setIsLoading(false)
            }
        });
    };

    // windowオブジェクトにonsubmitイベントハンドラーを設定
    window.onsubmit = executeElementsFormSubmit

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    const isFormValid = !cardInfo.error &&
                        formComplete.numberComplete &&
                        formComplete.expiryComplete &&
                        formComplete.cvcComplete &&
                        formData.message.cardOwnerName === '' &&
                        formData.info.cardOwnerName !== '';

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    useEffect(() => {
        //? コンポーネントがアンマウントされるときに sessionStorage から privateAccessGranted を削除するクリーンアップ関数
        return () => {
            sessionStorage.removeItem('privateAccessGranted')
        }
    }, [])

    return (
        <>
            <div className='w-full h-screen'>
                <div className='h-full'>
                    <div className='bg-accent text-accent-content bg-opacity-60 flex flex-col justify-evenly h-[45%] lg:h-1/2 px-5 md:px-20 py-4'>
                        <div className='lg:w-3/5 lg:mx-auto'>
                            <Link to={`/order/collation`}>
                                <button className="btn btn-xs md:btn-sm lg:btn-lg btn-ghost ">
                                    ←
                                    <img src={rinCircleIcon} alt='rin_circle' className='w-6 h-6 md:w-7 md:h-7' />
                                </button>
                            </Link>
                        </div>
                        <h2 className='text-3xl md:text-4xl text-center font-bold'>【カードの再登録】</h2>
                        <p className='text-xs md:text-lg text-center px-5'>
                            プランを再開させるために、<br />
                            新しく登録するクレジットカードの<br />
                            情報を入力してください。<br />
                        </p>
                    </div>
                    <div className='flex flex-col gap-5 px-5 md:px-40 py-10 md:py-14 lg:w-3/5 lg:mx-auto'>
                        <div className='flex flex-col items-end'>
                            {/* カードブランド画像を並べる */}
                            <div className='flex justify-evenly w-full'>
                                <img src={visaIcon} alt='visa' className='h-8' />
                                <img src={masterIcon} alt='master' className='h-8' />
                                <img src={jcbIcon} alt='jcb' className='h-8' />
                                <img src={amexIcon} alt='amex' className='h-8' />
                                <img src={dinersIcon} alt='diners' className='h-8' />
                                <img src={discoverIcon} alt='discover' className='h-8' />
                            </div>
                        </div>
                        <label className="input input-bordered bg-white flex items-center gap-2">
                            {(cardInfo.brand === '' || cardInfo.brand === 'unknown') && <PiCreditCardThin size={30} className='text-black' />}
                            {cardInfo.brand === 'American Express' && <img src={amexIcon} alt="amex" className='w-[30px]' />}
                            {cardInfo.brand === 'Diners Club' && <img src={dinersIcon} alt="diners" className='w-[30px]' />}
                            {cardInfo.brand === 'Discover' && <img src={discoverIcon} alt="discover" className='w-[30px]' />}
                            {cardInfo.brand === 'JCB' && <img src={jcbIcon} alt="jcb" className='w-[30px]' />}
                            {cardInfo.brand === 'MasterCard' && <img src={masterIcon} alt="master" className='w-[30px]' />}
                            {cardInfo.brand === 'Visa' && <img src={visaIcon} alt="visa" className='w-[30px]' />}
                            <div id="number-form" className="payjs-outer bg-white grow" />
                        </label>
                        <div className='flex gap-2'>
                            <label className="input input-bordered bg-white flex items-center gap-2 w-[55%]">
                                <PiCalendarCheckThin size={30} className='text-black' />
                                <div id="expiry-form" className="payjs-outer bg-white grow" />
                            </label>
                            <label className="input input-bordered bg-white flex items-center gap-2 w-[45%]">
                                <PiDotsThreeDuotone size={30} className='text-black' />
                                <div id="cvc-form" className="payjs-outer bg-white grow" />
                            </label>
                        </div>
                        <label className="input input-bordered bg-white flex items-center gap-2">
                            <PiSignatureThin size={30} className='text-black' />
                            <input
                                id='card-owner-name'
                                name='cardOwnerName'
                                type="text"
                                value={info.cardOwnerName || ''}
                                autoComplete='cc-name'
                                maxLength={20}
                                className="grow text-black"
                                placeholder="カード名義"
                                required
                                onChange={handleChange}
                            />
                        </label>
                        {message.cardOwnerName && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.cardOwnerName}</p>}
                        {(isFormValid && !isLoading) ?
                            <button
                                onClick={(e) => executeElementsFormSubmit(e)}
                                className='btn btn-primary'
                            >カードを登録</button> :
                            (isLoading) ?
                                <div className='btn btn-primary'>
                                    <span className="loading loading-spinner loading-base text-primary-content"></span>
                                </div> :
                                <div className='btn shadow-base-normal'>カードを登録</div>
                        }
                        {/* <p id="card-info-error" className='text-center text-error'>{cardInfo.error && JSON.parse(cardInfo.error)?.message}</p> */}
                        <p id="error" className='text-center text-error'>{cardInfo.error && JSON.parse(cardInfo.error)?.message}</p>
                    </div>
                    {/* <p>
                        <span id="number-brand">brand: {cardInfo.brand}</span><br />
                        <span id="number-empty">empty: {cardInfo.empty != null ? cardInfo.empty.toString() : 'N/A'}</span><br />
                        <span id="number-complete">complete: {cardInfo.complete != null ? cardInfo.complete.toString() : 'N/A'}</span><br />
                        <span id="number-error">error: {cardInfo.error}</span>
                    </p> */}
                </div>
            </div>
        </>
    )
}

export default SubscriptionResume