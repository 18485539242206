import { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../components/layout/Footer'
import TopBackgroundToCopy from '../components/layout/TopBackgroundToCopy'
import TopProfileSection from '../components/layout/TopProfileSection'
import TopUserReviewSection from '../components/layout/TopUserReviewSection'
import TopProductsMenu from '../components/layout/TopProductsMenu'

function TopPage() {

    // eslint-disable-next-line
    const [selectedSection, setSelectedSection] = useState('profile')
    const TopProfileRef = useRef(null)
    const ProductsMenuRef = useRef(null)
    const TopReviewRef = useRef(null)

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const section = params.get('section')
        if (section === 'profile') {
            setSelectedSection('profile')
            TopProfileRef?.current.scrollIntoView({ behavior: 'smooth' })
        } else if (section === 'menu') {
            setSelectedSection('menu')
            ProductsMenuRef?.current.scrollIntoView({ behavior: 'smooth' })
        } else if (section === 'review') {
            setSelectedSection('review')
            TopReviewRef?.current.scrollIntoView({ behavior: 'smooth' })
        } else {
            setSelectedSection('profile')
        }
    }, [location])

    return (
        <>
            <div className='w-full min-h-full'>
                <TopBackgroundToCopy />
                <div className='section-selector flex'>
                    <div
                        className='text-center font-bold text-sm md:text-lg cursor-pointer border-b-2 border-primary w-1/2 py-3'
                        onClick={() => {
                            setSelectedSection('profile')
                            TopProfileRef?.current.scrollIntoView({ behavior: 'smooth' })
                        }}
                    >
                        プロフィール
                    </div>
                    <div
                        className='text-center font-bold text-sm md:text-lg cursor-pointer w-1/2 py-3'
                        onClick={() => {
                            setSelectedSection('menu')
                            ProductsMenuRef?.current.scrollIntoView({ behavior: 'smooth' })
                        }}
                    >
                        鑑定メニュー
                    </div>
                    <div
                        className='text-center font-bold text-sm md:text-lg cursor-pointer w-1/2 py-3'
                        onClick={() => {
                            setSelectedSection('review')
                            TopReviewRef?.current.scrollIntoView({ behavior: 'smooth' })
                        }}
                    >
                        評価・レビュー
                    </div>
                </div>
                <div id='profile' ref={TopProfileRef}>
                    <TopProfileSection />
                </div>
                <div id='menu' ref={ProductsMenuRef}>
                    <TopProductsMenu />
                </div>
                <div id='review' ref={TopReviewRef}>
                    <TopUserReviewSection />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default TopPage