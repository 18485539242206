import { useContext, useState } from 'react'
import UseAuthAxios from '../../utils/hooks/UseAuthAxios'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { FaUser } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'

// バリデーションルールを定義
const schema = yup.object({
    email: yup.string().required('メールアドレスは必須です').email('正しいメールアドレスを入力してください'),
    name: yup.string().required('名前は必須です').min(2, '名前は2文字以上で入力してください').max(20, '名前は20文字以内で入力してください')
}).required();

function PortalPageLinkSendModal({ resetAll }) {

    const authAxios = UseAuthAxios()
    const { themeName } = useContext(themeNameContext)
    const [isLoading, setIsLoading] = useState(false)

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const resetAllOfThis = () => {
        reset() //clear all inputs of react-hook-form
        setIsLoading(false)
        document.getElementById('portal_page_link_send_modal').close()
        resetAll()
    }

    const closeThisModal = () => {
        resetAllOfThis()
        setIsLoading(false)
        document.getElementById('portal_page_link_send_modal').close()
    }

    const onSubmit = (data) => {
        setIsLoading(true)

        const EnterPortalPageLinkSendForm = async () => {
            try {
                const confirmResponse = await authAxios.post('/order/portal-generate', data)
                if (confirmResponse.status === 204) {
                    document.getElementById('portal_page_link_send_modal').close()
                    toast.success('ポータル画面URLを送信しました📩\nメール受信BOXをご確認ください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    closeThisModal()
                }
            } catch (error) {
                setIsLoading(false)
                const cancelCodeSendErrorResponse = error?.response
                if (cancelCodeSendErrorResponse?.status === 400) {
                    resetAllOfThis()
                    toast.error('無効なリクエストです。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 404 && cancelCodeSendErrorResponse?.data?.detail === 'User not found.') {
                    resetAllOfThis()
                    toast.warning('メールアドレスまたは\nお名前が正しくないようです。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 404 && cancelCodeSendErrorResponse?.data?.detail === 'Not found.') {
                    resetAllOfThis()
                    toast.warning('ご注文情報が見つかりません。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 404 && cancelCodeSendErrorResponse?.data?.detail === 'Resource not found.') {
                    resetAllOfThis()
                    toast.error('無効なリクエストです。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 429) {
                    resetAllOfThis()
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                }
            }
        }
        EnterPortalPageLinkSendForm()
    };

    return (
        <>
            <dialog id="portal_page_link_send_modal" className="modal">
                <div className="modal-box border-2 border-base-content">
                    <h3 className="font-bold text-xl">
                        <span className='underline'>【ご本人様情報のご記入】</span>をお願いいたします✏️.｡.:*・ﾟ✽
                    </h3>
                    <p className="text-sm py-2 my-2 md:my-4">
                        ポータル画面URLを用意するにあたり、ご記入いただく必要がございます🍀🔑
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-2 w-full'>
                            <label className="input input-bordered flex items-center gap-2">
                                <MdMail className='text-xl' />
                                <input
                                    type="email"
                                    {...register('email')}
                                    autoComplete='email'
                                    className="grow"
                                    placeholder="メールアドレス"
                                    disabled={isLoading}
                                />
                            </label>
                            {errors.email && <p className='text-error text-xs'>{errors.email.message}</p>}
                            <label className="input input-bordered flex items-center gap-2">
                                <FaUser className='text-lg' />
                                <input
                                    type="text"
                                    maxLength={20}
                                    {...register('name')}
                                    className="grow"
                                    placeholder="お名前(ニックネームなども可)"
                                    disabled={isLoading}
                                />
                            </label>
                            {errors.name && <p className='text-error text-xs'>{errors.name.message}</p>}
                            {isLoading ?
                                <div className='btn btn-secondary shadow-base-normal text-base md:text-lg w-full mt-2'>
                                    <span className="loading loading-spinner loading-base text-secondary-content"></span>
                                </div> :
                                <button
                                    type="submit"
                                    className='btn btn-secondary shadow-base-normal text-base md:text-lg w-full mt-2'
                                >送信</button>
                            }
                        </div>
                    </form>
                    <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                {!isLoading ?
                                    <button
                                        className="btn btn-neutral text-base md:text-lg w-full"
                                        onClick={() => resetAllOfThis()}
                                    >
                                        キャンセル
                                    </button> :
                                    <div className="btn btn-neutral text-base md:text-lg w-full">キャンセル</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    {!isLoading &&
                        <button onClick={() => resetAllOfThis()}>close</button>
                    }
                </form>
            </dialog>
        </>
    )
}

export default PortalPageLinkSendModal