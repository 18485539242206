import React from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'

function PaymentSuccessModal() {
    return (
        <>
            <div className='bg-base-100 rounded flex flex-col text-base-content h-full w-full'>
                <div className='flex flex-col items-center justify-around py-4 h-full'>
                    <BsCheckCircleFill className='text-success text-7xl md:text-8xl lg:text-9xl' />
                    <h2 className='text-2xl md:text-3xl lg:text-4xl font-bold'>お支払いが完了しました</h2>
                    <div className='flex flex-col gap-3 text-center'>
                        <p className='text-sm md:text-lg'>ご購入いただき、ありがとうございます💐</p>
                        <p className='text-sm md:text-lg'>ポップアップウィンドウを閉じると、<br />今回の注文の詳細が表示されます。</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccessModal