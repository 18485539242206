import { useContext, useEffect, useState } from 'react'
import UseAuthAxios from '../../../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import { accessTokenContext } from '../../../utils/contexts/AccessToken'
import { themeNameContext } from '../../../utils/contexts/ThemeName'
import { IoPricetag, IoChatbubbleEllipses } from 'react-icons/io5'
import UseValidation from '../../../utils/hooks/UseValidation'

function PortalOrderCancelModal({ toCancelOrderId, setToCancelOrderId, setAlreadyCanceledOrderIds }) {

    const authAxios = UseAuthAxios()
    const { accessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [formData, setFormData] = useState({
        info: {
            orderId: '',
            watchword: '',
            cancelReason: ''
        },
        message: {
            orderId: '',
            watchword: '',
            cancelReason: ''
        }
    })
    const { info, message } = formData

    useEffect(() => {
        if (toCancelOrderId) {
            setFormData({
                info: {
                    orderId: toCancelOrderId,
                    watchword: '',
                    cancelReason: ''
                },
                message: {
                    orderId: '',
                    watchword: '',
                    cancelReason: ''
                }
            })
        }
    },[toCancelOrderId])

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        if (info.orderId !== '' && info.watchword !== '' && info.cancelReason !== '') {
            if (message.orderId === '' && message.watchword === '' && message.cancelReason === '') {
                setButtonDisabled(false)
            } else if (message.orderId !== '' || message.watchword !== '' || message.cancelReason !== '') {
                setButtonDisabled(true)
            }
        } else {
            setButtonDisabled(true)
        }
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const closeThisModalProcess = () => {
        setButtonDisabled(true)
        setIsLoading(false)
        setToCancelOrderId('')
        setFormData({
            info: {
                orderId: '',
                watchword: '',
                cancelReason: ''
            },
            message: {
                orderId: '',
                watchword: '',
                cancelReason: ''
            }
        })
        document.getElementById('portal_order_cancel_modal').close()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        toggleLoading()  // ローディング開始
        const data = {
            order_id: info.orderId,
            watchword: info.watchword,
            reason: info.cancelReason
        }
        try {
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const response = await authAxios.post('/order/cancel', data, config, { withCredentials: true })
            if (response.status === 200) {
                toggleLoading()
                document.getElementById('portal_order_cancel_modal').close()
                toast.success('注文のキャンセルが完了しました😌\nお知らせメールをご確認ください。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2500,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setAlreadyCanceledOrderIds(prevState => [...prevState, info.orderId])
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                toast.error('該当する注文が見つかりませんでした。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            } else if (error?.response?.status === 400 || error?.response?.status === 401) {
                toast.error('不正なリクエストです。\n最初からやり直してください。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            } else if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            }
        }
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <dialog id="portal_order_cancel_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content">
                    <h3 className="font-bold text-lg">
                        注文をキャンセルします。<br />
                        本当によろしいですか？
                    </h3>
                    <p className='text-error text-xs md:text-base px-4 my-4'>
                        ⚠️ 鑑定をキャンセルすると元に戻せません。⚠️
                    </p>
                    <form onSubmit={handleSubmit} className='w-full px-4'>
                        <div className='flex flex-col gap-3 md:gap-5'>
                            <label className="input input-bordered flex items-center gap-2">
                                <IoPricetag className='text-xl' />
                                <input
                                    name='orderId'
                                    type="text"
                                    autoComplete='text'
                                    maxLength={6}
                                    className="grow"
                                    placeholder="注文ID"
                                    required
                                    value={info.orderId}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                />
                            </label>
                            {message.orderId && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.orderId}</p>}
                            <label className="input input-bordered flex items-center gap-2">
                                <IoChatbubbleEllipses className='text-lg' />
                                <input
                                    name='watchword'
                                    type="text"
                                    autoComplete='text'
                                    maxLength={20}
                                    className="grow"
                                    placeholder="あいことば"
                                    required
                                    value={info.watchword || ''}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                />
                            </label>
                            {message.watchword && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.watchword}</p>}
                            <select
                                name="cancelReason"
                                className="select select-bordered w-full max-w-xs"
                                required
                                onChange={handleChange}
                                value={info.cancelReason}
                                disabled={isLoading}
                            >
                                <option value="" disabled>キャンセル理由</option>
                                <option value={1}>注文が重複してしまった</option>
                                <option value={2}>商品が不要になった</option>
                                <option value={3}>誤って購入した</option>
                                <option value={4}>支払いに問題があった</option>
                                <option value={5}>レビュー・評価が悪い</option>
                                <option value={6}>サイトの信頼性への不安</option>
                                <option value={7}>その他</option>
                            </select>
                            {buttonDisabled ?
                                <div className='btn btn-active btn-ghost cursor-not-allowed'>
                                    送信
                                </div> :
                                isLoading ?
                                <div className='btn btn-primary text-base md:text-lg w-full'>
                                    <span className="loading loading-spinner loading-base text-primary-content" />
                                </div> :
                                <button
                                    type="submit"
                                    className='btn btn-primary text-base md:text-lg w-full'
                                >
                                    送信
                                </button>
                            }
                        </div>
                        <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                        {!isLoading &&
                            <div onClick={() => closeThisModalProcess()} className='btn btn-secondary w-full'>閉じる</div>
                        }
                        {isLoading &&
                            <div className='btn btn-secondary w-full'>閉じる</div>
                        }
                    </form>
                </div>
                <form method="dialog" className="modal-backdrop">
                    {!isLoading &&
                        <button onClick={() => closeThisModalProcess()}>close</button>
                    }
                </form>
            </dialog>
        </>
    )
}

export default PortalOrderCancelModal