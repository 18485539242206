import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseAuthAxios from '../../../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import UseValidation from '../../../utils/hooks/UseValidation'
import ContactConfirmModal from '../../modals/ContactConfirmModal'

function ContactForm({ themeName }) {

    const textAreaMaxLength = 1500
    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [textAreaLength, setTextAreaLength] = useState(0)
    const [formData, setFormData] = useState({
        info: {
            name: '',
            email: '',
            typeOfConcern: '',
            textBody: ''
        },
        message: {
            name: '',
            email: '',
            typeOfConcern: '',
            textBody: ''
        }
    })
    const { info, message } = formData

    const allInfoValuesFilled = obj => Object.values(obj).every(x => x !== '')
    const allMessageValuesEmpty = obj => Object.values(obj).every(x => x === '')

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        const buttonShouldBeEnabled = allInfoValuesFilled(info) && allMessageValuesEmpty(message);
        setButtonDisabled(!buttonShouldBeEnabled);
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const showContactConfirmModal = (e) => {
        e.preventDefault()
        document.getElementById('contact_confirm_modal').showModal()
    }

    const handleSubmit = async () => {
        toggleLoading()  // ローディング開始
        const sanitizedInfo = { ...info, textBody: info.textBody.replace(/[\s\n\r　]+$/, '') } // 送信前に末尾のスペースや改行を削除
        const data = {
            name: sanitizedInfo.name,
            email: sanitizedInfo.email,
            typeOfConcern: sanitizedInfo.typeOfConcern,
            textBody: sanitizedInfo.textBody
        }
        try {
            // ここにエンドポイントを記述
            const applicationResponse = await authAxios.post('/contact/send', data)
            if (applicationResponse.status === 201) {
                toast.success('お問合せが完了しました。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                // ! 送信後にtopページに遷移
                navigate('/top')
                return
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error('不正なリクエストを検出しました。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
                return
            }
            if (error?.response?.status === 404) {
                toast.error('リクエストが正しくありません。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
                return
            }
            if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
                return
            }
        }
    }

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked)
    };

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    const calculateTextLength = (char, currentPoints) => {
        // 全角文字は2ポイント、半角文字（改行含む）は1ポイント
        // eslint-disable-next-line
        return currentPoints + (char.match(/[\n]|\r/) || !char.match(/[^\x01-\x7E\xA1-\xDF]/) ? 1 : 2);
    }

    const handleTextAreaChange = (event) => {
        const key = event.target.name
        const value = event.target.value
        if (value === '') {
            setTextAreaLength(0)
            setFormData(() => ({
                info: { ...info, textBody: value },
                message: { ...message, [key]: UseValidation.formValidate(key, value)}
            }))
            return
        }
        let tempText = ''
        let currentPoints = 0

        for (let i = 0; i < value.length; i++) {
            const char = value[i];
            const newPoints = calculateTextLength(char, currentPoints);
            if (newPoints <= textAreaMaxLength) {
                tempText += char;
                currentPoints = newPoints;
                setTextAreaLength(currentPoints);
            } else {
                break; // 許容ポイントを超えるとループを抜ける
            }
        }
        setFormData(() => ({
            info: {...info, textBody: tempText},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <form onSubmit={showContactConfirmModal}>
                <div className='flex flex-col gap-2 md:gap-5'>
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            name='name'
                            type="text"
                            maxLength={20}
                            className="grow"
                            placeholder="あなたを表すお名前"
                            required
                            onChange={handleChange}
                            value={info.name || ''}
                            disabled={isLoading}
                        />
                    </label>
                    {message.name && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.name}</p>}
                    <label className="input input-bordered flex items-center gap-2">
                        <input
                            name='email'
                            type='email'
                            autoComplete='email'
                            maxLength={254}
                            className='grow'
                            placeholder='メールアドレス'
                            required
                            onChange={handleChange}
                            disabled={isLoading}
                        />
                    </label>
                    {message.email && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.email}</p>}
                    <select
                        name="typeOfConcern"
                        className="select select-bordered w-full md:w-3/5"
                        required
                        onChange={handleChange}
                        defaultValue=''
                        disabled={isLoading}
                    >
                        <option value="" disabled>お問い合わせの種類</option>
                        <optgroup label="- 購入・決済">
                            <option value="登録情報">登録情報について</option>
                            <option value="決済方法">決済方法について</option>
                            <option value="支払い情報">支払い情報について</option>
                        </optgroup>
                        <optgroup label="- 鑑定・プラン">
                            <option value="鑑定のお届け">鑑定のお届けについて</option>
                            <option value="利用中のプラン">利用中のプランについて</option>
                        </optgroup>
                        <optgroup label="- 当サイト">
                            <option value="サービスや商品">サービスや商品について</option>
                            <option value="エラー・不具合の報告">エラー・不具合の報告</option>
                            <option value="サイトに関するご意見">サイトへのご意見・ご感想</option>
                            <option value="情報の開示について">情報の開示について</option>
                        </optgroup>
                        <option value="その他">その他</option>
                    </select>
                    <label className="form-control">
                        <textarea
                            name='textBody'
                            value={info.textBody || ''}
                            onChange={handleTextAreaChange}
                            required
                            className="textarea textarea-bordered h-[40vh]"
                            placeholder={'お問い合わせ内容をご記入ください。\n\n※お問い合わせ内容によっては、お返事までにお時間をいただく場合がございます。予めご了承ください😌'}
                            disabled={isLoading}
                        />
                        <div className="label flex justify-end py-1">
                            <span className="label-text-alt">{textAreaLength}&nbsp;/&nbsp;1500文字</span>
                        </div>
                    </label>
                    {message.textBody && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.textBody}</p>}
                    <div className="form-control max-w-max mx-auto">
                        <label className="cursor-pointer label gap-2">
                            <input
                                type="checkbox"
                                className="checkbox checkbox-secondary"
                                onChange={handleCheckboxChange}
                                disabled={isLoading}
                            />
                            <span className="label-text">営業目的の問い合わせではありません</span>
                        </label>
                    </div>
                    {(buttonDisabled || !isChecked) ?
                        <div className='btn btn-active btn-ghost cursor-not-allowed mt-4'>
                            送信
                        </div> :
                        isLoading ?
                            <div className='btn btn-secondary text-base md:text-lg w-full mt-4'>
                                <span className="loading loading-spinner loading-base text-secondary-content" />
                            </div> :
                            isChecked &&
                            <button
                                type="submit"
                                className='btn btn-secondary text-base md:text-lg w-full mt-4'
                            >
                                送信
                            </button>
                    }
                </div>
            </form>
            <ContactConfirmModal
                info={info}
                handleSubmit={handleSubmit}
            />
        </>
    )
}

export default ContactForm