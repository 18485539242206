import { Link } from 'react-router-dom'
import { FaTiktok } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import RinIconImage from '../../assets/images/rin_circle_icon.png'


function Footer() {
    const footerYear = new Date().getFullYear()

    return (
        <footer className="footer bg-base-200 text-base-content p-10">
            <nav>
                <h6 className="footer-title">SITE MAP</h6>
                <Link to='/top' className="link link-hover">トップページ</Link>
                <Link to='/top?section=profile' className="link link-hover">プロフィール</Link>
                <Link to='/top?section=menu' className="link link-hover">鑑定メニュー</Link>
                <Link to='/top?section=review' className="link link-hover">レビュー/評価</Link>
            </nav>
            <nav>
                <h6 className="footer-title">SUPPORT</h6>
                <Link to='/order/collation' className="link link-hover">ご注文の詳細・状況確認</Link>
                <Link to='/order/how-to-start' className="link link-hover">鑑定のスタート方法</Link>
                <Link to='/faq' className="link link-hover">よくある質問(FAQ)</Link>
                <Link to='/contact' className="link link-hover">お問い合わせ</Link>
            </nav>
            <nav>
                <h6 className="footer-title">LEGAL</h6>
                <Link to='/terms-of-use' className="link link-hover">利用規約</Link>
                <Link to='/privacy-policy' className="link link-hover">プライバシーポリシー</Link>
                <Link to='/cancel-policy' className="link link-hover">キャンセルポリシー</Link>
                <Link to='/specified-commercial-transactions-act' className="link link-hover">特定商取引法に基づく表記</Link>
            </nav>
            <nav>
                <h6 className="footer-title">Social</h6>
                <div className="grid grid-flow-col gap-4">
                    <a href='https://www.instagram.com/rin__uranai/' target='_blank' rel='noopener noreferrer'>
                        <FaInstagram className='text-2xl' />
                    </a>
                    <a href='https://www.tiktok.com/@rin__uranai' target='_blank' rel='noopener noreferrer'>
                        <FaTiktok className='text-xl' />
                    </a>
                    <a href='https://twitter.com/rin__uranai' target='_blank' rel='noopener noreferrer'>
                        <FaXTwitter className='text-xl' />
                    </a>
                </div>
            </nav>
            <aside className="flex flex-col items-start">
                <div className='rounded-full shadow-base-normal'>
                    <img src={RinIconImage} alt='Rin Icon' className='w-12 h-12' />
                </div>
                <p className='font-bold'>Copyright © {footerYear} - All right reserved</p>
            </aside>
        </footer>
    )
}

export default Footer