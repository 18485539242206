import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaUser } from 'react-icons/fa'

function WatchwordChangeFormModal({ defaultWatchword, changeWatchwordNextStep }) {

    // バリデーションルールを定義
    const schema = yup.object({
        watchword: yup.string()
            .required('新しいあいことばを入力してください')
            .min(5, 'あいことばは5文字以上で入力してください')
            .max(20, 'あいことばは20文字以内で入力してください')
            .matches(/^[a-zA-Z0-9ぁ-んァ-ンー一-龠々]+$/, '絵文字または記号を含めないでください')
            .notOneOf([defaultWatchword], '同じあいことばは使用できません')
    }).required();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const onSubmit = (data) => {
        const newWatchword = data.watchword
        changeWatchwordNextStep(newWatchword)
        reset()
    }

    return (
        <>
            <dialog id="watchword_change_form_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content">
                    <h3 className="font-bold text-xl">
                        「あいことば」の変更
                    </h3>
                    <p className='text-xs p-4'>
                        ※この操作は<span className='font-bold text-error'>1回限り</span>です。<br />
                        ※変更後の「あいことば」は、ご注文の詳細・状況確認、鑑定スタートの際に必要となります。
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)} className='w-full md:w-3/4' method="dialog">
                        <div className='flex flex-col text-center gap-2 w-full'>
                            <p className='font-bold text-lg'>
                                {defaultWatchword}
                            </p>
                            <p>↓</p>
                            <label className="input input-bordered flex items-center gap-2">
                                <FaUser className='text-lg' />
                                <input
                                    type="text"
                                    maxLength={20}
                                    {...register('watchword')}
                                    className="grow"
                                    placeholder="新しい「あいことば」"
                                />
                            </label>
                            {errors.watchword && <p className='text-error text-xs'>{errors.watchword.message}</p>}
                            <button
                                type="submit"
                                className='btn btn-primary shadow-base-normal text-base md:text-lg w-full mt-2'
                            >決定する</button>
                        </div>
                    </form>
                    <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                <button
                                    className="btn btn-neutral text-base md:text-lg w-full"
                                    onClick={() => reset()}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
    )
}

export default WatchwordChangeFormModal