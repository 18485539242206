const menuAmountData = {
    1: {
        title: "Oneタロット鑑定",
        amount: 550,
    },
    2: {
        title: "通常お悩み鑑定",
        amount: 4400,
    },
    3: {
        title: "毎月1回お悩み鑑定",
        amount: 3300,
    },
    4: {
        title: "カラー鑑定",
        amount: 4400,
    },
    5: {
        title: "思念伝達鑑定",
        amount: 4400,
    }
}


const getAmountFromSelectedMenuNumber = (selectedMenuNumber) => {
    return menuAmountData[selectedMenuNumber]
}

function UseObtainMenuAmount(selectedMenuNumber) {
    return getAmountFromSelectedMenuNumber(selectedMenuNumber)
}

export default UseObtainMenuAmount;