import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import { accessTokenContext } from '../utils/contexts/AccessToken'
import { themeNameContext } from '../utils/contexts/ThemeName'
import UseValidation from '../utils/hooks/UseValidation'
import { IoPricetag, IoChatbubbleEllipses } from 'react-icons/io5'
import Footer from '../components/layout/Footer'

function SubscriptionCancel() {

    const authAxios = UseAuthAxios()
    const location = useLocation()
    const navigate = useNavigate()
    const { accessToken, setAccessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)

    const [accessRightGranted, setAccessRightGranted] = useState(false)
    const [cancelSucceeded, setCancelSucceeded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [currentPeriodEndSrt, setCurrentPeriodEndSrt] = useState(undefined)
    const [orderId, setOrderId] = useState(undefined)
    const [watchword, setWatchword] = useState(undefined)
    const [formData, setFormData] = useState({
        info: {
            orderId: '',
            watchword: '',
            cancelReason: ''
        },
        message: {
            orderId: '',
            watchword: '',
            cancelReason: ''
        }
    })
    const { info, message } = formData

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const token = queryParams.get('token')
        if (!token) {
            console.log('No token found!')
            navigate('/404')
        }
        const checkAccessRight = async () => {
            try {
                const config = { headers: { Authorization: `Bearer ${token}` } }
                const accessRightResponse = await authAxios.get(`/subscription/cancel?token=${token}`, config, { withCredentials: true })
                if (accessRightResponse.status === 204) {
                    setAccessRightGranted(true)
                    setAccessToken(token)
                }
            } catch (error) {
                if (error?.response.status === 404) {
                    toast.error('合致する情報が見つかりませんでした。\nお手数ですがプランの状況を再度ご確認いただくか、直接お問い合わせください🙇‍♀️', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 3500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                } else if (error?.response?.status === 429) {
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                } else if (error?.response.status === 400) {
                    toast.error('不正なアクセスです。', {
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                } else {
                    toast.error('エラーが発生しました。', {
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                    return
                }
            }
        }
        checkAccessRight()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        if (info.orderId !== '' && info.watchword !== '' && info.cancelReason !== '') {
            if (message.orderId === '' && message.watchword === '' && message.cancelReason === '') {
                setButtonDisabled(false)
            } else if (message.orderId !== '' || message.watchword !== '' || message.cancelReason !== '') {
                setButtonDisabled(true)
            }
        } else {
            setButtonDisabled(true)
        }
        // eslint-disable-next-line
    }, [formData])

    const handleCancelSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const data = {
            order_id: info.orderId,
            watchword: info.watchword,
            reason: info.cancelReason
        }
        try {
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const cancelResponse = await authAxios.post('/subscription/cancel', data, config, { withCredentials: true })
            if (cancelResponse.status === 200) {
                // * キャンセルリクエスト成功時の処理
                toast.success('ありがとうございます。\nプランをキャンセルしました😌', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setIsLoading(false)
                setCancelSucceeded(true)
                if (cancelResponse?.data?.current_period_end && cancelResponse?.data?.current_period_end !== '' &&
                    cancelResponse.data.access_token && cancelResponse.data.access_token !== '') {
                    setCurrentPeriodEndSrt(cancelResponse.data?.current_period_end)
                    setAccessToken(cancelResponse.data?.access_token)
                    setOrderId(info.orderId)
                    setWatchword(info.watchword)
                }
            }
        } catch (error) {
            if (error?.response?.status === 409 && error?.response?.data?.detail === 'Already canceled.') {
                toast.warning('プランはすでにキャンセル済みです。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            } else if (error.response.status === 404) {
                toast.error('該当する注文が見つかりませんでした。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
            } else if (error?.response?.status === 400) {
                toast.error('不正なリクエストです。\n最初からやり直してください。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            } else if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            } else {
                toast.error('エラーが発生しました。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                setButtonDisabled(true)
                setIsLoading(false)
                navigate('/top')
            }
        }
    }

    const handleImmediateResumeSubscription = async () => {
        try {
            setIsLoading(true)
            const data = { order_id: orderId, watchword: watchword }
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const immediateResumeResponse = await authAxios.post('/subscription/immediate/resume', data, config, { withCredentials: true })
            if (immediateResumeResponse.status === 200) {
                setIsLoading(false)
                toast.success('プランの再開が完了しました！✨', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/order/collation')
                return
            }
        }catch (error) {
            console.error(error)
            if (error?.response?.status === 400) {
                setIsLoading(false)
                toast.error('無効なリクエストです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/top')
            } else if (error?.response?.status === 404) {
                setIsLoading(false)
                toast.error('リクエストに失敗しました。\nこの操作は行えないようです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/top')
            } else if (error?.response?.status === 429) {
                setIsLoading(false)
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/top')
            } else {
                setIsLoading(false)
                toast.error('エラーが発生しました。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                navigate('/top')
            }
        }
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            { accessRightGranted &&
                <div className='w-full h-full'>
                    <div className='my-14 md:my-28 lg:my-14'>
                        {!cancelSucceeded &&
                            <>
                                <div className='text-center py-14 md:pt-14 md:pb-14 lg:py-14'>
                                    <h2 className='text-2xl md:text-3xl font-bold'>~ プランのキャンセル ~</h2>
                                    <p className='text-sm md:text-base px-4 mt-5'>
                                        以下のフォームに、<br />
                                        キャンセルを希望されるご注文(プラン)の<br />
                                        『注文ID』と『あいことば』を入力してください。<br />
                                    </p>
                                </div>
                                <div className='bg-primary bg-opacity-10 rounded-lg w-10/12 md:w-3/5 lg:w-2/5 px-4 md:px-8 py-6 md:py-16 mx-auto'>
                                    <form onSubmit={handleCancelSubmit}>
                                        <div className='flex flex-col gap-2 md:gap-5'>
                                            <label className="input input-bordered flex items-center gap-2">
                                                <IoPricetag className='text-xl' />
                                                <input
                                                    name='orderId'
                                                    type="text"
                                                    autoComplete='text'
                                                    maxLength={6}
                                                    className="grow"
                                                    placeholder="注文ID"
                                                    required
                                                    value={info.orderId || ''}
                                                    onChange={handleChange}
                                                    disabled={isLoading}
                                                />
                                            </label>
                                            {message.orderId && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.orderId}</p>}
                                            <label className="input input-bordered flex items-center gap-2">
                                                <IoChatbubbleEllipses className='text-lg' />
                                                <input
                                                    name='watchword'
                                                    type="text"
                                                    autoComplete='text'
                                                    maxLength={20}
                                                    className="grow"
                                                    placeholder="あいことば"
                                                    required
                                                    value={info.watchword || ''}
                                                    onChange={handleChange}
                                                    disabled={isLoading}
                                                />
                                            </label>
                                            {message.watchword && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.watchword}</p>}
                                            <select
                                                name="cancelReason"
                                                className="select select-bordered w-full max-w-xs"
                                                required
                                                onChange={handleChange}
                                                value={info.cancelReason || ''}
                                                disabled={isLoading}
                                            >
                                                <option value="" disabled>キャンセル理由</option>
                                                <option value={1}>注文が重複してしまった</option>
                                                <option value={2}>商品が不要になった</option>
                                                <option value={3}>誤って購入した</option>
                                                <option value={4}>支払いに問題があった</option>
                                                <option value={5}>レビュー・評価が悪い</option>
                                                <option value={6}>サイトの信頼性への不安</option>
                                                <option value={7}>その他</option>
                                            </select>
                                            {buttonDisabled &&
                                                <div className='btn btn-active btn-ghost cursor-not-allowed'>
                                                    送信
                                                </div>
                                            }
                                            {(!buttonDisabled && isLoading) &&
                                                <div className='btn btn-primary text-base md:text-lg w-full'>
                                                    <span className="loading loading-spinner loading-base text-primary-content" />
                                                </div>
                                            }
                                            {(!buttonDisabled && !isLoading) &&
                                                <button
                                                    type="submit"
                                                    className='btn btn-primary text-base md:text-lg w-full'
                                                >
                                                    送信
                                                </button>
                                            }
                                        </div>
                                    </form>
                                </div>
                                <div className='text-xs md:text-base md:w-3/4 lg:w-1/2 px-8 mt-14 md:mx-auto'>
                                    <p className='bg-base-300 text-center rounded p-3 md:p-6'>
                                        ※どうしても『注文ID』や『あいことば』が分からない場合は、<span className='hidden md:inline'><br /></span>
                                        大変お手数ではございますが<span className='hidden md:inline'><br /></span>
                                        <Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>の【ポータル画面URL】の発行をお試しください。
                                    </p>
                                </div>
                            </>
                        }
                        {cancelSucceeded &&
                            <div className='text-center pt-14 md:pt-14 md:pb-28 lg:py-14'>
                                <h2 className='text-2xl md:text-3xl font-bold'>プランがキャンセルされました</h2>
                                <p className='text-sm md:text-base px-4 mt-14'>
                                    プランが正常にキャンセルされました😌<br />
                                    ご利用いただき、誠にありがとうございました。<br />
                                    <br />
                                    以降、プランの自動更新は行われなくなり、<br />
                                    支払いが発生することはございません。<br />
                                    後ほどキャンセルの通知メールがお手元に届きます。<br />
                                    併せてご確認いただきますようお願いいたします☘️
                                </p>
                                {currentPeriodEndSrt &&
                                    <>
                                        <p className='text-sm md:text-base px-4 mt-10'>
                                            現在のプランは『<span className='font-bold'>キャンセル状態</span>』であり、<br />
                                            現在のプラン周期が終了すると<br />
                                            プランは自動的に『<span className='font-bold'>終了</span>』となります。<br />
                                            <br />
                                            それ以降は<Link to='/order/collation' className='text-info font-bold'>注文の詳細・状況確認ページ</Link>からも<br />
                                            プランの情報を確認できなくなります。<br />
                                        </p>
                                        <p className='bg-primary bg-opacity-20 border border-base-content rounded-lg text-sm md:text-base py-6 w-4/5 md:w-3/5 mx-auto mt-10'>
                                            現在のプラン周期の終了日時：<br />
                                            《<span className='text-lg font-bold'>{currentPeriodEndSrt}</span>》<br />
                                            <br />
                                            <span className='text-xs'>
                                            プランを再開させたい場合は、<br />
                                            現在のプラン周期の終了日時より前に<br />
                                            再開リクエストを行うことで、<br />
                                            キャンセルを取り消すことが可能です☺️🍀
                                            </span>
                                        </p>
                                    </>
                                }
                                <div className='flex flex-col items-center w-4/5 md:w-3/5 mx-auto mt-10'>
                                    {(currentPeriodEndSrt && !isLoading) &&
                                        <div
                                            onClick={handleImmediateResumeSubscription}
                                            className='btn btn-primary w-3/4 md:w-1/3 mb-4'>
                                            プランを再開する
                                        </div>
                                    }
                                    {(currentPeriodEndSrt && isLoading) &&
                                        <div className='btn btn-primary w-3/4 md:w-1/3 mb-4'>
                                            <span className="loading loading-spinner loading-base text-primary-content"></span>
                                        </div>
                                    }
                                    <Link to='/top' className='btn btn-secondary w-3/4 md:w-1/3'>
                                        トップページへ
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                    <Footer />
                </div>
            }
            {!accessRightGranted &&
                <div className='w-full h-full'>
                    <div className='flex items-center justify-center w-full h-screen'>
                        <p className='text-sm lg:text-base mr-1'>
                            🌟読み込み中です...
                        </p>
                        <span className="inline-block loading loading-ring loading-lg"></span>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default SubscriptionCancel