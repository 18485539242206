import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/layout/Footer'

function TermsOfUse() {
    return (
        <>
            <div className='w-full h-full'>
                <div className='h-full py-14'>
                    <div className='text-center py-14'>
                        <h2 className='text-base-content text-2xl font-bold'>利用規約</h2>
                    </div>
                    <div className='text-sm w-4/5 lg:w-3/4 mx-auto'>
                        <p>
                            この利用規約（以下、「本規約」といいます。）は、当ウェブサイト（以下、「本サービス」といいます。）の運営責任者者（以下、「当方」といいます。）が提供する、本サービスの利用条件を定めるものです。利用者の皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
                        </p>
                        <hr className='w-full my-5' />
                        <h3 className='text-base font-bold py-3'>
                            第1条（適用）
                        </h3>
                        <p>
                            1. 本規約は、ユーザーと当方との間の本サービスの利用に関わる一切の関係に適用されるものとします。<br />
                            2. 当方は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。<br />
                            3. 本規約の定めが前項の個別規定の定めと矛盾する場合には、個別規定において特段の定めがない限り、個別規定の定めが優先されるものとします。
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第2条（利用/購入登録）
                        </h3>
                        <p>
                            1. 本サービスにおいては、利用/購入を希望するユーザーが本規約に同意の上、当方の定める方法によって利用/購入登録を申請し、当方がこれに対する承認を当方が定める方法により利用/購入を希望したユーザーに通知することによって、利用/購入登録が完了するものとします。<br />
                            2. 利用/購入を希望するユーザーが未成年者である場合は、親権者など法定代理人の同意（本規約への同意を含みます）を得たうえで本サービスを利用しているものとみなします。<br />
                            3. 当方は、利用/購入登録の申請者に以下の事由があると判断した場合、利用/購入登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。<br />
                            <span className='block pl-4'>
                                ・利用/購入登録の申請に際して虚偽の事項を届け出た場合<br />
                                ・本規約に違反したことがある者からの申請である場合<br />
                                ・その他、当方が利用/購入登録を相当でないと判断した場合
                            </span>
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第3条（ユーザー情報および注文IDの管理）
                        </h3>
                        <p>
                            1. ユーザーは、自己の責任において、本サービス利用におけるユーザー情報および注文IDを管理するものとします。<br />
                            2. ユーザーは、いかなる場合にも、ユーザー情報および注文IDを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当方は、ユーザー情報と注文IDの組み合わせが登録情報と一致して本サービスが利用された場合には、そのユーザー情報を登録しているユーザー自身による利用とみなします。<br />
                            3. ユーザー情報及び注文IDが第三者に使用されたことによって生じた損害は、当方に故意又は重大な過失がある場合を除き、当方は一切の責任を負わないものとします。
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第4条（売買契約）
                        </h3>
                        <p>
                            1. 本サービスにおいては、ユーザーが当方に対して購入の申し込みをし、これに対して当方が定める方法により、当該申し込みを承諾した旨の通知をすることによって売買契約が成立するものとします。なお、当該商品の所有権は、当方が商品を引き渡したときに、ユーザーに移転するものとします。<br />
                            2. 当方は、ユーザーが以下のいずれかの事由に該当する場合には、当該ユーザーに事前に通知することなく、前項の売買契約を解除することができるものとします。<br />
                            <span className='block pl-4'>
                                ・ユーザーが本規約に違反した場合<br />
                                ・本サービスで提供される商品の支払いを1回でも怠った場合<br />
                                ・ユーザーが支払停止になった場合<br />
                                ・ユーザーが差押、仮差押、仮処分の申立、若しくは滞納処分を受けた場合、又は破産手続開始、民事再生手続開始の申立を受けた場合、若しくはこれらの申立を自らした場合<br />
                                ・ユーザーが反社会的勢力等に該当する、又は資金提供その他により反社会的勢力等の維持、運営に協力若しくは関与していると当社が判断する場合<br />
                                ・その他ユーザーによる本サービスの利用が不適切であると当社が判断する場合<br />
                                ・その他当方とユーザーの信頼関係が損なわれたと認める場合<br />
                            </span>
                            3. 本サービスに関する決済方法、受け渡し方法、購入の申し込みのキャンセル方法、または返品方法等については、別途当方が定める方法によります。
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第5条（知的財産権）
                        </h3>
                        本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は、当方及びコンテンツ提供者などの正当な権利者に帰属し、ユーザーは、これらを無断で複製、転載、改変、その他の二次利用をすることはできません。
                        <h3 className='text-base font-bold py-3'>
                            第6条（禁止事項）
                        </h3>
                        ユーザーは、本サービスの利用にあたり、以下の行為をしてはならないものとします。
                        <span className='block pl-4'>
                            ・法令または公序良俗に違反する行為<br />
                            ・犯罪行為に関連する行為<br />
                            ・本サービスに含まれる著作権、商標権その他の知的財産権を侵害する行為<br />
                            ・当方のサーバーまたはネットワークの機能を破壊しえたり、妨害したりする行為<br />
                            ・当方の事前の書面による承諾なしに、本サービスのいかなる部分であれワイヤーフレームやミラーサイトなどを作成する行為<br />
                            ・本サービスに対するハッキングやクラッキングなどの行為<br />
                            ・本サービスが提供するプログラムの全部または一部を書き換え、改変、または読み取りすることにより逆コンパイル、逆アセンブル、リバースエンジニアリングをする行為<br />
                            ・本サービスによって得られた情報を商業的に利用する行為<br />
                            ・当方のサービスの運営を妨害するおそれのある行為<br />
                            ・不正アクセスをし、またはこれを試みる行為<br />
                            ・他のユーザーに関する個人情報等を収集または蓄積する行為<br />
                            ・他のユーザーに成りすます行為<br />
                            ・当方のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為<br />
                            ・その他、当方が不適切と判断する行為<br />
                        </span>
                        <h3 className='text-base font-bold py-3'>
                            第7条（本サービスの提供の停止等）
                        </h3>
                        <p>
                            1. 当方は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br />
                            <span className='block pl-4'>
                                ・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br />
                                ・地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合<br />
                                ・コンピュータまたは通信回線等が事故により停止した場合<br />
                                ・その他、当方が本サービスの提供が困難と判断した場合<br />
                            </span>
                            2. 当方は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第8条（利用制限および登録抹消）
                        </h3>
                        <p>
                            1. 当方は、以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。<br />
                            <span className='block pl-4'>
                                ・本規約のいずれかの条項に違反した場合<br />
                                ・登録事項に虚偽の事実があることが判明した場合<br />
                                ・決済手段として当該ユーザーが届け出たクレジットカードが利用停止となった場合<br />
                                ・料金等の支払債務の不履行があった場合<br />
                                ・当方からの連絡に対し、一定期間返答がない場合<br />
                                ・本サービスについて、最終の利用から一定期間利用がない場合<br />
                                ・その他、当方が本サービスの利用を適当でないと判断した場合<br />
                            </span>
                            2. 当方は、本条に基づき当方が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第9条（登録解除/解約）
                        </h3>
                        ユーザーは、当方が用意した所定の手続方法により、本サービスにおける登録解除/解約の手続きを行えるものとします。
                        <h3 className='text-base font-bold py-3'>
                            第10条（保証の否認および免責事項）
                        </h3>
                        <p>
                            1. 当方は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを保証するものではありません。<br />
                            2. 当方は、本サービスによってユーザーに生じたあらゆる損害について、一切の責任を負いません。ただし、本サービスに関する当方とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されませんが、この場合であっても、当方は、当方の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当方またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。<br />
                            3. 当方は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                        </p>
                        <h3 className='text-base font-bold py-3'>
                            第11条（サービス内容の変更等）
                        </h3>
                        当方は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
                        <h3 className='text-base font-bold py-3'>
                            第12条（利用規約の変更）
                        </h3>
                        当方は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始または継続利用した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
                        <h3 className='text-base font-bold py-3'>
                            第13条（個人情報の取扱い）
                        </h3>
                        当方は、本サービスの利用によって取得する個人情報については、当方「<Link to='/privacy-policy' className='text-info'>プライバシーポリシー</Link>」に従い適切に取り扱うものとします。
                        <h3 className='text-base font-bold py-3'>
                            第14条（通知または連絡）
                        </h3>
                        ユーザーと当方との間の通知または連絡は、当方の定める方法によって行うものとします。当方は、ユーザーから、当方が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
                        <h3 className='text-base font-bold py-3'>
                            第15条（権利義務の譲渡の禁止）
                        </h3>
                        ユーザーは、当方の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                        <h3 className='text-base font-bold py-3'>
                            第16条（準拠法・裁判管轄）
                        </h3>
                        <p>
                            1. 本規約の解釈にあたっては、日本法を準拠法とします。なお、本サービスに関しては、国際物品売買契約に関する国際連合条約の適用を排除するものとします。<br />
                            2. 本サービスに関して紛争が生じた場合には、当方の所在地を管轄する裁判所を専属的合意管轄裁判所とします。<br />
                        </p>
                        <br />
                        <p className='w-full text-right'>以上</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default TermsOfUse