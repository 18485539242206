import { RiUserHeartLine, RiMailSendLine } from 'react-icons/ri'
import { TbCrystalBall } from "react-icons/tb"
import { FaRegCommentDots } from 'react-icons/fa'
import { HiOutlineBellAlert } from 'react-icons/hi2'

function OverallFlowOfDelivery() {
    return (
        <>
            <div className='pt-9'>
                <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
                    <li>
                        <div className="timeline-middle bg-primary rounded-full flex items-center justify-center h-7 w-7">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-primary"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg> */}
                            <RiUserHeartLine className="h-5 w-5 text-base-100" />
                        </div>
                        <div className="timeline-start md:text-end pt-1 pb-10">
                            {/* <time className="font-mono italic">1984</time> */}
                            <div className="text-lg font-black">1. 鑑定メニューの選択・お支払い</div>
                            <p className='text-sm md:text-base'>
                                お支払い(決済)画面にて、無事にご購入が完了しましたら、完了画面に記載された<span className='text-error font-bold'>《注文ID》と《あいことば》をお手元に控えていただき</span>次のフローに進みます☺️
                            </p>
                        </div>
                        <hr className='bg-base-content bg-opacity-20' />
                    </li>
                    <li>
                        <hr className='bg-base-content bg-opacity-20' />
                        <div className="timeline-middle bg-primary rounded-full flex items-center justify-center h-7 w-7">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg> */}
                            <RiMailSendLine className="h-4 w-4 text-base-100" />
                        </div>
                        <div className="timeline-end md:text-start pt-1 pb-10">
                            <div className="text-lg font-black">2. お悩みを記入・お申し込み</div>
                            <p className='text-sm md:text-base'>
                                お申し込み画面上のフォームより、お悩みの内容を記入して送信してくださいね♡✏️
                            </p>
                        </div>
                        <hr className='bg-base-content bg-opacity-20' />
                    </li>
                    <li>
                        <hr className='bg-base-content bg-opacity-20' />
                        <div className="timeline-middle bg-primary rounded-full flex items-center justify-center h-7 w-7">
                            <TbCrystalBall className="h-5 w-5 text-base-100" />
                        </div>
                        <div className="timeline-start md:text-end pt-1 pb-10">
                            <div className="text-lg font-black">3. LINEで鑑定スタート!!</div>
                            <p className='text-sm md:text-base'>
                                LINE公式アカウントより<span className='font-bold'>《鑑定スタート》</span>の操作をお願いいたします。
                                その際に先ほどコピーした<span className='text-error font-bold'>《注文ID》と《あいことば》を必ず送信</span>してください☘️
                            </p>
                        </div>
                        <hr className='bg-base-content bg-opacity-20' />
                    </li>
                    <li>
                        <hr className='bg-base-content bg-opacity-20' />
                        <div className="timeline-middle bg-primary rounded-full flex items-center justify-center h-7 w-7">
                            <HiOutlineBellAlert className="h-5 w-5 text-base-100" />
                        </div>
                        <div className="timeline-end pt-1 pb-10">
                            <div className="text-lg font-black">4. LINEに鑑定結果が届く♪</div>
                            <p className='text-sm md:text-base'>
                                通常、<span className='font-bold'>1〜5営業日以内</span>に、鑑定結果がLINEのトークに届きます。
                                お待たせしてしまうこともあるかもしれませんが、どうか楽しみにお待ちいただけますと幸いです🌟
                            </p>
                        </div>
                        <hr className='bg-base-content bg-opacity-20' />
                    </li>
                    <li>
                        <hr className='bg-base-content bg-opacity-20' />
                        <div className="timeline-middle bg-primary rounded-full flex items-center justify-center h-7 w-7">
                            <FaRegCommentDots className="h-4 w-4 text-base-100" />
                        </div>
                        <div className="timeline-start md:text-end pt-1 pb-10">
                            <div className="text-lg font-black pl-4">5. LINEで気軽に質問・相談◎</div>
                            <p className='text-sm md:text-base'>
                                鑑定結果に対する質問や相談は、LINEトークで承っております。
                                どうぞお気軽にご連絡くださいね☺️♡<br />
                                <br />
                                <span className='text-xs'>※鑑定ごとに「質問できる回数」が設けられております。お申し込みの際にご確認ください。</span>
                                <br />
                                <span className='text-xs'>※【-Oneタロット鑑定-】【カラー鑑定】【思念伝達鑑定】では、質問を受け付けておりません。予めご了承くださいませ。</span>
                            </p>
                        </div>
                        <hr className='bg-base-content bg-opacity-20' />
                    </li>
                </ul>
            </div>
        </>
    )
}

export default OverallFlowOfDelivery