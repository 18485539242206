import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { themeNameContext } from '../utils/contexts/ThemeName'
import ContactForm from '../components/common/form/ContactForm'
import Footer from '../components/layout/Footer'

function Contact() {

    const { themeName } = useContext(themeNameContext)

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className='w-full h-full'>
                <div className='h-full py-14'>
                    <div className='text-center pt-14'>
                        <h2 className='text-base-content text-2xl md:text-3xl font-bold mb-4'>~ お問合せフォーム ~</h2>
                        <p className='text-xs leading-5 w-10/12 mx-auto'>
                            下記のフォームに必要事項をご記入の上、<br />
                            送信ボタンをクリックしてください。<br />
                            お問合せ内容によっては、お返事までにお時間をいただく場合がございます。予めご了承ください😌
                        </p>
                    </div>
                    <div className='bg-error bg-opacity-50 text-base-content rounded-lg w-10/12 md:w-3/5 lg:w-2/5 mx-auto my-5'>
                        <p className='text-xs leading-5 p-2'>
                            ⚠️<span className='font-bold tracking-wide'>当フォームを利用した売り込みの一切をお断りしています。</span>
                            当フォームは、当サイトを利用されるお客様からのお問い合わせを目的として設置しております。<br />
                            万が一、営業メールをいただいても返信はいたしません。ご了承ください。
                        </p>
                    </div>
                    <div className='bg-primary bg-opacity-10 rounded-lg w-10/12 md:w-3/5 lg:w-2/5 px-4 md:px-8 py-6 md:py-16 mx-auto'>
                        <ContactForm
                            themeName={themeName}
                        />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Contact