import { useContext, useState } from 'react'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import { themeNameContext } from '../utils/contexts/ThemeName'
import { accessTokenContext } from '../utils/contexts/AccessToken'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaUser } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'
import OrderCollationModal from '../components/modals/OrderCollationModal'
import OrderInfoModal from '../components/modals/OrderInfoModal'
import PortalPageLinkSendModal from '../components/modals/PortalPageLinkSendModal'
import Footer from '../components/layout/Footer'

function OrderCollation() {

    // バリデーションルールを定義
    const schema = yup.object({
        email: yup.string().required('メールアドレスは必須です').email('正しいメールアドレスを入力してください'),
        name: yup.string().required('名前は必須です').min(2, '名前は2文字以上で入力してください').max(20, '名前は20文字以内で入力してください')
    }).required();

    const authAxios = UseAuthAxios()
    const { setAccessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)
    const [isLoading, setIsLoading] = useState(false)
    const [orderId, setOrderId] = useState('')
    const [watchword, setWatchword] = useState('')
    const [purchasedDatetime, setPurchasedDatetime] = useState('')
    const [divinationStatus, setDivinationStatus] = useState('Unknown')
    const [divinationTitle, setDivinationTitle] = useState('Unknown')

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const resetAll = () => {
        reset() //clear all inputs of react-hook-form
        setIsLoading(false)
    }

    const showOrderCollationModal = () => {
        document.getElementById('order_collation_modal').showModal()
    }

    const showOrderInfoModal = () => {
        document.getElementById('order_info_modal').showModal()
    }

    const showPortalPageLinkSendModal = () => {
        document.getElementById('portal_page_link_send_modal').showModal()
    }

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const onSubmit = (data) => {
        toggleLoading()  // ローディング開始
        const EnterUserAuthorizationForm = async () => {
            try {
                const authResponse = await authAxios.post('/order/collation/auth', data)
                if (authResponse.status === 200) {
                    setAccessToken(authResponse?.data.access_token)
                    toggleLoading()
                    showOrderCollationModal()
                }
            } catch (error) {
                if (error?.response?.status === 400) {
                    toast.error('無効なリクエストです。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    return
                }
                if (error?.response?.status === 404 && error?.response?.data?.detail === 'User not found.') {
                    toast.warning('メールアドレスまたは\nお名前が正しくないようです。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    resetAll()
                    return
                }
                if (error?.response?.status === 429) {
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    resetAll()
                    return
                }
            }
        }
        EnterUserAuthorizationForm()
    }

    return (
        <>
            <div className='w-full h-full'>
                <div className='my-14 md:my-28 lg:my-14'>
                    <div className='text-center py-14 md:pt-14 md:pb-28 lg:py-14'>
                        <h2 className='text-2xl md:text-3xl font-bold'>~ ご注文の詳細・状況確認 ~</h2>
                        <p className='text-sm md:text-base px-4 mt-5'>
                            以下のフォームにて、ご登録された<br />
                            『メールアドレス』と『お名前』を入力することで、<br />
                            ご注文の詳細や状況を安全に確認いただけます。
                        </p>
                    </div>
                    <div className='bg-primary bg-opacity-10 rounded-lg w-10/12 md:w-3/5 lg:w-2/5 px-4 md:px-8 py-6 md:py-16 mx-auto'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-col gap-2 w-full'>
                                <label className="input input-bordered flex items-center gap-2">
                                    <MdMail className='text-xl' />
                                    <input
                                        type="email"
                                        {...register('email')}
                                        autoComplete='email'
                                        className="grow"
                                        placeholder="メールアドレス"
                                        disabled={isLoading}
                                    />
                                </label>
                                {errors.email && <p className='text-error text-xs'>{errors.email.message}</p>}
                                <label className="input input-bordered flex items-center gap-2">
                                    <FaUser className='text-lg' />
                                    <input
                                        type="text"
                                        maxLength={20}
                                        {...register('name')}
                                        className="grow"
                                        placeholder="お名前(ニックネームなども可)"
                                        disabled={isLoading}
                                    />
                                </label>
                                {errors.name && <p className='text-error text-xs'>{errors.name.message}</p>}
                                {(isLoading) &&
                                    <div className='btn btn-primary shadow-base-normal cursor-not-allowed text-base md:text-lg w-full mt-2'>
                                        <span className="loading loading-spinner loading-base text-primary-content"></span>
                                    </div>
                                }
                                {(!isLoading) &&
                                    <button
                                        type="submit"
                                        className='btn btn-primary shadow-base-normal cursor-pointer text-base md:text-lg w-full mt-2'
                                    >完了</button>
                                }
                            </div>
                        </form>
                        <div className="w-full mt-4">
                            {!isLoading ?
                                <div
                                    className="btn btn-neutral shadow-base-normal text-base cursor-pointer md:text-lg w-full"
                                    onClick={() => resetAll()}
                                >
                                    クリア
                                </div> :
                                <div className="btn btn-neutral shadow-base-normal text-base cursor-not-allowed md:text-lg w-full">クリア</div>
                            }
                        </div>
                    </div>
                    <div className='bg-base-200 rounded-lg w-10/12 mt-14 md:w-3/5 lg:w-2/5 px-4 md:px-8 py-6 md:py-10 mx-auto'>
                        <h3 className='text-center text-sm md:text-base font-bold'>「注文ID」や「あいことば」<br />を忘れてしまいましたか？</h3>
                        <div className='bg-base-100 flex flex-col items-center rounded-lg p-4 mt-4 md:mt-10'>
                            <p className='text-center text-sm'>
                                ご安心ください☺️<br />
                                お客様専用の【ポータル画面URL】を発行することが可能です。<br />
                                以下のボタンをクリックすると、<span className='inline md:hidden'><br /></span>
                                ご登録のメールアドレス宛に<br />
                                URLが記載されたメール届きますので、そちらからご確認ください。🌟<br />
                                <br />
                                ↓↓↓
                            </p>
                            <div
                                className='btn btn-secondary  w-3/5 md:w-1/2 md:text-lg mt-4 mb-2'
                                onClick={() => showPortalPageLinkSendModal()}
                            >
                                URLを発行する
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <OrderCollationModal
                setOrderId={setOrderId}
                setWatchword={setWatchword}
                setPurchasedDatetime={setPurchasedDatetime}
                setDivinationStatus={setDivinationStatus}
                setDivinationTitle={setDivinationTitle}
                showOrderInfoModal={showOrderInfoModal}
                resetAll={resetAll}
            />
            <OrderInfoModal
                title={divinationTitle}
                orderId={orderId}
                watchword={watchword}
                statusStr={divinationStatus}
                purchasedDatetime={purchasedDatetime}
                resetAll={resetAll}
            />
            <PortalPageLinkSendModal
                resetAll={resetAll}
            />
        </>
    )
}

export default OrderCollation