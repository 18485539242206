import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import { accessTokenContext } from '../utils/contexts/AccessToken'
import { themeNameContext } from '../utils/contexts/ThemeName'
import { toast } from 'react-toastify'
import PortalSection from '../components/common/portal/PortalSection'
import PortalOrderCancelModal from '../components/common/portal/PortalOrderCancelModal'
import PortalWatchwordChangeModal from '../components/common/portal/PortalWatchwordChangeModal'
import Footer from '../components/layout/Footer'

function UserPortal() {

    const authAxios = UseAuthAxios()
    const location = useLocation()
    const navigate = useNavigate()
    const { setAccessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)
    const [accessAllowed, setAccessAllowed] = useState(false)
    const [orderData, setOrderData] = useState()

    const [toCancelOrderId, setToCancelOrderId] = useState('')
    const [alreadyCanceledOrderIds, setAlreadyCanceledOrderIds] = useState([])
    const [toChangeWatchwordOrderId, setToChangeWatchwordOrderId] = useState('')

    useEffect(() => {
        // * クエリパラメータが有効かどうかを確認する処理
        const queryParams = new URLSearchParams(location.search)
        const paramToken = queryParams.get('token')
        if (!paramToken || paramToken === '') {
            navigate('/404')
        } else {
            setAccessToken(paramToken)
        }
        const requestUrl = (`/order/portal-view?token=${paramToken}`)
        const requestPortalView = async () => {
            try {
                const config = { headers: { Authorization: `Bearer ${paramToken}` } }
                const portalViewResponse = await authAxios.get(requestUrl, config, { withCredentials: true })
                if (portalViewResponse.status === 200) {
                    setAccessAllowed(true)
                    setOrderData(portalViewResponse?.data)
                }
            } catch (error) {
                if ((error?.response?.status === 400 || error?.response?.status === 404) ||
                    (error?.response?.status === 401 && error?.response?.data.detail === 'Invalid Credentials')) {
                    toast.error('無効なリクエストです。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    setAccessToken('')
                    navigate('/top')
                } else if (error?.response?.status === 401 && error?.response?.data.detail === 'Credentials have expired') {
                    toast.error('リンクの有効期限が切れています。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2500,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    setAccessToken('')
                    navigate('/top')
                }
            }
        }
        if (location.pathname === '/order/portal-view') {
            requestPortalView()
        }
        // eslint-disable-next-line
    }, [])

    const showPortalOrderCancelModal = (orderId) => {
        setToCancelOrderId(orderId)
        document.getElementById('portal_order_cancel_modal').showModal()
    }

    const showPortalWatchwordChangeModal = (orderId) => {
        setToChangeWatchwordOrderId(orderId)
        document.getElementById('portal_watchword_change_form_modal').showModal()
    }

    return (
        <>
            {accessAllowed &&
                <div className='w-full h-full'>
                    <div className='my-14 md:my-28 lg:mt-14 lg:mb-28'>
                        <div className='text-center pt-14 md:pt-14 md:pb-14 lg:py-14'>
                            <h2 className='text-2xl md:text-3xl font-bold'>~ 注文一覧 ~</h2>
                            <p className='text-sm md:text-base px-4 mt-14 mb-5'>
                                このページは<span className='font-bold'>お客様専用のポータルページ</span>です🌱<br />
                                お客様が注文情報を確認・変更することができます。<br />
                                注文の管理にお役立てください☺️<br />
                            </p>
                            <p className='border border-base-content rounded-lg text-2xs p-2 max-w-max mx-auto'>
                                ※【鑑定結果のお届けが完了している注文】や、<span className='inline md:hidden'><br /></span>【すでにキャンセルされた注文】は一覧に表示されません。<br />
                                ※『ご購入後30日以内』かつ『ご相談内容の送信前』のご注文は<span className='inline md:hidden'><br /></span>このページからキャンセルが可能です。<br />
                                ※このページからは毎月1回お悩み鑑定プランのキャンセルはできません。<br />
                                ※一定時間が経過すると、このページは閲覧できなくなります。
                            </p>
                        </div>
                        {(orderData?.subscription && orderData?.subscription.length !== 0) &&
                            <PortalSection
                                sectionTitle='【⭐️毎月1回お悩み鑑定⭐️】'
                                dataList={orderData?.subscription}
                                showPortalOrderCancelModal={showPortalOrderCancelModal}
                                alreadyCanceledOrderIds={alreadyCanceledOrderIds}
                                showPortalWatchwordChangeModal={showPortalWatchwordChangeModal}
                            />
                        }
                        {(orderData?.normal && orderData?.normal.length !== 0) &&
                            <PortalSection
                                sectionTitle='【🍀通常お悩み鑑定🍀】'
                                dataList={orderData?.normal}
                                showPortalOrderCancelModal={showPortalOrderCancelModal}
                                alreadyCanceledOrderIds={alreadyCanceledOrderIds}
                                showPortalWatchwordChangeModal={showPortalWatchwordChangeModal}
                            />
                        }
                        {(orderData?.one_tarot && orderData?.one_tarot.length !== 0) &&
                            <PortalSection
                                sectionTitle='【-Oneタロット鑑定-】'
                                dataList={orderData?.one_tarot}
                                showPortalOrderCancelModal={showPortalOrderCancelModal}
                                alreadyCanceledOrderIds={alreadyCanceledOrderIds}
                                showPortalWatchwordChangeModal={showPortalWatchwordChangeModal}
                            />
                        }
                        {(orderData?.color && orderData?.color.length !== 0) &&
                            <PortalSection
                                sectionTitle='【🎨カラー鑑定🎨】'
                                dataList={orderData?.color}
                                showPortalOrderCancelModal={showPortalOrderCancelModal}
                                alreadyCanceledOrderIds={alreadyCanceledOrderIds}
                                showPortalWatchwordChangeModal={showPortalWatchwordChangeModal}
                            />
                        }
                        {(orderData?.thought && orderData?.thought.length !== 0) &&
                            <PortalSection
                                sectionTitle='【💐思念伝達鑑定💐】'
                                dataList={orderData?.thought}
                                showPortalOrderCancelModal={showPortalOrderCancelModal}
                                alreadyCanceledOrderIds={alreadyCanceledOrderIds}
                                showPortalWatchwordChangeModal={showPortalWatchwordChangeModal}
                            />
                        }
                    </div>
                    <Footer />
                    <PortalOrderCancelModal
                        toCancelOrderId={toCancelOrderId}
                        setToCancelOrderId={setToCancelOrderId}
                        setAlreadyCanceledOrderIds={setAlreadyCanceledOrderIds}
                    />
                    <PortalWatchwordChangeModal
                        toChangeWatchwordOrderId={toChangeWatchwordOrderId}
                    />
                </div>
            }
            {!accessAllowed &&
                <div className='w-full h-full'>
                    <div className='flex items-center justify-center w-full h-screen'>
                        <p className='text-sm lg:text-base mr-1'>
                            🔑読み込み中です...
                        </p>
                        <span className="inline-block loading loading-ring loading-lg"></span>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default UserPortal