import React from 'react'
import Footer from '../../layout/Footer'
import RinIconImage from '../../../assets/images/rin_circle_icon.png'
import Product from '../../layout/Product'
import HeaderImage from '../../../assets/images/one_tarot.jpg'
import EffortToPrivacy from '../EffortToPrivacy'
import OverallFlowOfDelivery from '../../layout/OverallFlowOfDelivery'
import ReviewItem from '../ReviewItem'
import ThumbnailImageForFirstTarot from '../../../assets/images/normal_divination.jpg'
import ThumbnailImageForSubscription from '../../../assets/images/subscription_divination.jpg'
import ThumbnailImageForColor from '../../../assets/images/color_divination.jpg'
// import ThumbnailImageForThoughtTransmission from '../../../assets/images/candle_and_flower.jpg'

function OneTarotContents({ imageHeight, buttonPosition, showPurchaseConfirmModal }) {

    const showFirstModal = () => {
        showPurchaseConfirmModal()
    }

    return (
        <>
            <div className='h-full md:w-4/5 lg:w-3/4 my-14 md:mx-auto'>
                <div
                    className='header-image bg-neutral bg-cover'
                    style={{
                        backgroundImage: `url(${HeaderImage})`,
                        backgroundPosition: 'center top 50%',
                        height: imageHeight
                    }}
                />
                <div className='title-container'>
                    <h2 className='fortune-menu-title text-2xl font-bold md:text-xl p-4'>
                        【-Oneタロット鑑定-】1枚のカードからメッセージや意味、ヒントを読み取りお伝えします💗
                    </h2>
                </div>
                <hr className='border-base-content border-opacity-30 mx-8' />
                <div className='flex items-center gap-4 icon-section p-4 pt-8'>
                    <img src={RinIconImage} alt="" className='rounded-full shadow-base-normal w-24 h-24' />
                    <p className='font-bold'>占い師 ~ 凛 ~</p>
                </div>
                <div className='means-section p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 🔮 使用する占術 》
                    </h2>
                    <ul className='pt-3'>
                        <li>・タロット</li>
                    </ul>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 ✏️ 鑑定メニュー詳細 》
                    </h2>
                    <h3 className='font-bold pt-3'>◆ 概要</h3>
                    <p className='px-2'>
                        Instagramで定期的に行なっている『<span className='italic border-b border-base-content'>無料鑑定</span>』の有料版です。<br />
                        <br />
                        お悩みに対して、1枚のカードからメッセージや意味、ヒントを読み取りお伝えします。<br />
                        <br />
                        お悩みの内容は問いません。<br />
                        どんな内容でもOKです♡<br />
                        お気軽にご相談くださいね🍀^^<br />
                    </p>
                    <h3 className='font-bold pt-9'>◆ ご相談例</h3>
                    <ul className='px-2 text-sm md:text-base'>
                        <li>・「いま、彼に思いを伝えても大丈夫ですか？」</li>
                        <li>・「別れた彼のことが忘れらない…。どうするべき？」</li>
                        <li>・「今カレが何を考えているのか知りたいです」</li>
                        <li>・「出会いがありません。この先、好きな人は現れますか？」</li>
                        <li>・「ケンカが絶えない夫と、昔のように仲良くしたい」</li>
                    </ul>
                    <h3 className='font-bold pt-9'>◆ 占い方法</h3>
                    <p className='px-2'>
                        あなたのために "<span className='font-bold'>1枚のタロットカード</span>" を引いて、そこから受け取ったカードの意味やメッセージ、今後の過ごし方や考え方をお伝えします♡^^<br />
                    </p>
                    <h3 className='font-bold pt-9'>◆ ココがおすすめ！🔥</h3>
                    <p className='px-2'>
                        ・無料鑑定より"踏み込んだ内容"もOK！<br />
                        ・無料鑑定のような抽選はナシ！<br />
                        ・無料鑑定より多い文章ボリューム！<br />
                        （目安：約1.5〜2倍程度）<br />
                    </p>
                    <h3 className='font-bold pt-9'>◆ ご質問について</h3>
                    <p className='px-2'>
                        こちらの鑑定では、鑑定結果に対する「質問」は受け付けておりません。<br />
                        予めご了承ください。<br />
                    </p>
                    <h3 className='font-bold pt-9'>◆ 料金</h3>
                    <p className='px-2'>
                        ¥550 / 鑑定(税込)
                    </p>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 🍀 凛(Rin)から伝えたいこと 》
                    </h2>
                    <h3 className='font-bold pt-9'>◆ 鑑定における必要項目</h3>
                    <p className='px-2'>＜必須＞</p>
                    <ul className='px-2'>
                        <li>・ご相談内容（詳しい情報をお願いします）</li>
                    </ul>
                    <h3 className='font-bold pt-9'>◆ 注意事項</h3>
                    <p className='px-2'>1. 以下に該当するときは、鑑定をお断りさせていただく場合があります。</p>
                    <ul className='px-2'>
                        <li>・占い師を挑発、侮辱、または試しているとみなせる場合</li>
                        <li>・他者を傷つけるような相談内容の場合</li>
                        <li>・ギャンブルや投資の結果、試験や面接の合否、適職や就職先の選定、人の健康や病気、犯罪や人の生死に関わるような相談内容の場合</li>
                        <li>・その他、不適切と判断される場合</li>
                    </ul>
                    <p className='px-2'>2. 占術の指定はできません。予めご了承くださいませ。</p>
                    <EffortToPrivacy />
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 📲 鑑定お届けまでの流れ 》
                    </h2>
                    <OverallFlowOfDelivery />
                </div>
                <div className='review-section mt-10 p-4'>
                    <h2 className='review-title text-xl font-bold mb-2 md:text-xl'>
                        《 ⭐️ 評価・レビュー 》
                    </h2>
                    <div className='flex flex-col gap-4 pt-5 pb-10'>
                        <ReviewItem
                            gender='女性'
                            age='30代'
                            reviewText={<span>インスタの無料鑑定の当選倍率が高く…、どうしても凛さんに鑑定してもらいたくて、こちらを購入しました。<br />いつも1枚のカードからのメッセージが、とても的確で、今後の過ごし方や考え方が明確になります。無料鑑定よりも文章も多めで、深い内容を感じました。こちらでは必ず鑑定をいただけるので、安心できますね。</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>いつもありがとうございます。凛さんの鑑定は、いつもズバリ当たっていて、驚きます。今回もカードからのメッセージが、私の悩みに対してズバリ！でした。参考にさせていただきます！また悩んだ際は、ぜひ相談させていただきます。ありがとうございました！</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='20代'
                            reviewText={<span>無料鑑定では当選するとアーカイブで悩みが見えてしまうため、いつもためらってしまうのですが、そういったことも無く安心できるので、購入させていただきました。<br />ずっと鑑定してもらいたかったので、嬉しかったです！またお願いしたいです、ありがとうございました！</span>}
                        />
                        <ReviewItem
                            gender='女性'
                            age='40代'
                            reviewText={<span>こちらのメニューは他のメニューに比べて安いし、ぜったいに鑑定してもらえるっていうのが購入のポイントでした。<br />鑑定の文章はそこまで長くないですが、納得のいく内容でした。さらに詳しく鑑定をしてもらいたいと思いましたので、次回は別の鑑定も購入してみようと思います。</span>}
                        />
                    </div>
                </div>
                <div className='cta-section max-h-max w-full'>
                    <div className={`flex justify-center w-full py-6 ${buttonPosition === 'fixed' ? 'button-fixed-bottom' : ''}`}>
                        <button
                            className={`btn btn-accent text-xl shadow-base-normal w-[80vw] lg:w-[75vw] h-16`}
                            onClick={() => showFirstModal()}
                        >
                            購入する
                        </button>
                    </div>
                </div>
                <div className='detail-section mt-10 p-4'>
                    <h2 className='fortune-menu-title text-xl font-bold md:text-xl'>
                        《 📚 他の鑑定メニュー 》
                    </h2>
                    <div className='grid grid-cols-2 gap-4 md:gap-8 lg:gap-12 pt-9'>
                        <Product
                            productNum={2}
                            img={ThumbnailImageForFirstTarot}
                            title={'【🍀通常お悩み鑑定🍀】\nどんな悩みでもOKです♡恋愛・お仕事・お金・人間関係など'}
                            desc={'※お悩みの内容は問いません'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        />
                        <Product
                            productNum={3}
                            img={ThumbnailImageForSubscription}
                            title={'【⭐️毎月1回お悩み鑑定⭐️】\n通常価格より"おトク"に！月に1回までの鑑定が可能です♡'}
                            desc={'※【🍀通常お悩み鑑定🍀】のサブスクリプション版です'}
                            price={'¥3,300(税込)'}
                            type={'月々払い'}
                        />
                        <Product
                            productNum={4}
                            img={ThumbnailImageForColor}
                            title={'【🎨カラー鑑定🎨】\nあなたの特性や深層心理、将来へのインスピレーションを読み解きます♡'}
                            desc={'※購入された"ご本人様"が対象です'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        />
                        {/* <Product
                            productNum={5}
                            img={ThumbnailImageForThoughtTransmission}
                            title={'【💐思念伝達鑑定💐】\nあなたの"想い"をお相手様の潜在意識に届けます♡恋愛/復縁/片想い'}
                            desc={'※購入された"ご本人様"が対象です'}
                            price={'¥4,400(税込)'}
                            type={'1回限り'}
                        /> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default OneTarotContents