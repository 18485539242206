import React from 'react'

function ApplicationConfirmModal({ info, handleSubmit }) {

    const submitFromModal = () => {
        handleSubmit()
    }

    return (
        <>
            <dialog id="application_confirm_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content max-h-[80vh]">
                    <h3 className="font-bold text-xl text-center">
                        以下の内容で<br /> 申し込みを確定しますか？
                    </h3>
                    <p className='text-error text-sm font-bold mt-5'>お申し込み後の内容変更はできません。</p>
                    <hr className='border-base-content border-opacity-30 my-6 w-11/12 mx-auto' />
                    <dl className='flex flex-col gap-2 overflow-y-scroll'>
                        {info.name &&
                            <div className='flex'>
                                <dt className='font-bold'>あなたを表すお名前：</dt>
                                <dd className='ml-2'>{info.name}</dd>
                            </div>
                        }
                        {info.birthDate &&
                            <div className='flex'>
                                <dt className='font-bold'>あなたの生年月日：</dt>
                                <dd className='ml-2'>{info.birthDate}</dd>
                            </div>
                        }
                        {info.bloodType &&
                            <div className='flex'>
                                <dt className='font-bold'>血液型：</dt>
                                <dd className='ml-2'>{info.bloodType}</dd>
                            </div>
                        }
                        {info.gender &&
                            <div className='flex'>
                                <dt className='font-bold'>性別：</dt>
                                <dd className='ml-2'>{info.gender}</dd>
                            </div>
                        }
                        {(info.textBody || info.mainText) &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>お悩み内容：</dt>
                                <dd className='px-4'>{info.textBody || info.mainText}</dd>
                            </div>
                        }
                        {info.messageToTheOtherParty &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>相手へのメッセージ：</dt>
                                <dd className='px-4'>{info.messageToTheOtherParty}</dd>
                            </div>
                        }
                        {info.selectedColor &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>選択した色：</dt>
                                <dd className='px-4'>{info.selectedColor}</dd>
                            </div>
                        }
                        {info.selectionReason &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>選択した理由：</dt>
                                <dd className='px-4'>{info.selectionReason}</dd>
                            </div>
                        }
                        {info.incorporationIntoDailyLife &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>日常への取り入れ：</dt>
                                <dd className='px-4'>{info.incorporationIntoDailyLife}</dd>
                            </div>
                        }
                        {info.relevanceWithColor &&
                            <div className='flex flex-col'>
                                <dt className='font-bold'>自分と色の関連性：</dt>
                                <dd className='px-4'>{info.relevanceWithColor}</dd>
                            </div>
                        }
                    </dl>
                    <div className='w-full mt-6'>
                        <form method="dialog">
                            <div className='flex justify-evenly'>
                                <button
                                    className="btn btn-primary text-base md:text-lg w-2/5"
                                    onClick={submitFromModal}
                                >
                                    はい
                                </button>
                                <button
                                    className="btn btn-neutral text-base md:text-lg w-2/5"
                                >
                                    戻る
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </>
    )
}

export default ApplicationConfirmModal