import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { IoIosMenu, IoIosClose } from 'react-icons/io'

const Header = React.forwardRef((props, ref) => {

    const location = useLocation()
    const { themeName, setThemeName } = useContext(themeNameContext)
    const [isPaymentPage, setIsPaymentPage] = useState(false)
    const [isAddingNewCardPage, setIsAddingNewCardPage] = useState(false)
    const drawerOpen = props.drawerOpen
    const toggleDrawer = props.toggleDrawer

    useEffect(() => {
        // 「/order/payment」ページの場合はヘッダーを非表示にする
        if ((location.pathname === '/order/payment') ||
            (location.pathname === '/order/payment/') ||
            (location.pathname === '/subscription/resume') ||
            (location.pathname === '/subscription/resume/')) {
            setIsPaymentPage(true)
            setIsAddingNewCardPage(true)
        } else {
            setIsPaymentPage(false)
            setIsAddingNewCardPage(false)
        }
        // eslint-disable-next-line
    }, [location.pathname])

    const toggleTheme = (e) => {
        const isNight = e.target.checked
        const newTheme = isNight ? 'night' : 'cupcake'
        setThemeName(newTheme)
    }

    return (
        <>
            {(!isPaymentPage || !isAddingNewCardPage) &&
                <header className='fixed z-[100] bg-base-200 shadow-base-normal flex items-center w-full min-h-14' ref={ref}>
                    <div className="w-full flex items-center justify-between">
                        <div className='pl-2'>
                            <label htmlFor="my-custom-drawer" className="drawer-button cursor-pointer" onClick={() => toggleDrawer()}>
                                {drawerOpen ? <IoIosClose size={35} /> : <IoIosMenu size={30} />}
                            </label>
                        </div>
                        <div className='pr-2'>
                            <div className="flex justify-end items-center">
                                <label className="flex cursor-pointer gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="5"/><path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"/></svg>
                                    <input
                                        id='toggle-theme-button'
                                        type="checkbox"
                                        checked={themeName === 'night'}
                                        value="night"
                                        className="toggle"
                                        onChange={toggleTheme}
                                    />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
                                </label>
                            </div>
                        </div>
                    </div>
                </header>
            }
        </>
    )
})

export default Header