import React from 'react'

function PortalSubscriptionSection({ sectionTitle, dataList, showPortalOrderCancelModal, alreadyCanceledOrderIds, showPortalWatchwordChangeModal }) {
    return (
        <>
            <div className='w-full px-4 mt-14 md:p-0 md:w-3/4 lg:w-3/5 md:mx-auto'>
                <h3 className='bg-base-300 text-center rounded py-1'>{sectionTitle}</h3>
                {dataList.map((data, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className='flex justify-between py-4'>
                                <div className='flex flex-col gap-2 md:flex-row max-w-max'>
                                    <p className='text-2xs md:text-sm lg:text-base md:max-w-max px-1'>注文ID:<span className='text-sm md:text-lg font-bold'>{data.order_id}</span></p>
                                    <div className='flex flex-col gap-2'>
                                        <p className='text-2xs md:text-sm lg:text-base px-1'>購入日時:<span className='text-sm md:text-lg tracking-tighter font-bold'>{data.purchase_date}</span></p>
                                        <p className='text-2xs md:text-sm lg:text-base px-1'>
                                            ステータス
                                            <span className={`text-sm md:text-lg font-bold ${data.status === '申し込み可能' ? 'text-info' : data.status === '受付済み' ? 'text-secondary' : data.status === '鑑定中' ? 'text-warning' : data.status === '一時停止中' ? 'text-error' : data.status === 'キャンセル済み' ? 'text-error' : 'text-success'}`}>
                                                {data.status}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className='flex flex-col md:gap-2 justify-evenly'>
                                    {((data.status === '申し込み可能' || data.status === '受付済み' || data.status === '鑑定中') && !alreadyCanceledOrderIds.includes(data.order_id)) &&
                                        <div
                                            onClick={() => showPortalWatchwordChangeModal(data.order_id)}
                                            className={`btn btn-sm text-2xs lg:text-xs btn-primary`}
                                        >あいことばを変更する</div>
                                    }
                                    {(data?.cancelable === true && !alreadyCanceledOrderIds.includes(data.order_id)) &&
                                        <div
                                            onClick={() => showPortalOrderCancelModal(data.order_id)}
                                            className={`btn btn-sm text-2xs lg:text-xs bg-neutral-content text-neutral`}
                                        >注文をキャンセルする</div>
                                    }
                                </div>
                            </div>
                            <hr className='border-base-content border-opacity-30' />
                        </React.Fragment>
                )})}
            </div>
        </>
    )
}

export default PortalSubscriptionSection