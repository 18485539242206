import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import { accessTokenContext } from '../utils/contexts/AccessToken'
import { themeNameContext } from '../utils/contexts/ThemeName'
import { toast } from 'react-toastify'
import OneTarotApplicationForm from '../components/common/form/OneTarotApplicationForm'
import NormalDivinationApplicationForm from '../components/common/form/NormalDivinationApplicationForm'
import ColorDivinationApplicationForm from '../components/common/form/ColorDivinationApplicationForm'
import ThoughtTransmissionApplicationForm from '../components/common/form/ThoughtTransmissionApplicationForm'
import Footer from '../components/layout/Footer'

function OrderApplication() {

    const authAxios = UseAuthAxios()
    const location = useLocation()
    const navigate = useNavigate()
    const { accessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext) // 子コンポーネント内のtoastのテーマを設定するために使用
    const [accessAllowed, setAccessAllowed] = useState(false)
    const [targetDivinationKey, setTargetDivinationKey] = useState(undefined) // フォームを切り替えるために使用（レンダリング時にエンドポイントから取得）

    useEffect(() => {
        // * クエリパラメータが有効かどうかを確認する処理
        const queryParams = new URLSearchParams(location.search)
        const paramOrderId = queryParams.get('order_id')
        const paramWatchword = queryParams.get('watchword')
        if (!paramOrderId || !paramWatchword) {
            navigate('/404')
        }
        const requestUrl = (`/order/apply?order_id=${paramOrderId}&watchword=${paramWatchword}`)
        const readyToOrder = async () => {
            try {
                const config = { headers: { Authorization: `Bearer ${accessToken}` } }
                const orderReadyResponse = await authAxios.get(requestUrl, config, { withCredentials: true })
                if (orderReadyResponse.status === 200) {
                    setAccessAllowed(true)
                    setTargetDivinationKey(orderReadyResponse?.data.divination_key)
                }
            } catch (error) {
                const errorResponseStatusCode = error?.response?.status
                if (errorResponseStatusCode === 404 || errorResponseStatusCode === 400 || errorResponseStatusCode === 401) {
                    navigate('/404')
                } else if (errorResponseStatusCode === 429) {
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                }
            }
        }
        if (location.pathname === '/order/apply') {
            readyToOrder()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {accessAllowed &&
                <div className='w-full h-full'>
                    <div className='my-14 md:my-28 lg:my-14'>
                        <div className='text-center py-14 md:pt-14 md:pb-28 lg:py-14'>
                            <h2 className='text-2xl md:text-3xl font-bold'>~ 鑑定申し込みフォーム ~</h2>
                            <p className='text-sm md:text-base px-4 mt-5'>
                                ご相談内容を入力してください。
                            </p>
                        </div>
                        <div className='bg-secondary bg-opacity-10 rounded-lg w-10/12 md:w-3/5 lg:w-2/5 px-4 md:px-8 py-6 md:py-16 mx-auto'>
                            {targetDivinationKey === 'one_tarot' &&
                                <OneTarotApplicationForm
                                    themeName={themeName}
                                    accessToken={accessToken}
                                />
                            }
                            {((targetDivinationKey === 'normal') || (targetDivinationKey === 'monthly')) &&
                                <NormalDivinationApplicationForm
                                    themeName={themeName}
                                    accessToken={accessToken}
                                />
                            }
                            {targetDivinationKey === 'color' &&
                                <ColorDivinationApplicationForm
                                    themeName={themeName}
                                    accessToken={accessToken}
                                />
                            }
                            {targetDivinationKey === 'thought' &&
                                <ThoughtTransmissionApplicationForm
                                    themeName={themeName}
                                    accessToken={accessToken}
                                />
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            }
            {!accessAllowed &&
                <div className='w-full h-full'>
                    <div className='flex items-center justify-center w-full h-screen'>
                        <p className='text-sm lg:text-base mr-1'>
                            🔮読み込み中です...
                        </p>
                        <span className="inline-block loading loading-ring loading-lg"></span>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default OrderApplication