import { Link } from 'react-router-dom'

function Product({ productNum, img, title, desc, price, type }) {
    return (
        <>
            <Link to={`/menu/${productNum}`} >
                <div className='shadow-base-normal rounded cursor-pointer h-60 md:h-80 lg:h-[420px]'>
                    <div className='bg-base-300 rounded-t img-container overflow-hidden h-[45%]'>
                        <img src={img} className='relative md:-top-4 xl:-top-16 w-full' alt="" />
                    </div>
                    <div className='desc-container h-[55%] p-1 md:p-3 lg:p-6'>
                        <div className='h-3/4'>
                            <h3 className='break-newline text-xs md:text-base font-bold'>{title}</h3>
                            <p className='break-newline text-2xs md:text-sm lg:text-base'>{desc}</p>
                        </div>
                        <div className='h-1/4 flex items-center justify-between'>
                            <p className='text-xs md:text-sm lg:text-lg font-bold'>
                                {price}
                            </p>
                            <p className='text-xs md:text-sm lg:text-base font-bold text-secondary border border-secondary p-1 md:py-1 md:px-2 rounded'>
                                {type}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default Product