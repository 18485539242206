import React from 'react'
import Product from './Product'
import ThumbnailImageForOneTarot from '../../assets/images/one_tarot.jpg'
import ThumbnailImageForFirstTarot from '../../assets/images/normal_divination.jpg'
import ThumbnailImageForSubscription from '../../assets/images/subscription_divination.jpg'
import ThumbnailImageForColor from '../../assets/images/color_divination.jpg'
// import ThumbnailImageForThoughtTransmission from '../../assets/images/candle_and_flower.jpg'

function TopProductsMenu() {
    return (
        <>
            <div className='menu-section h-auto px-4 pt-16 md:px-24 lg:px-40'>
                <h2 className='text-xl font-bold mb-5'>《鑑定メニュー》</h2>
                <div className='grid grid-cols-2 gap-4 md:gap-8 lg:gap-12'>
                    <Product
                        productNum={2}
                        img={ThumbnailImageForFirstTarot}
                        title={'【🍀通常お悩み鑑定🍀】\nどんな悩みでもOKです♡恋愛・お仕事・お金・人間関係など'}
                        desc={'※お悩みの内容は問いません'}
                        price={'¥4,400(税込)'}
                        type={'1回限り'}
                    />
                    <Product
                        productNum={3}
                        img={ThumbnailImageForSubscription}
                        title={'【⭐️毎月1回お悩み鑑定⭐️】\n通常価格より"おトク"に！月に1回までの鑑定が可能です♡'}
                        desc={'※【🍀通常お悩み鑑定🍀】のサブスクリプション版です'}
                        price={'¥3,300(税込)'}
                        type={'月々払い'}
                    />
                    <Product
                        productNum={4}
                        img={ThumbnailImageForColor}
                        title={'【🎨カラー鑑定🎨】\nあなたの特性や深層心理、将来へのインスピレーションを読み解きます♡'}
                        desc={'※購入された"ご本人様"が対象です'}
                        price={'¥4,400(税込)'}
                        type={'1回限り'}
                    />
                    {/* <Product
                        productNum={5}
                        img={ThumbnailImageForThoughtTransmission}
                        title={'【💐思念伝達鑑定💐】\nあなたの"想い"をお相手様の潜在意識に届けます♡恋愛/復縁/片想い'}
                        desc={'※お悩みの内容は問いません'}
                        price={'¥4,4000(税込)'}
                        type={'1回限り'}
                    /> */}
                    <Product
                        productNum={1}
                        img={ThumbnailImageForOneTarot}
                        title={'【-Oneタロット鑑定-】\n1枚のカードからメッセージや意味、ヒントを読み取りお伝えします💗'}
                        desc={'※お悩みの内容は問いません'}
                        price={'¥550(税込)'}
                        type={'1回限り'}
                    />
                </div>
            </div>
        </>
    )
}

export default TopProductsMenu