import React from 'react'

function TopProfileSection() {
    return (
        <>
            <div className='profile-section h-auto px-8 pt-16 md:px-32 lg:px-44'>
                <p>
                    こんにちは！♡🍀<br />
                    『<span className='font-bold'>凛（Rin）</span>』と申します。<br />
                    ご覧いただきありがとうございます。<br />
                    <br />
                    <br />
                    <br />
                    これまでタロットや占星術、数秘術、スピリチュアル、霊視などを用いて、多くの方の鑑定を行ってまいりました。
                    <br />
                    <br />
                    <br />
                    現在は、メインの活動として<br />
                    《<span className='font-bold'>チャット鑑定</span>》を行なっております。^^<br />
                    <br />
                    <br />
                    <br />
                    最近は"SNS"での活動も始めてみたり、新しいことにも挑戦しています🚀⭐️<br />
                    まだまだ至らない部分も多いですが…皆さまのおかげで、不慣れながらも楽しく運営しております♡♪<br />
                    <br />
                    <br />
                    <br />
                    また、嬉しいことにSNS経由での鑑定依頼をいただく機会も増えてまいりました🌱🌱<br />
                    <br />
                    私を信頼して声をかけてくださる方が増えていることに、"<span className='font-bold'>感謝</span>"の気持ちでいっぱいです…!!✨(T ^ T)<br />
                    <br />
                    <br />
                    <br />
                    これまではチャット鑑定専用のアプリなどを利用して鑑定をお届けしていましたが「より多くの方に、もっとお気軽にご相談いただけるように」との思いから、私…『<span className='font-bold'>凛（Rin）の公式サイト</span>』を開設いたしました♡✨🎉<br />
                    <span className='text-sm'>(皆様からのご意見やご要望に合わせて、その都度、更新や改修を行ってまいりますね^^)</span><br />
                    <br />
                    <br />
                    <br />
                    鑑定はいつも"<span className='font-bold'>お一人お一人に丁寧に寄り添う</span>"をモットーに行なっております☺️💐<br />
                    相談者様からお話をお伺いした上で、私が一緒にお悩み解決のお手伝いをさせていただきます。<br />
                    <br />
                    ぜひ、お気軽にご相談くださいね🕊️🔑<br />
                    お待ちしております。<br />
                    <br />
                    <br />
                    <br />
                    <br />
                    【占術】<br />
                    ◆タロットカード<br />
                    ◆占星術<br />
                    ◆数秘術<br />
                    ◆スピリチュアル<br />
                    ◆霊視<br />
                    ◆カラー占い<br />
                    ◆ヒーリング<br />
                    <br />
                    <br />
                    <br />
                    【得意な分野】<br />
                    ◆恋愛<br />
                    ・復縁<br />
                    ・片想い<br />
                    ・浮気/不倫<br />
                    ・結婚/離婚<br />
                    ・同性愛<br />
                    ◆仕事/ビジネス<br />
                    ・人付き合い<br />
                    ・就職/転職<br />
                    ・独立/開業<br />
                    ・キャリア/スキルアップ<br />
                    ◆人間関係<br />
                    ・家族<br />
                    ・夫婦<br />
                    ・親子<br />
                    ・友人<br />
                </p>
            </div>
        </>
    )
}

export default TopProfileSection