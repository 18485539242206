import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { accessTokenContext } from '../utils/contexts/AccessToken'
import { themeNameContext } from '../utils/contexts/ThemeName'
import UseAuthAxios from '../utils/hooks/UseAuthAxios'
import Footer from '../components/layout/Footer'
import PaymentSuccessModal from '../components/modals/PaymentSuccessModal'
import WatchwordChangeFormModal from '../components/modals/WatchwordChangeFormModal'
import WatchwordChangeConfirmModal from '../components/modals/WatchwordChangeConfirmModal'
import { IoIosClose } from "react-icons/io"
import { toast } from 'react-toastify'

function OrderSuccess() {

    const authAxios = UseAuthAxios()
    const location = useLocation()
    const navigate = useNavigate()
    const { accessToken, setAccessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)

    const [accessAllowed, setAccessAllowed] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(true)
    const [orderId, setOrderId] = useState('')
    const [watchword, setWatchword] = useState('')
    const [newWatchword, setNewWatchword] = useState('')
    const [changeWatchwordAvailable, setChangeWatchwordAvailable] = useState(false)
    const [watchwordChanged, setWatchwordChanged] = useState(false)

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const paramOrderId = queryParams.get('order_id')
        const paramWatchword = queryParams.get('watchword')
        if (!paramOrderId || !paramWatchword) {
            navigate('/404')
        }
        const requestUrl = (`/order/success?order_id=${paramOrderId}&watchword=${paramWatchword}`)
        const fetchOrderData = async () => {
            try {
                const config = { headers: { Authorization: `Bearer ${accessToken}` } }
                const successResponse = await authAxios.get(requestUrl, config, { withCredentials: true })
                if (successResponse.status === 200) {
                    setAccessToken(successResponse?.data.access_token)
                    setAccessAllowed(true)
                    setOrderId(successResponse?.data.order_id)
                    setWatchword(successResponse?.data.watchword)
                    if (successResponse?.data.default && successResponse?.data.default === true) {
                        setChangeWatchwordAvailable(true)
                    } else {
                        setChangeWatchwordAvailable(false)
                    }
                }
            } catch (error) {
                const errorResponseStatusCode = error?.response?.status
                if (errorResponseStatusCode === 404 || errorResponseStatusCode === 400 || errorResponseStatusCode === 401) {
                    navigate('/404')
                    return
                }
                if (errorResponseStatusCode === 429) {
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                    return
                }
            }
        }
        // /order/successページにアクセスできていることを確認できたら、fetchOrderData()を実行
        if (location.pathname === '/order/success') {
            fetchOrderData()
        }
        // eslint-disable-next-line
    }, [watchwordChanged])

    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden'
        }
    }, [modalIsOpen])

    const closeAddModal = () => {
        setModalIsOpen(false)
        // setSearchResultData(undefined)
        document.body.style.overflow = 'auto'
    }

    const showWatchwordChangeFormModal = () => {
        document.getElementById('watchword_change_form_modal').showModal()
    }

    const showWatchwordChangeConfirmModal = () => {
        document.getElementById('watchword_change_confirm_modal').showModal()
    }

    const changeWatchwordNextStep = (newWatchwordValue) => {
        setNewWatchword(newWatchwordValue)
        document.getElementById('watchword_change_form_modal').close()
        showWatchwordChangeConfirmModal()
    }

    return (
        <>
            {accessAllowed &&
                <>
                    <div className='w-full h-full'>
                        <div className='my-14'>
                            <div className='text-center py-14 md:py-20'>
                                <div className='bg-primary text-primary-content rounded-lg w-10/12 md:w-3/5 mx-auto px-2 py-5'>
                                    <h2 className='text-2xl font-bold'>~ ご注文の詳細 ~</h2>
                                    <p className='text-xs mt-5'>
                                        以下の内容でご注文を承りました。<br />必ずご確認くださいませ。
                                    </p>
                                </div>
                            </div>
                            <div className='w-10/12 md:w-3/5 mx-auto'>
                                <div className='w-full px-2'>
                                    <p className='text-sm'>
                                        この度は私の鑑定をご購入いただき、誠にありがとうございます💐^^<br />
                                        <br />
                                        【ご購入完了お知らせメール】を送信いたしましたので、ご確認ください。<br />
                                        ※はじめは迷惑メールフォルダに振り分けられている場合がございますので、ご注意ください。<br />
                                        <br />
                                        下記の『<span className='font-bold border-b border-base-content'>注文ID</span>』と『<span className='font-bold border-b border-base-content'>あいことば</span>』は、鑑定スタートの際に必要となります。<br />
                                        <br />
                                        <br />
                                        <span className='font-bold text-2xl text-error'>必ず"2つセット"で<br /> 大切に保管</span>してください🔑<br />
                                        <span className='font-bold'>・メモ帳</span><br />
                                        <span className='font-bold'>・スクリーンショット</span><br />
                                        などを活用して保存されることをオススメします。<br />
                                    </p>
                                    <p className='mt-12 text-center tracking-widest'>↓ ↓ ↓ ↓ ↓ ↓</p>
                                    <dl className='border border-error shadow-base-normal text-lg rounded-lg flex flex-col gap-5 w-full p-5 my-4 md:w-3/4 lg:w-3/5 md:mx-auto'>
                                        <div className='flex flex-col'>
                                            <dt className='mr-4'>＜注文ID＞</dt>
                                            <dd className='font-bold text-xl'>{orderId}</dd>
                                        </div>
                                        <div className='flex flex-col'>
                                            <dt className='mr-4'>＜あいことば＞</dt>
                                            <dd className='font-bold text-xl'>{watchword}</dd>
                                            {changeWatchwordAvailable &&
                                            <dd
                                                className='text-xs text-info cursor-pointer mt-5'
                                                onClick={() => showWatchwordChangeFormModal()}
                                            >
                                                「あいことば」を変更（※1回限り）
                                            </dd>
                                            }
                                        </div>
                                    </dl>
                                    <p className='mb-12 text-center tracking-widest'>↑ ↑ ↑ ↑ ↑</p>
                                    <p className='text-sm'>
                                        上記の情報を保存いただきましたら、下記のボタンより《<span className='font-bold border-b border-base-content'>鑑定のお申し込み</span>》の操作をお願いいたします。<br />
                                    </p>
                                    <div className='text-center mt-10'>
                                    <div
                                        onClick={() => navigate(`/order/apply?order_id=${orderId}&watchword=${watchword}`)}
                                        className='btn btn-primary text-lg shadow-base-normal'>『鑑定申し込み』ページへ
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                    {modalIsOpen &&
                        <div className='fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-80 flex justify-center items-center cursor-pointer'
                            onClick={closeAddModal}
                        >
                            <div className='bg-base-content rounded-md h-2/5 w-[85%] md:w-3/5 lg:w-1/2 p-1 cursor-default'
                                onClick={(e) => e.stopPropagation()}
                            >
                                <PaymentSuccessModal />
                            </div>
                            <IoIosClose className='absolute bottom-[15%] left-1/2 transform -translate-x-1/2 text-8xl text-white cursor-pointer' onClick={closeAddModal} />
                        </div>
                    }
                    {(changeWatchwordAvailable && !watchwordChanged ) &&
                        <WatchwordChangeFormModal
                            defaultWatchword={watchword}
                            changeWatchwordNextStep={changeWatchwordNextStep}
                        />
                    }
                    {(changeWatchwordAvailable && !watchwordChanged ) &&
                        <WatchwordChangeConfirmModal
                            orderId={orderId}
                            defaultWatchword={watchword}
                            newWatchword={newWatchword}
                            setWatchwordChanged={setWatchwordChanged}
                        />
                    }
                </>
            }
            {!accessAllowed &&
                <div className='w-full h-full'>
                    <div className='flex items-center justify-center w-full h-screen'>
                        <p className='text-sm lg:text-base mr-1'>
                            🍀読み込み中です...
                        </p>
                        <span className="inline-block loading loading-ring loading-lg"></span>
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default OrderSuccess