import { useContext, useEffect, useState } from 'react'
import UseAuthAxios from '../../../utils/hooks/UseAuthAxios'
import { accessTokenContext } from '../../../utils/contexts/AccessToken'
import { themeNameContext } from '../../../utils/contexts/ThemeName'
import { toast } from 'react-toastify'
import { FaUser } from 'react-icons/fa'
import UseValidation from '../../../utils/hooks/UseValidation'

function PortalWatchwordChangeModal({ toChangeWatchwordOrderId }) {

    const authAxios = UseAuthAxios()
    const { themeName } = useContext(themeNameContext)
    const { accessToken } = useContext(accessTokenContext)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [formData, setFormData] = useState({
        info: {
            watchword: ''
        },
        message: {
            watchword: ''
        }
    })
    const { info, message } = formData

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        if (info.watchword !== '') {
            if (message.watchword === '') {
                setButtonDisabled(false)
            } else if (message.watchword !== '') {
                setButtonDisabled(true)
            }
        } else {
            setButtonDisabled(true)
        }
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const closeThisModalProcess = () => {
        setButtonDisabled(true)
        setIsLoading(false)
        setFormData({
            info: {
                watchword: ''
            },
            message: {
                watchword: ''
            }
        })
        document.getElementById('portal_watchword_change_form_modal').close()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        toggleLoading()  // ローディング開始
        const data = {
            order_id: toChangeWatchwordOrderId,
            new_watchword: info.watchword,
        }
        try {
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const response = await authAxios.post('/order/watchword/change', data, config, { withCredentials: true })
            if (response.status === 200) {
                toggleLoading()
                document.getElementById('portal_order_cancel_modal').close()
                toast.success('あいことばを変更しました👒\n大切に保管しておきましょう♡', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                toast.error('該当する注文が見つかりませんでした。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            }else if (error?.response?.status === 409 || error?.data?.detail === 'Already used.') {
                toast.error('すでに使用されています。\n別の「あいことば」を入力してください。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            } else if (error?.response?.status === 400 || error?.response?.status === 401) {
                toast.error('不正なリクエストです。\n最初からやり直してください。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            } else if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
            }
        }
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <dialog id="portal_watchword_change_form_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content">
                    <h3 className="font-bold text-xl">
                        「あいことば」の変更
                    </h3>
                    <p className='text-xs px-4 my-4'>
                        ※変更後の「あいことば」は、ご注文の詳細・状況確認、鑑定スタートの際に必要となります。
                    </p>
                    <p className='text-xs border-2 border-secondary rounded-md py-2 px-4 mb-4'>
                        変更する注文のID: <span className='text-sm font-bold'>{toChangeWatchwordOrderId}</span>
                    </p>
                    <form onSubmit={handleSubmit} className='w-full md:w-3/4' method="dialog">
                        <div className='flex flex-col text-center gap-2 w-full'>
                            <label className="input input-bordered flex items-center gap-2">
                                <FaUser className='text-lg' />
                                <input
                                    name='watchword'
                                    type="text"
                                    autoComplete='text'
                                    maxLength={20}
                                    className="grow"
                                    placeholder="新しい「あいことば」"
                                    required
                                    value={info.watchword || ''}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                />
                            </label>
                            {message.watchword && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.watchword}</p>}
                            {buttonDisabled ?
                                <div className='btn btn-active btn-ghost cursor-not-allowed'>
                                    決定
                                </div> :
                                isLoading ?
                                <div className='btn btn-primary text-base md:text-lg w-full'>
                                    <span className="loading loading-spinner loading-base text-primary-content" />
                                </div> :
                                <button
                                    type="submit"
                                    className='btn btn-primary text-base md:text-lg w-full'
                                >
                                    決定
                                </button>
                            }
                        </div>
                    </form>
                    <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                    <div className="w-full">
                        <form method="dialog">
                            <div className='flex justify-evenly'>
                                {!isLoading ?
                                    <button
                                        className="btn btn-neutral text-base md:text-lg w-full"
                                        onClick={() => closeThisModalProcess()}
                                    >
                                        キャンセル
                                    </button> :
                                    <div className="btn btn-neutral text-base md:text-lg w-full">キャンセル</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    {!isLoading &&
                        <button onClick={() => closeThisModalProcess()}>close</button>
                    }
                </form>
            </dialog>
        </>
    )
}

export default PortalWatchwordChangeModal