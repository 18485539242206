import { useContext } from 'react'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { IoPerson } from 'react-icons/io5'
import { BsStarFill } from 'react-icons/bs'

function ReviewItem({ gender, age, reviewText }) {

    const { themeName } = useContext(themeNameContext)

    return (
        <>
            <div className='flex justify-between min-h-32'>
                <div className='flex flex-col gap-1 items-center justify-center w-1/4 lg:w-2/12'>
                    <IoPerson className={`${themeName === 'cupcake' ? 'text-7xl text-base-content' : 'text-7xl text-gray-200'}`} />
                    <p className='text-2xs'>{gender} / {age}</p>
                    <div className='flex items-center'>
                        <BsStarFill className='text-xs text-accent' />
                        <BsStarFill className='text-xs text-accent' />
                        <BsStarFill className='text-xs text-accent' />
                        <BsStarFill className='text-xs text-accent' />
                        <BsStarFill className='text-xs text-accent' />
                    </div>
                </div>
                <div className={`${themeName === 'cupcake' ? 'relative bg-base-300 rounded-xl w-3/4 lg:w-10/12 p-3' : 'relative bg-base-content rounded-xl w-3/4 lg:w-10/12 p-3'}`}>
                    <div className={`${themeName === 'cupcake' ? 'border-r-base-300' : 'border-r-base-content'} absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-full w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8`}></div>
                    <p className={`text-xs lg:text-sm ${themeName === 'cupcake' ? 'text-base-content' : 'text-base-100'}`}>
                        {reviewText}
                    </p>
                </div>
            </div>
        </>
    )
}

export default ReviewItem