import ProfileCircleIcon from '../assets/images/rin_circle_icon.png'

function Maintenance() {
    return (
        <>
            <div className='z-[10000] bg-base-100 w-full h-[100vh]'>
                <div className="flex items-center text-center h-full">
                    <div className="max-w-2xl mx-auto">
                        <img src={ProfileCircleIcon} alt="Rin Enrich Life" className="rounded-full shadow-base-normal w-24 h-24 mx-auto mb-6" />
                        <p className="text-base md:text-xl lg:text-2xl font-bold mb-6">
                            いつもご利用いただき<span className='inline md:hidden'><br /></span>誠にありがとうございます。<br />
                            現在、システムメンテナンスを<span className='inline md:hidden'><br /></span>行なっております。<br />
                            ご不便をおかけいたしますが、<span className='inline md:hidden'><br /></span>しばらくお待ちください🍀
                        </p>
                        <p className="text-sm md:text-lg lg:text-xl text-gray-500">
                            Thank you for your continued patronage.<br />
                            Currently under maintenance.<br />
                            I apologize for any inconvenience caused.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Maintenance