import { TbShieldLock } from 'react-icons/tb'

function EffortToPrivacy() {
    return (
        <>
            <div className='relative border-4 border-primary rounded-2xl text-center mt-12 p-3 pt-6'>
                <div className='bg-base-100 absolute -top-7 left-1/2 transform -translate-x-1/2 px-3'>
                    <TbShieldLock className='text-5xl text-primary' />
                </div>
                <h4 className='font-bold text-xl'>安心・安全への取り組み</h4>
                <p>
                    <br />
                    個人情報は厳重に管理しております。<br />
                    皆さまからお預かりした個人情報（ご相談内容も含む）は、ご本人さま確認・鑑定のお届け以外の目的には使用いたしません。<br />
                    <br />
                    また金銭やそれに準ずるものを目的として<br />
                    個人情報を他の第三者に<br />
                    譲渡・提供することは一切ございません。<br />
                    <br />
                    安心してご利用くださいませ^^
                </p>
            </div>
        </>
    )
}

export default EffortToPrivacy