import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { themeNameContext } from '../../utils/contexts/ThemeName';
import UseAuthAxios from '../../utils/hooks/UseAuthAxios';
import UseObtainMenuAmount from '../../utils/hooks/UseObtainMenuAmount';
import { toast } from 'react-toastify'
import './ApplePayButton.css';

function ApplePayButton({ selectedMenuNum, accessToken }) {
    const { themeName } = useContext(themeNameContext);
    const authAxios = UseAuthAxios();
    const navigate = useNavigate();
    const menuData = UseObtainMenuAmount(selectedMenuNum);
    // eslint-disable-next-line no-undef
    if (!window.payjp) {
        // windowオブジェクトにpayjpがまだ設定されていなければ、新しく生成
        window.payjp = window.Payjp(process.env.REACT_APP_PAYJP_PUBLIC_KEY);
    }

    const sendPaymentRequestByApplePay = async (cardToken, useDefaultCard) => {
        try {
            const data = { menuId: selectedMenuNum, cardToken: cardToken, useDefaultCard: useDefaultCard}
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const paymentResponse = await authAxios.post(
                '/order/payment/',
                data,
                config,
                { withCredentials: true }
            )
            if (paymentResponse.status === 200) {
                return { success: true, response: paymentResponse}
            }
        } catch (error) {
            console.error(error);
            const paymentErrorResponse = error?.response
            return { success: false, response: paymentErrorResponse}
        }
    }

    const checkAvailabilityHandler = (available) => {
        if (available) {
            document.getElementById('apple-pay-button-wrapper').style.display = 'block';
            document.getElementById('notice').style.display = 'none';
            document.getElementById('loading').style.display = 'none';
        } else {
            document.getElementById('apple-pay-button-wrapper').style.display = 'none';
            document.getElementById('notice').style.display = 'block';
            document.getElementById('loading').style.display = 'none';
        }
    };

    const onClick = () => {
        const paymentRequest = {
            countryCode: 'JP',
            currencyCode: 'JPY',
            total: {
                label: menuData.title,
                amount: menuData.amount,
            }
        };
        const session = window.payjp.applePay.buildSession(paymentRequest,
            async function (result) {
                console.log('> result', result);
                document.getElementById('result').innerText = 'Token = ' + result.token.id;
                if (result?.token.id) {
                    const { success, response } = await sendPaymentRequestByApplePay(result.token.id, false)
                    if (success) {
                        // eslint-disable-next-line no-undef
                        session.completePayment(ApplePaySession.STATUS_SUCCESS);
                        const orderId = response?.data?.order_id
                        const watchword = response?.data?.watchword
                        toast.success('支払いが完了しました！✨', {
                            position: 'top-right',
                            autoClose: 2000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                        })
                        navigate(`/order/success?order_id=${orderId}&watchword=${watchword}`)
                    } else {
                        // eslint-disable-next-line no-undef
                        session.completePayment(ApplePaySession.STATUS_FAILURE);
                        if ((response?.status === 400) && (response?.data?.detail === 'Payment failed.')) {
                            toast.error('支払いに失敗しました。\n最初からやり直してください。', {
                                className: 'break-newline',
                                position: 'top-right',
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                            })
                            navigate(`/menu/${selectedMenuNum}`)
                            return
                        } else if ((response?.status === 400) && (response?.data?.detail === 'Invalid request')) {
                            toast.error('無効なリクエストです。', {
                                className: 'break-newline',
                                position: 'top-right',
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                            })
                            navigate(`/menu/${selectedMenuNum}`)
                            return
                        } else if (response?.status === 429) {
                            toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                                className: 'break-newline',
                                position: 'top-right',
                                autoClose: 2000,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                            })
                            navigate('/top')
                            return
                        } else {
                            toast.warning(`正常なリクエストが行われませんでした。\nお手数をおかけして申し訳ございません。\n『ポータル画面』から状況をご確認ください🙇‍♀️(${response?.status})`, {
                                className: 'break-newline',
                                position: 'top-right',
                                autoClose: 3500,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                            })
                            navigate(`/menu/${selectedMenuNum}`)
                            return
                        }
                    }
                }
            },
            function (e) {
                console.error(e);
                alert(e.error.message);
            }
        );
        session.begin();
    };

    useEffect(() => {
        window.payjp.applePay.checkAvailability(checkAvailabilityHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.payjp]);

    return (
        <>
            <div
                id="loading"
                className='flex flex-col items-center italic mx-auto'
            >
                Apple Pay準備中...
                <span className="inline-block mt-1 loading loading-ring loading-lg" />
            </div>
            <div
                id='notice'
                style={{ display: 'none' }}
                className='px-5'
            >
                <h2 className='font-bold'>
                    【Apple Payでの決済をご希望の方へ】
                </h2>
                <p className='text-sm'>
                    <span className='underline'>Apple PayはmacOSまたはiOSの"Safari"でのみご利用可能です。</span>
                    <br />
                    他のブラウザやデバイスではご利用いただけませんので、ご注意ください。
                </p>
            </div>
            <div id="apple-pay-button-wrapper" style={{ display: 'none' }}>
                <div className='flex flex-col items-center w-full'>
                    <div
                        id="apple-pay-button"
                        className={`apple-pay-button w-2/3 h-9 mx-auto ${themeName === 'cupcake' ? "apple-pay-button-black" : "apple-pay-button-white"}`}
                        onClick={onClick}
                    />
                </div>
            </div>
            <div>
                <p id="result"></p>
            </div>
        </>
    );
}

export default ApplePayButton;
