import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeNameContext } from '../contexts/ThemeName'
import { authAxios } from '../api/Axios'
import { toast } from 'react-toastify'

function UseAuthAxios() {
    const navigate = useNavigate()
    const { themeName } = useContext(themeNameContext)

    useEffect(() => {
        const responseIntercept = authAxios.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                const isNetworkError = error.code === "ERR_NETWORK"
                const isTimeout = error.code === "ECONNABORTED"
                if (isTimeout) {
                    toast.warning('サーバーからの応答がありませんでした🙇‍♀️\n《通知メール》や《ポータル画面》から\n現在の状況をご確認ください。\n問題が続く場合はお問い合わせください。', {
                        className: 'break-newline',
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                } else if (isNetworkError && error?.message === 'Network Error') {
                    toast.error('ネットワークエラーが発生しました🙇‍♀️\nもう一度最初からお試しいただくか、\n問題が続く場合はお問い合わせください。', {
                        className: 'break-newline',
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    navigate('/top')
                } else {
                    return Promise.reject(error)
                }
            }
        )
        return () => {
            authAxios.interceptors.response.eject(responseIntercept)
        }
        // eslint-disable-next-line
    }, [])

    return authAxios
}

export default UseAuthAxios