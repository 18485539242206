import { useEffect, useState } from 'react'
import NormalDivinationContents from '../../components/common/menu/NormaDivinationContents'
import PaymentConfirmModal from '../../components/modals/PaymentConfirmModal'
import RepeatConfirmModal from '../../components/modals/RepeatConfirmModal'
import IdentificationFormModal from '../../components/modals/IdentificationFormModal'

function NormalDivination({ setSelectedMenuNum }) {
    const menuNum = 2
    const [imageHeight, setImageHeight] = useState(0)
    const [buttonPosition, setButtonPosition] = useState('fixed')
    // eslint-disable-next-line
    const [intentionToPurchase, setIntentionToPurchase] = useState(false)
    const [isRepeater, setIsRepeater] = useState()

    useEffect(() => {
        //* Get the width of the device window
        const width = window.innerWidth
        // Modify the height of the image according to the width of the device window
        if (width > 1024) {
            setImageHeight(width / 3)
        } else if (width > 768) {
            setImageHeight(width / 3)
        } else {
            setImageHeight(width / 2)
        }
    }, [])

    useEffect(() => {
        //* Detect scroll events and follow the purchase button
        // Styling of purchase buttons to be followed is defined in index.css
        const handleScroll = () => {
            const buttonElement = document.querySelector('.cta-section')
            if (!buttonElement) return
            const buttonRect = buttonElement.getBoundingClientRect()
            // 画面内にボタンが完全に含まれている場合のみ、位置を静的にする
            if (buttonRect.top >= 0 && buttonRect.bottom <= window.innerHeight) {
                setButtonPosition('static')
            } else {
                setButtonPosition('fixed')
            }
        }
        window.addEventListener('scroll', handleScroll)
        // Check the position of the purchase button when the page is loaded
        handleScroll()
        // Clean up the event listener
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const showPurchaseConfirmModal = () => {
        document.getElementById('payment_confirm_modal').showModal()
    }

    const showRepeatModal = () => {
        document.getElementById('repeat_modal').showModal()
    }

    const showRegistrationConfirmModal = () => {
        document.getElementById('identification_modal').showModal()
    }

    const resetAllQuestions = () => {
        setIntentionToPurchase(false)
        setIsRepeater()
    }

    return (
        <>
            <div className='w-full h-full pb-28'>
                <NormalDivinationContents
                    imageHeight={imageHeight}
                    buttonPosition={buttonPosition}
                    showPurchaseConfirmModal={showPurchaseConfirmModal}
                />
                <PaymentConfirmModal
                    setIntentionToPurchase={setIntentionToPurchase}
                    showRepeatModal={showRepeatModal}
                    resetAllQuestions={resetAllQuestions}
                />
                <RepeatConfirmModal
                    setIsRepeater={setIsRepeater}
                    showRegistrationConfirmModal={showRegistrationConfirmModal}
                    resetAllQuestions={resetAllQuestions}
                />
                <IdentificationFormModal
                    resetAllQuestions={resetAllQuestions}
                    isRepeater={isRepeater}
                    menuNum={menuNum}
                    setSelectedMenuNum={setSelectedMenuNum}
                />
            </div>
        </>
    )
}

export default NormalDivination