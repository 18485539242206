import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { accessTokenContext } from "../../utils/contexts/AccessToken"
import { privateRouteAccessContext } from "../../utils/contexts/PrivateRouteAccess"
import { themeNameContext } from "../../utils/contexts/ThemeName"
import UseAuthAxios from "../../utils/hooks/UseAuthAxios"
import { toast } from 'react-toastify'

function OrderInfoModal({ title, orderId, watchword, statusStr, purchasedDatetime, resetAll }) {

    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const { accessToken, setAccessToken } = useContext(accessTokenContext)
    const { setPrivateRouteAccess } = useContext(privateRouteAccessContext)
    const { themeName } = useContext(themeNameContext)
    const [isLoading, setIsLoading] = useState(false)

    const registerNewCardFlow = async () => {
        try {
            setIsLoading(true)
            const data = { order_id: orderId, watchword: watchword }
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const prepareResponse = await authAxios.post('/subscription/resume/prepare', data, config, { withCredentials: true })
            if (prepareResponse.status === 200) {
                const resumeToken = prepareResponse?.data?.access_token
                setAccessToken(resumeToken)
                setPrivateRouteAccess(true)
                sessionStorage.setItem('privateAccessGranted', 'true')
                document.getElementById('order_info_modal').close()
                navigate(`/subscription/resume?order_id=${orderId}&watchword=${watchword}`)
            }
        } catch (error) {
            console.error(error)
            if (error.response.status === 400) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('無効なリクエストです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error.response.status === 404) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('リクエストに失敗しました。\nこの操作は行えないようです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error?.response?.status === 429) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            }
        }
    }

    const immediateResumeSubscriptionFlow = async () => {
        try {
            setIsLoading(true)
            const data = { order_id: orderId, watchword: watchword }
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const immediateResumeResponse = await authAxios.post('/subscription/immediate/resume', data, config, { withCredentials: true })
            if (immediateResumeResponse.status === 200) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.success('プランの再開が完了しました！✨', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                return
            }
        }catch (error) {
            console.error(error)
            if (error?.response?.status === 400) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('無効なリクエストです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error?.response?.status === 404) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('リクエストに失敗しました。\nこの操作は行えないようです。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else if (error?.response?.status === 429) {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            } else {
                setIsLoading(false)
                document.getElementById('order_info_modal').close()
                toast.error('エラーが発生しました。', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
            }
        }
    }

    return (
        <>
            <dialog id="order_info_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content">
                    <h3 className="font-bold text-xl">
                        🔮 ご注文の詳細・状況 🔮
                    </h3>
                    {statusStr === '一時停止中' &&
                        <p className='border border-error text-xs rounded p-2 mt-6'>
                            ⚠️ご登録いただいているクレジットカードが<span className='font-bold text-error'>何らかの理由によりご利用いただけない</span>ようです。<br />
                            ⚠️ご利用中のプランが『<span className='font-bold text-error'>一時停止</span>』の状態になっています。<br />
                            <br />
                            ※カードのご状況をご確認の上、再度のご登録をお願い致します☘️
                            以下のボタンよりカードを再登録することで、<span className='font-bold'>プランの再開</span>が可能です。
                        </p>
                    }
                    <hr className='border-base-content border-opacity-30 my-4 w-11/12 mx-auto' />
                    <dl className='flex flex-col gap-2'>
                        <div className='flex'>
                            <dt className='font-bold'>タイトル：</dt>
                            <dd>&nbsp;&nbsp;『{title}』</dd>
                        </div>
                        <div className='flex'>
                            <dt className='font-bold'>注文ID：</dt>
                            <dd>&nbsp;&nbsp;{orderId}</dd>
                        </div>
                        <div className='flex'>
                            <dt className='font-bold'>あいことば：</dt>
                            <dd>&nbsp;&nbsp;{watchword}</dd>
                        </div>
                        <div className='flex'>
                            <dt className='font-bold'>ステータス：</dt>
                            <dd
                                className={`font-bold ${statusStr === '申し込み可能' ? 'text-info' : statusStr === '受付済み' ? 'text-secondary' : statusStr === '鑑定中' ? 'text-warning' : statusStr === '一時停止中' ? 'text-error' : statusStr === 'キャンセル済み' ? 'text-error' : 'text-success'}`}
                            >
                                &nbsp;&nbsp;{statusStr}
                            </dd>
                        </div>
                        <div className='flex'>
                            <dt className='font-bold'>購入日時：</dt>
                            <dd>&nbsp;&nbsp;{purchasedDatetime}</dd>
                        </div>
                    </dl>
                    <form method="dialog" className='w-3/4 md:w-3/5 mt-4'>
                        {statusStr === '申し込み可能' &&
                            <div
                                onClick={() => navigate(`/order/apply?order_id=${orderId}&watchword=${watchword}`)}
                                className="btn btn-primary shadow-base-normal w-full">
                                鑑定申し込みページへ
                            </div>
                        }
                        {statusStr === '受付済み' &&
                            <div
                                onClick={() => navigate(`/order/how-to-start`)}
                                className="btn btn-primary shadow-base-normal w-full">
                                鑑定をスタートさせる
                            </div>
                        }
                        {(statusStr === 'キャンセル済み' && !isLoading) &&
                            <div
                                onClick={() => immediateResumeSubscriptionFlow()}
                                className="btn btn-primary shadow-base-normal w-full">
                                プランを再開する
                            </div>
                        }
                        {(statusStr === 'キャンセル済み' && isLoading) &&
                            <div className="btn btn-primary shadow-base-normal w-full">
                                <span className="loading loading-spinner loading-base text-primary-content"></span>
                            </div>
                        }
                        {(statusStr === '一時停止中' && !isLoading) &&
                            <div
                                onClick={() => registerNewCardFlow()}
                                className="btn btn-primary shadow-base-normal w-full">
                                カードを再登録する
                            </div>
                        }
                        {(statusStr === '一時停止中' && isLoading) &&
                            <div className="btn btn-primary shadow-base-normal w-full">
                                <span className="loading loading-spinner loading-base text-primary-content"></span>
                            </div>
                        }
                        <button onClick={() => resetAll()} className='btn btn-secondary w-full mt-4'>閉じる</button>
                    </form>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => resetAll()}>close</button>
                </form>
            </dialog>
        </>
    )
}

export default OrderInfoModal