import { useContext, useState } from 'react'
import UseAuthAxios from '../../utils/hooks/UseAuthAxios'
import { accessTokenContext } from '../../utils/contexts/AccessToken'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { FaUser } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'

// バリデーションルールを定義
const schema = yup.object({
    email: yup.string().required('メールアドレスは必須です').email('正しいメールアドレスを入力してください'),
    name: yup.string().required('名前は必須です').min(2, '名前は2文字以上で入力してください').max(20, '名前は20文字以内で入力してください')
}).required();

function CancelCodeSendModal({ showCancelLinkSendModal, resetAllQuestions }) {

    const authAxios = UseAuthAxios()
    const { themeName } = useContext(themeNameContext)
    const { setAccessToken } = useContext(accessTokenContext)
    const [isLoading, setIsLoading] = useState(false)

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const resetAll = () => {
        reset() //clear all inputs of react-hook-form
        setIsLoading(false)
        document.getElementById('cancel_code_send_modal').close()
        resetAllQuestions()
    }

    const closeThisModal = () => {
        resetAll()
        setIsLoading(false)
        document.getElementById('cancel_code_send_modal').close()
    }

    const onSubmit = (data) => {
        setIsLoading(true)

        const EnterSubscriptionCancelCodeSendForm = async () => {
            try {
                const confirmResponse = await authAxios.post('/subscription/cancel-code/send', data)
                if (confirmResponse.status === 200) {
                    setAccessToken(confirmResponse?.data?.access_token)
                    document.getElementById('cancel_code_send_modal').close()
                    closeThisModal()
                    showCancelLinkSendModal()
                }
            } catch (error) {
                setIsLoading(false)
                const cancelCodeSendErrorResponse = error?.response
                if (cancelCodeSendErrorResponse?.status === 400) {
                    resetAll()
                    toast.error('無効なリクエストです。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 404 && cancelCodeSendErrorResponse?.data?.detail === 'Not found.') {
                    resetAll()
                    toast.warning('メールアドレスまたは\nお名前が正しくないようです。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 404 && cancelCodeSendErrorResponse?.data?.detail === 'Resource not found.') {
                    resetAll()
                    toast.warning('プランが見つかりませんでした🙇‍♀️\nご状況をご確認くださいませ。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                } else if (cancelCodeSendErrorResponse?.status === 429) {
                    resetAll()
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                }
            }
        }
        EnterSubscriptionCancelCodeSendForm()
    };

    return (
        <>
            <dialog id="cancel_code_send_modal" className="modal">
                <div className="modal-box border-2 border-base-content">
                    <div className='text-center mb-4'>
                        <ul className="steps steps-horizontal">
                            <li className="step step-primary"></li>
                            <li className="step"></li>
                            <li className="step"></li>
                        </ul>
                    </div>
                    <h3 className="font-bold text-xl">
                        <span className='underline'>【ご本人様情報のご記入】</span>をお願いいたします✏️.｡.:*・ﾟ✽
                    </h3>
                    <p className="text-sm py-2 my-2 md:my-4">
                        プランをキャンセルいただくにあたり、ご記入いただく必要がございます🍀🔑
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-2 w-full'>
                            <label className="input input-bordered flex items-center gap-2">
                                <MdMail className='text-xl' />
                                <input
                                    type="email"
                                    {...register('email')}
                                    autoComplete='email'
                                    className="grow"
                                    placeholder="メールアドレス"
                                />
                            </label>
                            {errors.email && <p className='text-error text-xs'>{errors.email.message}</p>}
                            <label className="input input-bordered flex items-center gap-2">
                                <FaUser className='text-lg' />
                                <input
                                    type="text"
                                    maxLength={20}
                                    {...register('name')}
                                    className="grow"
                                    placeholder="お名前(ニックネームなども可)"
                                />
                            </label>
                            {errors.name && <p className='text-error text-xs'>{errors.name.message}</p>}
                            {isLoading ?
                                <div className='btn shadow-base-normal text-base md:text-lg w-full mt-2'>
                                    <span className="loading loading-spinner loading-base text-primary-content"></span>
                                </div> :
                                <button
                                    type="submit"
                                    className='btn btn-primary shadow-base-normal text-base md:text-lg w-full mt-2'
                                >完了</button>
                            }
                        </div>
                    </form>
                    <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                <button
                                    className="btn btn-neutral text-base md:text-lg w-full"
                                    onClick={() => resetAll()}
                                >
                                    キャンセル
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => resetAll()}>close</button>
                </form>
            </dialog>
        </>
    )
}

export default CancelCodeSendModal