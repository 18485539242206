import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import UseAuthAxios from '../../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import { accessTokenContext } from '../../utils/contexts/AccessToken'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { IoPricetag, IoChatbubbleEllipses } from 'react-icons/io5'
import UseValidation from '../../utils/hooks/UseValidation'

function OrderCollationModal({ setOrderId, setWatchword, setPurchasedDatetime, setDivinationStatus, setDivinationTitle, showOrderInfoModal, resetAll }) {

    const authAxios = UseAuthAxios()
    const { accessToken, setAccessToken } = useContext(accessTokenContext)
    const { themeName } = useContext(themeNameContext)
    const [isLoading, setIsLoading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [formData, setFormData] = useState({
        info: {
            orderId: '',
            watchword: ''
        },
        message: {
            orderId: '',
            watchword: ''
        }
    })
    const { info, message } = formData

    useEffect(() => {
        // formData.infoが全て入力されていて、且つformData.messageが全て空の場合、ボタンを有効化
        if (info.orderId !== '' && info.watchword !== '') {
            if (message.orderId === '' && message.watchword === '') {
                setButtonDisabled(false)
            } else if (message.orderId !== '' || message.watchword !== '') {
                setButtonDisabled(true)
            }
        } else {
            setButtonDisabled(true)
        }
        // eslint-disable-next-line
    }, [formData])

    const toggleLoading = () => {
        setIsLoading(prevIsLoading => !prevIsLoading)
    }

    const closeThisModalProcess = () => {
        setAccessToken('')
        setButtonDisabled(true)
        setIsLoading(false)
        setFormData({
            info: {
                orderId: '',
                watchword: ''
            },
            message: {
                orderId: '',
                watchword: ''
            }
        })
        document.getElementById('order_collation_modal').close()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        toggleLoading()  // ローディング開始
        const data = {
            order_id: info.orderId,
            watchword: info.watchword
        }
        try {
            const config = { headers: { Authorization: `Bearer ${accessToken}` } }
            const response = await authAxios.post('/order/collation', data, config, { withCredentials: true })
            if (response.status === 200) {
                setOrderId(info.orderId)
                setWatchword(info.watchword)
                setPurchasedDatetime(response?.data.purchased_datetime)
                setDivinationStatus(response?.data.status)
                setDivinationTitle(response?.data.title)
                setAccessToken(response?.data.access_token)
                toggleLoading()
                setFormData({
                    info: {
                        orderId: '',
                        watchword: ''
                    },
                    message: {
                        orderId: '',
                        watchword: ''
                    }
                })
                document.getElementById('order_collation_modal').close()
                showOrderInfoModal()
            }
        } catch (error) {
            if (error.response.status === 404) {
                toast.error('該当する注文が見つかりませんでした。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
                return
            }
            if (error?.response?.status === 400 || error?.response?.status === 401) {
                toast.error('不正なリクエストです。\n最初からやり直してください。', {
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
                return
            }
            if (error?.response?.status === 429) {
                toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                    className: 'break-newline',
                    position: 'top-right',
                    autoClose: 2000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                })
                closeThisModalProcess()
                return
            }
        }
    }

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setFormData(() => ({
            info: {...info, [key]: value},
            message: {...message, [key]: UseValidation.formValidate(key, value)}
        }))
    }

    return (
        <>
            <dialog id="order_collation_modal" className="modal">
                <div className="modal-box flex flex-col items-center border-2 border-base-content">
                    <h3 className="font-bold text-xl">
                        🔍 ご注文の検索 🔍
                    </h3>
                    <p className='text-xs md:text-base px-4 my-5'>
                        以下のフォームに、ご注文完了時に表示された<br />
                        『注文ID』と『あいことば』を入力すると、<br />
                        ご注文の詳細や状況を確認いただけます。
                    </p>
                    <form onSubmit={handleSubmit} className='w-full px-4'>
                        <div className='flex flex-col gap-3 md:gap-5'>
                            <label className="input input-bordered flex items-center gap-2">
                                <IoPricetag className='text-xl' />
                                <input
                                    name='orderId'
                                    type="text"
                                    autoComplete='text'
                                    maxLength={6}
                                    className="grow"
                                    placeholder="注文ID"
                                    required
                                    value={info.orderId || ''}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                />
                            </label>
                            {message.orderId && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.orderId}</p>}
                            <label className="input input-bordered flex items-center gap-2">
                                <IoChatbubbleEllipses className='text-lg' />
                                <input
                                    name='watchword'
                                    type="text"
                                    autoComplete='text'
                                    maxLength={20}
                                    className="grow"
                                    placeholder="あいことば"
                                    required
                                    value={info.watchword || ''}
                                    onChange={handleChange}
                                    disabled={isLoading}
                                />
                            </label>
                            {message.watchword && <p className='text-error py-px px-0.5 rounded text-sm font-base pl-2'>{message.watchword}</p>}
                            {buttonDisabled ?
                                <div className='btn btn-active btn-ghost cursor-not-allowed'>
                                    完了
                                </div> :
                                isLoading ?
                                <div className='btn btn-primary text-base md:text-lg w-full'>
                                    <span className="loading loading-spinner loading-base text-primary-content" />
                                </div> :
                                <button
                                    type="submit"
                                    className='btn btn-primary text-base md:text-lg w-full'
                                >
                                    完了
                                </button>
                            }
                        </div>
                        <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                        {!isLoading ?
                            <div onClick={() => closeThisModalProcess()} className='btn btn-secondary w-full cursor-pointer'>閉じる</div> :
                            <div className='btn btn-secondary w-full cursor-not-allowed'>閉じる</div>
                        }

                    </form>
                    <div className='text-xs md:text-base md:w-3/4 lg:w-1/2 md:mx-auto'>
                        <p className='bg-base-300 text-center rounded p-3 md:p-6 mt-6'>
                            ※どうしても『注文ID』や『あいことば』が分からない場合は、<span className='hidden md:inline'><br /></span>
                            大変お手数ではございますが<span className='hidden md:inline'><br /></span>
                            <Link to='/order/collation' onClick={() => closeThisModalProcess()} className='text-info font-bold'>注文の詳細・状況確認ページ</Link>の【ポータル画面URL】の発行をお試しください。
                        </p>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    {!isLoading &&
                        <button onClick={() => closeThisModalProcess()}>close</button>
                    }
                </form>
            </dialog>
        </>
    )
}

export default OrderCollationModal