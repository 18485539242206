import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UseAuthAxios from '../../utils/hooks/UseAuthAxios'
import { toast } from 'react-toastify'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import { accessTokenContext } from '../../utils/contexts/AccessToken'
import { privateRouteAccessContext } from '../../utils/contexts/PrivateRouteAccess'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaUser } from 'react-icons/fa'
import { MdMail } from 'react-icons/md'

// バリデーションルールを定義
const schema = yup.object({
    email: yup.string().required('メールアドレスは必須です').email('正しいメールアドレスを入力してください'),
    name: yup.string().required('名前は必須です').min(2, '名前は2文字以上で入力してください').max(20, '名前は20文字以内で入力してください')
}).required();

function IdentificationForm({ resetAllQuestions, isRepeater, menuNum, setSelectedMenuNum }) {

    const authAxios = UseAuthAxios()
    const navigate = useNavigate()
    const [isAgreed, setIsAgreed] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { themeName } = useContext(themeNameContext)
    const { setAccessToken } = useContext(accessTokenContext)
    const { setPrivateRouteAccess } = useContext(privateRouteAccessContext)

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const resetAll = () => {
        reset() //clear all inputs of react-hook-form
        setIsLoading(false)
        resetAllQuestions()
    }

    const onSubmit = (data) => {
        setIsLoading(true)
        data['menu_id'] = menuNum
        data['repeat'] = isRepeater

        const EnterPaymentConfirmForm = async () => {
            try {
                const confirmResponse = await authAxios.post('/order/confirm', data)
                if ((confirmResponse.status === 200) && (confirmResponse?.data?.access_token)) {
                    setIsLoading(false)
                    resetAllQuestions()
                    document.getElementById('identification_modal').close()
                    document.getElementById('payment_confirm_modal').close()
                    const access_token = confirmResponse.data.access_token
                    setAccessToken(access_token)
                    setPrivateRouteAccess(true)
                    setSelectedMenuNum(menuNum)
                    sessionStorage.setItem('privateAccessGranted', 'true')
                    navigate('/order/payment')
                }
            } catch (error) {
                const confirmErrorResponse = error?.response
                if (confirmErrorResponse?.status === 409 && confirmErrorResponse?.data?.detail === 'Already exists.') {
                    document.getElementById('identification_modal').close()
                    resetAll()
                    toast.warning('すでにご登録いただいているようです。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    return
                }
                if (confirmErrorResponse?.status === 400) {
                    document.getElementById('identification_modal').close()
                    resetAll()
                    toast.error('無効なリクエストです。\n最初からやり直してください。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    return
                }
                if (confirmErrorResponse?.status === 404 && confirmErrorResponse?.data?.detail === 'Not found.') {
                    document.getElementById('identification_modal').close()
                    resetAll()
                    toast.warning('メールアドレスまたは\nお名前が正しくないようです。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    return
                }
                if (confirmErrorResponse?.status === 409 && confirmErrorResponse?.data?.detail === 'Already subscribed.') {
                    document.getElementById('identification_modal').close()
                    resetAll()
                    toast.warning('すでにご利用中のプランがあります。\n重複してお申し込みいただくことはできません。', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    return
                }
                if (confirmErrorResponse?.status === 429) {
                    document.getElementById('identification_modal').close()
                    resetAll()
                    toast.error('リクエストが多すぎます。\nしばらくしてから再度お試しください🙏', {
                        className: 'break-newline',
                        position: 'top-right',
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: themeName === '' ? 'light' : themeName === 'night' ? 'dark' : 'light'
                    })
                    return
                }
            }
        }
        EnterPaymentConfirmForm()
    };

    return (
        <>
            <dialog id="identification_modal" className="modal">
                <div className="modal-box border-2 border-base-content">
                    <h3 className="font-bold text-xl">
                        <span className='underline'>
                            {isRepeater ? '【ご本人様情報のご入力】' : '【ご本人様情報のご登録】'}
                        </span>
                        をお願いいたします✏️.｡.:*・ﾟ✽
                    </h3>
                    <p className="text-sm py-2 my-2 md:my-4">
                        こちらは当サイトを安全にご利用いただく上で必要な情報でございます🍀🔑
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-2 w-full'>
                            <label className="input input-bordered flex items-center gap-2">
                                <MdMail className='text-xl' />
                                <input
                                    type="email"
                                    {...register('email')}
                                    autoComplete='email'
                                    className="grow"
                                    placeholder="メールアドレス"
                                    disabled={isLoading}
                                />
                            </label>
                            {errors.email && <p className='text-error text-xs'>{errors.email.message}</p>}
                            <label className="input input-bordered flex items-center gap-2">
                                <FaUser className='text-lg' />
                                <input
                                    type="text"
                                    maxLength={20}
                                    {...register('name')}
                                    className="grow"
                                    placeholder="お名前(ニックネームなども可)"
                                    disabled={isLoading}
                                />
                            </label>
                            {errors.name && <p className='text-error text-xs'>{errors.name.message}</p>}
                            {!isRepeater &&
                                <div className='flex flex-col gap-2 mt-2'>
                                    <p className='text-xs'>
                                        以下の『利用規約とプライバシーポリシーに同意する』をクリックすると
                                        <span className='text-info font-bold'><Link to='/terms-of-use'>利用規約</Link></span>
                                        、
                                        <span className='text-info font-bold'><Link to='/privacy-policy'>プライバシーポリシー</Link></span>
                                        を確認・同意したものとみなします。
                                    </p>
                                    <label className={`border border-base-content ${themeName === 'cupcake' ? 'rounded-full' : 'rounded-lg'} cursor-pointer label px-2 gap-2 justify-center md:w-max md:mx-auto`}>
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-secondary"
                                            onChange={() => setIsAgreed(!isAgreed)}
                                            disabled={isLoading}
                                        />
                                        <span className="label-text">利用規約とプライバシーポリシーに同意する</span>
                                    </label>
                                </div>
                            }
                            {(isRepeater && isLoading) ?
                                <div className='btn btn-primary shadow-base-normal text-base md:text-lg w-full mt-2'>
                                    <span className="loading loading-spinner loading-base text-primary-content"></span>
                                </div> :
                                (isRepeater && !isLoading) &&
                                <button
                                    type="submit"
                                    className='btn btn-primary shadow-base-normal text-base md:text-lg w-full mt-2'
                                >完了</button>
                            }
                            {(!isRepeater && isLoading) ?
                                <div className='btn btn-primary shadow-base-normal text-base md:text-lg w-full mt-2'>
                                    <span className="loading loading-spinner loading-base text-primary-content"></span>
                                </div> :
                                (!isRepeater && !isLoading && isAgreed) ?
                                    <button
                                        type="submit"
                                        className='btn btn-primary shadow-base-normal text-base md:text-lg w-full mt-2'
                                    >
                                        完了
                                    </button> :
                                    (!isRepeater && !isLoading && !isAgreed) &&
                                    <div className='btn shadow-base-normal text-base md:text-lg w-full mt-2'>完了</div>
                            }
                        </div>
                    </form>
                    <hr className='border-base-content border-opacity-30 my-3 w-11/12 mx-auto' />
                    <div className="w-full">
                        <form method="dialog">
                            {/* if there is a button tag in form, it will close the modal */}
                            <div className='flex justify-evenly'>
                                {!isLoading ?
                                    <button
                                        className="btn btn-neutral text-base md:text-lg w-full"
                                        onClick={() => resetAll()}
                                    >
                                        キャンセル
                                    </button> :
                                    <div className='btn btn-neutral text-base md:text-lg w-full'>キャンセル</div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    {!isLoading &&
                        <button onClick={() => resetAll()}>close</button>
                    }
                </form>
            </dialog>
        </>
    )
}

export default IdentificationForm