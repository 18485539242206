import { createContext, useState } from 'react'

export const accessTokenContext = createContext({})

const AccessTokenProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState('')

    return (
        <accessTokenContext.Provider value={{ accessToken, setAccessToken }}>
            {children}
        </accessTokenContext.Provider>
    )
}

export default AccessTokenProvider