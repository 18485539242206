import React from 'react'

function CollapseItem({ title, content }) {
    return (
        <>
            <div className="bg-base-200 shadow-base-normal collapse collapse-plus">
                <input type="checkbox" />
                <div className="collapse-title text-sm md:text-lg font-bold">
                    {title}
                </div>
                <div className="collapse-content">
                    <p className='break-newline text-sm md:text-base'>{content}</p>
                </div>
            </div>
        </>
    )
}

export default CollapseItem