import { useContext } from 'react'
import { themeNameContext } from '../../utils/contexts/ThemeName'
import RinIconImage from '../../assets/images/rin_circle_icon.png'
import BackgroundImageLight from '../../assets/images/background_light.jpg'
import BackgroundImageDark from '../../assets/images/background_dark.jpg'

function TopBackgroundAndIcon() {

    const { themeName } = useContext(themeNameContext)

    return (
        <>
            <div
                className='bg-neutral relative bg-cover md:bg-top h-64 md:h-80 lg:h-96'
                style={{
                    backgroundImage: `url(${themeName === 'night' ? BackgroundImageDark : BackgroundImageLight})`,
                    backgroundPosition: 'center top 30%'
                }}
            >
                <img
                    src={RinIconImage}
                    alt=""
                    className="absolute -bottom-16 left-1/2 transform -translate-x-1/2 rounded-full border-4 border-base-100 w-32 h-32 object-cover"
                />
            </div>
            <div className='bg-base-200 flex flex-col items-center py-20'>
                <h2 className='text-xl mb-4'>占い師 - <span className='text-3xl font-bold'>凛</span>(Rin)</h2>
                <p>~ 人生をより"豊か"に、未来にもっと"輝き"を ~</p>
            </div>
        </>
    )
}

export default TopBackgroundAndIcon