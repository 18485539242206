import React from 'react'

function LineAddFriendModal() {
    return (
        <dialog id="line_add_friend_modal" className="modal">
            <div className="modal-box flex flex-col items-center border-2 border-base-content w-4/5">
                <div className='flex flex-col items-center'>
                    <h3 className="font-bold text-lg mb-2">
                        "QRコード"で友だち追加
                    </h3>
                    <img className='w-4/5' src="https://qr-official.line.me/gs/M_590ozkry_GW.png?oat__id=3816426&oat_content=qr" alt='' />
                </div>
                <hr className='border-base-content border-opacity-30 my-6 w-11/12 mx-auto' />
                <div className='flex flex-col items-center'>
                    <h3 className="font-bold text-lg mb-2">
                        "ボタンをタップ"で友だち追加
                    </h3>
                    <a data-v-6fa6a74a="" href="https://lin.ee/gg5777T" target="_blank" rel="noopener noreferrer">
                        <img data-v-6fa6a74a="" src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="12" border="0" />
                    </a>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button>close</button>
            </form>
        </dialog>

    )
}

export default LineAddFriendModal